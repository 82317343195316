import { useState, useEffect } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import onlyNumbers1DotOrEmpty from "../../../utils/onlyNumbers1DotOrEmpty";
import onlyNumbersOrEmpty from "../../../utils/onlyNumbersOrEmpty";
import NextBtn from "../components/NextBtn";
import Button from "../../../components/Button/Button";
import Dropdown from "../../../components/Dropdown";
import Input from "../../../components/Input";
import RadioButton from "../../../components/RadioButton";
import PercentEndAdornment from "../components/PercentEndAdornment";
import Loading from "../../../components/Loading/Loading";
import { Position, Size } from "../../../types";
import Svg from "../../../components/Svg";
import InfoTooltip from "../components/InfoTooltip";

export default function Page0({
  prizeTokenType,
  setPrizeTokenType,
  potTokenID,
  setPotTokenID,
  tokenList,
  NFTTokenList,
  selectedPotToken,
  setSelectedPotToken,
  potTokenDecimals,
  minPotAmount,
  potAmount,
  setPotAmount,
  selectedBidToken,
  setSelectedBidToken,
  bidTokenDecimals,
  selectedBidOption,
  setSelectedBidOption,
  minBidAmount,
  bidVariable1,
  setBidVariable1,
  bidVariable2,
  setBidVariable2,
  setPage,
  wallet,
  connectMetamask,
  chain,
  setNetworksModalOpen,
  notEnoughPotToken,
  loadingTokenList,
  notOwnerOfToken,
  currentNetwork,
  error,
  setUpdateTimestamp,
}) {
  const isPrizeNFT = prizeTokenType === "1";

  const bp640px = useMediaQuery(640);
  const bp1200px = useMediaQuery(1200);
  const bp1280px = useMediaQuery(1280);

  const [potAmountFocused, setPotAmountFocused] = useState(false);
  const [bidAmountFocused, setBidAmountFocused] = useState(false);
  const [errors, setErrors] = useState([]);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  useEffect(() => {
    if (!potAmountFocused && potAmount !== "" && !isNaN(minPotAmount)) {
      if (potAmount < minPotAmount) setPotAmount(minPotAmount);
    }
  }, [potAmount, potAmountFocused, minPotAmount]);

  useEffect(() => {
    if (
      !bidAmountFocused &&
      bidVariable1 !== "" &&
      selectedBidOption !== "1" &&
      !isNaN(minBidAmount)
    ) {
      if (bidVariable1 < minBidAmount) setBidVariable1(minBidAmount);
    }
  }, [bidVariable1, bidAmountFocused, minBidAmount]);

  useEffect(() => {
    if (potAmount.length > 2 + potTokenDecimals && potAmount.includes("."))
      setPotAmount(potAmount.slice(0, 2 + potTokenDecimals));
  }, [potTokenDecimals]);

  useEffect(() => {
    if (selectedBidOption !== "1") {
      if (
        bidVariable1.length > 2 + bidTokenDecimals &&
        bidVariable1.includes(".")
      )
        setBidVariable1(bidVariable1.slice(0, 2 + bidTokenDecimals));
    }
  }, [bidTokenDecimals]);

  const validate = () => {
    const errors = [];
    if (!parseFloat(potAmount) && !isPrizeNFT)
      errors.push({
        id: "pot-token-amount",
        message: "Please provide a valid amount.",
      });
    if (!potTokenID && isPrizeNFT)
      errors.push({
        id: "pot-token-id",
        message: "Please provide a valid ID.",
      });

    if (!parseFloat(bidVariable1))
      errors.push({
        id: `bid-price-${
          selectedBidOption === "0"
            ? "amount"
            : selectedBidOption === "1"
            ? "percent"
            : "start-amount"
        }`,
        message: `Please provide a valid ${
          selectedBidOption === "0"
            ? "amount"
            : selectedBidOption === "1"
            ? "percent"
            : "start amount"
        }.`,
      });

    if (selectedBidOption === "2") {
      if (!parseFloat(bidVariable2))
        errors.push({
          id: `bid-price-increase-by`,
          message: "Please provide a valid value.",
        });
    }

    setErrors(errors);
    return errors;
  };

  useEffect(() => {
    if (triedToSubmit) validate();
  }, [
    triedToSubmit,
    potAmount,
    potTokenID,
    selectedPotToken,
    selectedBidToken,
    selectedBidOption,
    bidVariable1,
    bidVariable2,
  ]);

  useEffect(() => {
    if (prizeTokenType === "0") setPotTokenID("");
    else setPotAmount("");
  }, [prizeTokenType]);

  const TokenSelectorLoading = () =>
    loadingTokenList && (
      <div className="absolute top-0 right-0 left-0 bg-white bg-opacity-50 flex justify-center items-center py-4">
        <Loading color="black" size={Size.sm} />
      </div>
    );

  const RefreshBtn = () => (
    <Button
      bgColor="black"
      borderColor="black"
      className="h-[48px]"
      textColor={"white"}
      onClick={() => setUpdateTimestamp(Date.now())}
      disabled={loadingTokenList || !wallet || !currentNetwork}
    >
      <div className="flex sm:space-x-2 items-center">
        <span className="hidden sm:block">Refresh</span>
        <Svg name={"refreshSm"} />
      </div>
    </Button>
  );

  return (
    <>
      <p className="text-28 font-bold mb-1">
        Set the treasure and raid amounts
      </p>
      <p className="text-18 text-grey2 font-medium mb-8">
        Easy and simple steps to build your own village FOR FREE!
      </p>
      <label
        className="text-16 font-medium mb-1"
        htmlFor="prize-token-native/erc20"
      >
        Treasure type
      </label>
      <RadioButton
        id={`prize-token-native/erc20`}
        label={`Native / ERC20`}
        checked={prizeTokenType === "0"}
        onChange={() => setPrizeTokenType("0")}
        disabled={loadingTokenList}
      />
      <RadioButton
        id={`prize-token-erc721`}
        label={`ERC721 (NFT)`}
        className="mb-4"
        checked={prizeTokenType === "1"}
        onChange={() => setPrizeTokenType("1")}
        disabled={
          loadingTokenList ||
          currentNetwork?.NFTTournamentsAddresses.length === 0
        }
      />
      <label className="text-14 mb-1 flex space-x-2" htmlFor="pot-token">
        <span>Treasure token address</span>
        <InfoTooltip
          position={bp640px ? Position.right : Position.top}
          tooltip={
            "From the dropdown list select the token that you want as the currency for the prize of your tournament. If you want a token that is not yet on the list, reach out to us on Discord."
          }
        />
      </label>
      <div className="flex space-x-2">
        <div className="relative w-full">
          <Dropdown
            id="pot-token"
            className="mb-4"
            fullWidth
            options={(isPrizeNFT ? NFTTokenList : tokenList).map(
              ({ address, value, label }) => {
                return {
                  value: value,
                  label: `${label} (${
                    !address.includes("0x0000")
                      ? bp1200px
                        ? address
                        : `${address.slice(0, 4)}...${address.slice(-4)}`
                      : "native"
                  })`,
                };
              }
            )}
            value={selectedPotToken}
            onChange={(value) => setSelectedPotToken(parseInt(value))}
            disabled={
              (isPrizeNFT ? NFTTokenList : tokenList).length === 0 ||
              loadingTokenList
            }
          />
          {TokenSelectorLoading()}
        </div>
        {RefreshBtn()}
      </div>
      {!isPrizeNFT ? (
        <Input
          id={`pot-token-amount`}
          label={
            <>
              <span>
                Starting treasure (You must deposit this amount right after you
                built your village)
              </span>
              <InfoTooltip
                position={bp1280px ? Position.top : Position.left}
                tooltip={
                  "This amount equals what you need to deposit into the contract right after you successfully create it. This amount can be claimed by the winner of the tournament. This amount can grow while the tournament is ongoing by two ways. First, donors can send tokens to the contract to support it. And second, you can set a percentage of each bid to be sent to the prize pool itself."
                }
              />
            </>
          }
          labelClassName="flex space-x-2"
          className="mb-1.5"
          placeholder={"1"}
          fullWidth
          value={potAmount}
          onChange={(e) => {
            const value = e.target.value;
            if (onlyNumbers1DotOrEmpty(value)) {
              const valueTrimmed =
                value.length > 2 + potTokenDecimals && value.includes(".")
                  ? value.slice(0, 2 + potTokenDecimals)
                  : value;
              setPotAmount(valueTrimmed);
            }
          }}
          onFocus={() => setPotAmountFocused(true)}
          onBlur={() => setPotAmountFocused(false)}
          error={errors.find((e) => e.id === `pot-token-amount`)?.message}
        />
      ) : (
        <Input
          id={`pot-token-id`}
          label={"Treasure token address ID"}
          className="mb-1.5"
          placeholder={"1"}
          fullWidth
          value={potTokenID}
          onChange={(e) => setPotTokenID(e.target.value)}
          error={errors.find((e) => e.id === `pot-token-id`)?.message}
        />
      )}
      <label className="text-14 mb-1 flex space-x-2" htmlFor="bid-token">
        <span>Raid token</span>
        <InfoTooltip
          position={bp640px ? Position.right : Position.top}
          tooltip={
            "From the dropdown list select the token that you want as the bid currency for your tournament. Users will bid using this token. If you want a token that is not yet on the list, reach out to us on Discord."
          }
        />
      </label>
      <div className="flex space-x-2">
        <div className="relative w-full">
          <Dropdown
            id="bid-token"
            className="mb-4"
            fullWidth
            options={tokenList.map(({ address, value, label }) => {
              return {
                value: value,
                label: `${label} (${
                  !address.includes("0x0000")
                    ? bp1200px
                      ? address
                      : `${address.slice(0, 4)}...${address.slice(-4)}`
                    : "native"
                })`,
              };
            })}
            value={selectedBidToken}
            onChange={(value) => {
              const parsedValue = parseInt(value);
              setSelectedBidToken(parsedValue);
            }}
            disabled={tokenList.length === 0 || loadingTokenList}
          />
          {TokenSelectorLoading()}
        </div>
        {RefreshBtn()}
      </div>
      <label className="text-16 font-medium mb-1" htmlFor="bid-price-fixed">
        Raid fee
      </label>
      <RadioButton
        id={`bid-price-fixed`}
        label={
          <>
            <span>Fixed raid fee</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.top}
              tooltip={
                "The raid fee is the initial raid fee forever. You enter the initial bid amount and that's it."
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        checked={selectedBidOption === "0"}
        onChange={() => {
          setSelectedBidOption("0");
          setBidVariable2("");
        }}
      />
      {!isPrizeNFT && (
        <RadioButton
          id={`bid-price-%-of-current-pot`}
          label={
            <>
              <span>Raid fee is always a given percentage of the treasure</span>
              <InfoTooltip
                position={bp1280px ? Position.top : Position.left}
                tooltip={
                  "The raid fee is always a fixed percentage of the prize pool. If the prize pool increases, so does the raid fee. You enter the percentage of the prize pool and that's it. You cannot select this option if the decimals of the raid token and the Treasure token address differ."
                }
              />
            </>
          }
          labelClassName="flex space-x-2"
          checked={selectedBidOption === "1"}
          onChange={() => {
            setSelectedBidOption("1");
            setBidVariable2("");
          }}
          disabled={bidTokenDecimals !== potTokenDecimals}
        />
      )}
      <RadioButton
        id={`bid-price-increase-by-a-multiplier-after-every-bid`}
        label={
          <>
            <span>Increase by a multiplier after every bid</span>
            <InfoTooltip
              position={bp1280px ? Position.top : Position.left}
              tooltip={
                "The raid fee starts from the initial bid amount and grows by the given percent after every bid. You enter the initial bid amount and the percentage by which the bid amount increases after every bid. "
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        className="mb-4"
        checked={
          isPrizeNFT ? selectedBidOption === "1" : selectedBidOption === "2"
        }
        onChange={() => setSelectedBidOption(isPrizeNFT ? "1" : "2")}
      />
      <Input
        id={`bid-price-${
          selectedBidOption === "0"
            ? "amount"
            : isPrizeNFT
            ? "start-amount"
            : selectedBidOption === "1"
            ? "percent"
            : "start-amount"
        }`}
        label={
          selectedBidOption === "0"
            ? "Amount"
            : isPrizeNFT
            ? "Start amount"
            : selectedBidOption === "1"
            ? "Percent"
            : "Start amount"
        }
        placeholder={
          selectedBidOption === "1" ? (isPrizeNFT ? "1" : "10") : "1"
        }
        padding={
          selectedBidOption === "1"
            ? isPrizeNFT
              ? "pr-3 pl-4"
              : "pr-16 pl-4"
            : "pr-3 pl-4"
        }
        fullWidth
        value={bidVariable1}
        onChange={(e) => {
          const value = e.target.value;
          if (selectedBidOption === "1" && !isPrizeNFT) {
            if (onlyNumbersOrEmpty(value)) setBidVariable1(value);
          } else if (onlyNumbers1DotOrEmpty(value)) {
            const valueTrimmed =
              value.length > 2 + bidTokenDecimals && value.includes(".")
                ? value.slice(0, 2 + bidTokenDecimals)
                : value;
            setBidVariable1(valueTrimmed);
          }
        }}
        onFocus={() => setBidAmountFocused(true)}
        onBlur={() => setBidAmountFocused(false)}
        error={
          errors.find(
            (e) =>
              e.id ===
              `bid-price-${
                selectedBidOption === "0"
                  ? "amount"
                  : isPrizeNFT
                  ? "start-amount"
                  : selectedBidOption === "1"
                  ? "percent"
                  : "start-amount"
              }`
          )?.message
        }
        endAdornment={
          selectedBidOption === "1" && !isPrizeNFT && <PercentEndAdornment />
        }
      />
      {(isPrizeNFT ? selectedBidOption === "1" : selectedBidOption === "2") && (
        <Input
          id={`bid-price-increase-by`}
          label={"Increase by"}
          placeholder={"10"}
          padding={"pr-16 pl-4"}
          fullWidth
          value={bidVariable2}
          onChange={(e) => {
            const value = e.target.value;
            if (onlyNumbersOrEmpty(value)) setBidVariable2(value);
          }}
          error={errors.find((e) => e.id === "bid-price-increase-by")?.message}
          endAdornment={<PercentEndAdornment />}
        />
      )}
      <div className="mt-4">
        {notEnoughPotToken ? (
          <Button bgColor="green-green-darker" disabled>
            {`Not enough ${tokenList?.[selectedPotToken]?.label}`}
          </Button>
        ) : notOwnerOfToken ? (
          <Button bgColor="green-green-darker" disabled>
            {`Not owner of token`}
          </Button>
        ) : (
          <NextBtn
            onClick={() => {
              setTriedToSubmit(true);
              const errorsLength = validate().length;
              if (errorsLength === 0) {
                setPage(1);
              }
            }}
            wallet={wallet}
            connectMetamask={connectMetamask}
            chain={chain}
            setNetworksModalOpen={setNetworksModalOpen}
            disabled={error}
          />
        )}
      </div>
    </>
  );
}
