import { useEffect } from "react";
import { useState } from "react";
import useNFTPots from "../contracts/NFTPot/useNFTPots";
import usePots from "../contracts/pot/usePots";
import useContract from "./useContract";

export default function useBlacklist(contractAddress,
    isNFT,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshBlacklistedAddresses,
    watchTransaction,
    blacklistedAddresses) {
    const {
        blacklist,
    } = usePots([contractAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    const {
        blacklist: blacklistNFT,
    } = useNFTPots([contractAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    const [blacklistErrors, setBlacklistErrors] = useState([]);
    const [newBlacklistedAddress, setNewBlacklistedAddress] = useState("");
    const [triedToSubmitNewBlacklistAddress, setTriedToSubmitNewBlacklistAddress] = useState(false);
    const [loadingAddBlacklistedAddress, setLoadingAddBlacklistedAddress] = useState(false);

    const validateBlacklist = () => {
        const errors = [];
        if (!newBlacklistedAddress) errors.push({ id: "blacklist-new-address", message: "Please provide a valid address." });
        if (!!blacklistedAddresses.find(address => address.toLowerCase() === newBlacklistedAddress.toLowerCase()))
            errors.push({ id: "blacklist-new-address", message: "The blacklist already includes this address." });

        setBlacklistErrors(errors);
        return errors;
    }

    useEffect(() => {
        if (triedToSubmitNewBlacklistAddress) validateBlacklist();
        else setBlacklistErrors([]);
    }, [triedToSubmitNewBlacklistAddress, newBlacklistedAddress]);

    const { state: stateBlacklist, callMethod: onBlacklist } = useContract(
        watchTransaction,
        async (newBlacklistedAddress) => {
            setLoadingAddBlacklistedAddress(true);
            return isNFT
                ? await blacklistNFT(contractAddress, newBlacklistedAddress)
                : await blacklist(contractAddress, newBlacklistedAddress);
        },
        async () => {
            setLoadingAddBlacklistedAddress(false);
            setNewBlacklistedAddress("");
            setBlacklistErrors([]);
            setTriedToSubmitNewBlacklistAddress(false);
            await refreshBlacklistedAddresses(contractAddress);
        },
        () => {
            setLoadingAddBlacklistedAddress(false);
        }
    );

    return {
        blacklistErrors,
        setBlacklistErrors,
        newBlacklistedAddress,
        setNewBlacklistedAddress,
        setTriedToSubmitNewBlacklistAddress,
        loadingAddBlacklistedAddress,
        onBlacklist,
        validateBlacklist
    }
}