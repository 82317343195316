import { useEffect } from "react";
import useERC20 from "../../../contracts/ERC20/useERC20";

export default function useTokenListInit(
    watchTransaction,
    wallet,
    getContract,
    tokenAddresses,
    tokenList,
    setTokenList,
) {
    const {
        getSymbol,
        getDecimals,
        getBalanceOf,
        canCallERC20,
        setCanCallERC20,
    } = useERC20(watchTransaction, wallet, getContract, tokenAddresses);

    useEffect(() => {
        const updateTokenList = async () => {
            const newTokenList = tokenList.slice();
            for (let i = 1; i <= tokenAddresses.length; i++) {
                const address = tokenAddresses[i - 1];
                try {
                    newTokenList.push({
                        value: i,
                        label: `${await getSymbol(address)}`,
                        address: address,
                        decimals: await getDecimals(address),
                        balance: await getBalanceOf(address),
                    });
                }
                catch { }
            }
            setTokenList(newTokenList);
        }

        const updateTokenListBalances = async () => {
            const newTokenList = tokenList.slice();
            for (let i = 1; i <= tokenAddresses.length; i++) {
                const address = tokenAddresses[i - 1];
                try {
                    newTokenList[i].balance = await getBalanceOf(address);
                }
                catch { }
            }
            setTokenList(newTokenList);
        }

        if (canCallERC20 && wallet) {
            if (tokenList.length === 1) updateTokenList();
            else if (tokenList.length > 1) updateTokenListBalances();
            setCanCallERC20(false);
        }
    }, [canCallERC20, wallet, tokenList]);
}