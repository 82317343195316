import { useEffect, useState } from "react";
import './index.css';
import Tooltip from "../../components/Tooltip/Tooltip";
import { BtnType, Position, } from "../../types";
import Button from "../../components/Button/Button";
import Svg from "../../components/Svg";
import TournamentDetails from "./TournamentDetails";
import trophyBronze from "../../assets/icons/trophyBronze.svg";
import trophyGold from "../../assets/icons/trophyGold.svg";
import TournamentCountdown from "./TournamentCountdown";
import useMediaQuery from "../../hooks/useMediaQuery";
import WhitelistModal from '../../components/Modals/WhitelistModal';
import NotificationModal from '../../components/Modals/NotificationModal';
import useTournamentData from '../../hooks/useTournamentData';
import useTournamentLogic from "../../hooks/useTournamentLogic";
import useCopyToClipboard from "../../hooks/useCopyToClipboard";
import usePinnedTournaments from "./hooks/usePinnedTournaments";

export default function Tournament({
    tournament,
    refreshTournamentData,
    refreshBlacklistedAddresses,
    refreshWhitelistedAddresses,
    setWantsToApprove,
    setWantsToApprovePotToken,
    setWantsToBid,
    setWantsToClaim,
    setWantsToDeposit,
    canApprove,
    canApprovePotToken,
    canBid,
    canClaim,
    canDeposit,
    setCanApprove,
    setCanApprovePotToken,
    setCanBid,
    setCanClaim,
    setCanDeposit,
    canCall,
    wallet,
    chain,
    currentNetwork,
    loaded,
    connectMetamask,
    switchToNetwork,
    getContract,
    watchTransaction,
    getGasLimit,
    getGasPrice,
    balance,
    getBalance,
    isTournamentPinned,
    pinnedTournamentsStorageName,
    now,
    ...rest
}) {
    const bp640px = useMediaQuery(640);

    const [whitelistModalOpen, setWhitelistModalOpen] = useState(false);
    const [notificationModalOpen, setNotificationModalOpen] = useState(false);
    const [hasNotification, setHasNotification] = useState(false);

    const {
        symbolBidToken,
        decimalsBidToken,
        setBalanceOfBidToken,
        setBalanceOfPotToken,
        symbolPotToken,
        decimalsPotToken,
        tokenURI,
        contractAddress,
        initDone,
        isNFT,
        isActive,
        creatorCanClaim,
        expired,
        topOwner,
        lastBidWinner,
        createdDateFormatted,
        claimedDateFormatted,
        bidAmount,
        bidToken,
        potToken,
        potAmount,
        isNativeBidToken,
        isNativePotToken,
        isWalletOwnerOfTournament,
        blacklistedAddresses,
        isBlacklisted,
        whitelistedAddresses,
        whitelistOnly,
        notOnWhitelist,
        timeUntilExpiry,
        isPriority,
        deposited,
        bidAmountDivided,
        potAmountDivided,
        bidAmountFormatted,
        potAmountFormatted,
        balanceOfBidTokenInContractDivided,
        balanceOfContractDivided,
        balanceOfBidTokenInContractFormatted,
        balanceOfContractFormatted,
        notEnoughBidToken,
        notEnoughPotToken,
        notOwnerOfToken,
        updateBalanceOfBidTokenInContract,
        updateBalanceOfContract,
        setDeposited,
        potTokenID,
    } = useTournamentData(tournament,
        wallet,
        canCall,
        watchTransaction,
        getContract,
        balance,
        getBalance,
        currentNetwork);

    const {
        onBid,
        onClaim,
        onDepositNFT,
        onDeposit,
        approveERC20,
        approveERC721,
        loadingApproveERC20,
        loadingApproveERC721,
        loadingBid,
        loadingClaim,
        loadingDeposit,
        approvedERC20,
        approvedERC721,
    } = useTournamentLogic(watchTransaction,
        wallet,
        getContract,
        bidToken,
        potToken,
        isNFT,
        contractAddress,
        getGasLimit,
        getGasPrice,
        isNativeBidToken,
        isNativePotToken,
        refreshTournamentData,
        updateBalanceOfBidTokenInContract,
        updateBalanceOfContract,
        setDeposited,
        setBalanceOfBidToken,
        setBalanceOfPotToken,
        balance,
        potTokenID);

    useEffect(() => {
        const callOnBid = async () => {
            await onBid(bidAmount);
            setCanBid("");
        }
        if (canBid === contractAddress) callOnBid();
    }, [canBid]);

    useEffect(() => {
        const onApprove = async () => {
            await approveERC20(bidToken);
            setCanApprove("");
        }
        if (canApprove === contractAddress) onApprove();
    }, [canApprove]);

    useEffect(() => {
        const onApprove = async () => {
            if (isNFT) await approveERC721(potToken);
            else await approveERC20(potToken);
            setCanApprovePotToken("");
        }
        if (canApprovePotToken === contractAddress) onApprove();
    }, [canApprovePotToken]);

    useEffect(() => {
        const callOnClaim = async () => {
            await onClaim();
            setCanClaim("");
        }
        if (canClaim === contractAddress) callOnClaim();
    }, [canClaim]);

    useEffect(() => {
        const callOnDeposit = async () => {
            if (isNFT) await onDepositNFT(contractAddress);
            else await onDeposit(contractAddress, potAmount);
            setCanDeposit("");
        }
        if (canDeposit === contractAddress) callOnDeposit();
    }, [canDeposit]);

    const LastBidWinner = () => {
        return (
            <div className="mb-6">
                <p className='text-grey1 mb-0'>Winner:</p>
                <p className={`text-20 font-medium ${lastBidWinner ? "" : "shimmer w-full"}`}>
                    <span className={lastBidWinner !== undefined ? "" : "invisible"}>{lastBidWinner || "."}</span>
                </p>
            </div>
        )
    }

    const {
        copyTooltip,
        setCopyTooltip,
        copyToClipboard,
    } = useCopyToClipboard(contractAddress);

    const {
        getPinnedTournaments,
        pinTournament,
        unpinTournament,
        isPinned,
    } = usePinnedTournaments(isTournamentPinned,
        tournament,
        pinnedTournamentsStorageName);

    return (
        <>
            <div className={`col-span-12 md:col-span-6 xl:col-span-4 w-full bg-white
                border-2 ${isPriority ? "border-orange outline outline-1 outline-orange shadow-[0_2px_6px_rgba(235,141,21,0.1)]" : "border-[#DBE0EB] shadow-[0_2px_6px_rgba(32,36,50,0.1)]"} rounded-[8px]`} {...rest}>
                <div className="sm:flex justify-between items-center sm:space-x-2 space-y-2 sm:space-y-0
                bg-[#F3F7FD] px-3 sm:px-5 py-3 text-left rounded-t-[8px] border-b border-[#DBE0EB]">
                    <div>
                        <p className='text-14 text-grey1 mb-0'>Contract</p>
                        <p className="break-all text-16 font-semibold">{contractAddress?.slice(0, 6)}...{contractAddress?.slice(-4)}</p>
                    </div>
                    <div className="flex space-x-2">
                        <Tooltip
                            tooltip={copyTooltip}
                            onInvisible={() => setCopyTooltip("Copy to clipboard")}
                            wallet={wallet}
                            position={bp640px ? Position.top : undefined}
                            minWidthOverride={160}
                            minHeightOverride={40}
                            minWidthOverrideClassName="w-[160px]"
                            minHeightOverrideClassName="h-[40px]"
                        >
                            <Button
                                type={BtnType.linklike}
                                textColor="grey2-grey2-darker"
                                padding="p-1"
                                onClick={copyToClipboard}
                            >
                                <Svg name={"copy"} />
                            </Button>
                        </Tooltip>
                        {whitelistOnly &&
                            <Tooltip
                                tooltip={"Whitelisted addresses"}
                                wallet={wallet}
                                position={bp640px ? Position.top : undefined}
                                minWidthOverride={190}
                                minHeightOverride={40}
                                minWidthOverrideClassName="w-[190px]"
                                minHeightOverrideClassName="h-[40px]"
                            >
                                <Button
                                    type={BtnType.linklike}
                                    textColor="grey2-grey2-darker"
                                    padding="p-1"
                                    onClick={() => setWhitelistModalOpen(true)}
                                >
                                    <Svg name={"plus"} />
                                </Button>
                            </Tooltip>
                        }
                        <TournamentDetails
                            contractAddress={contractAddress}
                            isNFT={isNFT}
                            getContract={getContract}
                            getGasLimit={getGasLimit}
                            getGasPrice={getGasPrice}
                            potToken={potToken}
                            decimalsPotToken={decimalsPotToken}
                            symbolPotToken={symbolPotToken}
                            tokenURI={tokenURI}
                            isNativePotToken={isNativePotToken}
                            isNativeBidToken={isNativeBidToken}
                            bidToken={bidToken}
                            bidAmountFormatted={bidAmountFormatted}
                            symbolBidToken={symbolBidToken}
                            decimalsBidToken={decimalsBidToken}
                            timeUntilExpiry={timeUntilExpiry}
                            refreshTournamentData={refreshTournamentData}
                            deposited={deposited}
                            isWalletOwnerOfTournament={isWalletOwnerOfTournament}
                            whitelistOnly={whitelistOnly}
                            whitelistedAddresses={whitelistedAddresses}
                            blacklistedAddresses={blacklistedAddresses}
                            isActive={isActive}
                            refreshBlacklistedAddresses={refreshBlacklistedAddresses}
                            refreshWhitelistedAddresses={refreshWhitelistedAddresses}
                            watchTransaction={watchTransaction}
                            currentNetwork={currentNetwork}
                            isPriority={isPriority}
                            bidAmountDivided={bidAmountDivided}
                            potAmountDivided={potAmountDivided}
                            balanceOfBidTokenInContractDivided={balanceOfBidTokenInContractDivided}
                            balanceOfContractDivided={balanceOfContractDivided}
                        />
                        <Tooltip
                            tooltip={"Subpage"}
                            position={Position.top}
                        >
                            <Button
                                type={BtnType.linklike}
                                textColor="grey2-grey2-darker"
                                padding="p-1"
                                asLink
                                href={`/tournaments/${contractAddress}`}
                                target={"_blank"}
                            >
                                <Svg name={"externalLink"} />
                            </Button>
                        </Tooltip>
                        {isActive &&
                            <Tooltip
                                tooltip={hasNotification ? "Change reminder" : "Set reminder"}
                                position={Position.top}
                                minWidthOverride={150}
                                minWidthOverrideClassName="w-[150px]"
                            >
                                <Button
                                    type={BtnType.linklike}
                                    textColor={hasNotification ? isPriority ? "orange" : "green-green-darker" : "grey2-grey2-darker"}
                                    padding="p-1"
                                    onClick={() => setNotificationModalOpen(true)}
                                >
                                    <Svg name={"notification"} />
                                </Button>
                            </Tooltip>
                        }
                        {isPinned
                            ?
                            <Tooltip
                                tooltip={"Unpin"}
                                position={Position.top}
                            >
                                <Button
                                    type={BtnType.linklike}
                                    textColor="grey2-grey2-darker"
                                    padding="p-1"
                                    onClick={unpinTournament}
                                >
                                    <Svg name={"unpin"} />
                                </Button>
                            </Tooltip>
                            :
                            <Tooltip
                                tooltip={"Pin"}
                                position={Position.top}
                                hide={getPinnedTournaments().length >= 3}
                            >
                                <Button
                                    type={BtnType.linklike}
                                    textColor="grey2-grey2-darker"
                                    padding="p-1"
                                    onClick={pinTournament}
                                    disabled={getPinnedTournaments().length >= 3}
                                >
                                    <Svg name={"pin"} />
                                </Button>
                            </Tooltip>
                        }
                    </div>
                </div>
                <div className="px-3 sm:px-5 py-6 rounded-b-[8px]">
                    <div className={`${isNFT ? "sm:flex space-x-2" : "flex space-x-4"} mb-8`}>
                        <div className="flex justify-center">
                            {!isNFT
                                ? <img src={isPriority ? trophyGold : trophyBronze} alt="" width={70} height={80} />
                                :
                                <div className={`${tokenURI ? "max-w-[200px] max-h-[200px]" : "w-[200px] h-[200px] shimmer"}`}>
                                    <img src={tokenURI} alt="" className="w-full h-full" />
                                </div>
                            }
                        </div>
                        <div className={`flex flex-col justify-center`}>
                            <p className="text-16 sm:text-18 text-grey1 font-medium sm:mb-3">
                                {isActive
                                    ? isNFT ? `You will win one` : "You will win"
                                    : isNFT ? `The prize is one` : "Prize"}
                            </p>
                            {!isNFT
                                ?
                                <div className={`${potAmountFormatted && symbolPotToken !== undefined ? "" : "shimmer w-full"}`}>
                                    <div className={`flex flex-wrap items-end ${potAmountFormatted && symbolPotToken !== undefined ? "" : "invisible"}`}>
                                        <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">{potAmountFormatted}</span>
                                        {" "}
                                        <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">{symbolPotToken !== undefined ? symbolPotToken : "."}</span>
                                    </div>
                                    {(isNativePotToken || (!isNativePotToken && potToken != bidToken)) && !isNativeBidToken
                                        &&
                                        <div className={`flex flex-wrap items-end mt-2 ${balanceOfBidTokenInContractFormatted && symbolBidToken !== undefined ? "" : "invisible"}`}>
                                            <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">{balanceOfBidTokenInContractFormatted}</span>
                                            {" "}
                                            <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">{symbolBidToken !== undefined ? symbolBidToken : "."}</span>
                                        </div>
                                    }
                                    {!isNativePotToken && isNativeBidToken
                                        &&
                                        <div className={`flex flex-wrap items-end mt-2 ${balanceOfContractFormatted && symbolBidToken !== undefined ? "" : "invisible"}`}>
                                            <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">{balanceOfContractFormatted}</span>
                                            {" "}
                                            <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">{symbolBidToken !== undefined ? symbolBidToken : "."}</span>
                                        </div>
                                    }
                                </div>
                                :
                                <div className={`${symbolPotToken !== undefined ? "" : "shimmer w-full"}`}>
                                    <div className={`flex flex-wrap items-end ${symbolPotToken !== undefined ? "" : "invisible"}`}>
                                        <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">{symbolPotToken}</span>
                                        {" "}
                                        <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">NFT</span>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {isActive
                        ?
                        <>
                            <div className="mb-6">
                                <p className='text-grey1 mb-0'>Currently winning:</p>
                                <p className={`text-20 font-medium ${lastBidWinner ? "" : "shimmer w-full"}`}>
                                    <span className={lastBidWinner !== undefined ? "" : "invisible"}>{lastBidWinner || "."}</span>
                                </p>
                            </div>
                            <TournamentCountdown
                                date={timeUntilExpiry * 1000}
                                onComplete={async () => await refreshTournamentData(contractAddress)}
                                completedText={"Bidding closed!"}
                                isPriority={isPriority}
                            />
                            <Tooltip
                                tooltip={`Bid: ${bidAmountDivided} ${symbolBidToken || ""}`}
                                position={Position.top}
                                wallet={wallet}
                                hide={!deposited || !bidAmountDivided || !symbolBidToken}
                                containerClassName="w-full"
                                optionalRefresh={deposited}
                            >
                                <Button
                                    bgColor={isPriority ? "orange" : 'green-green-darker'}
                                    borderColor={isPriority ? "orange" : 'green-green-darker'}
                                    className='w-full mt-2'
                                    loading={loadingApproveERC20 || loadingApproveERC721 || loadingBid || loadingDeposit || !initDone}
                                    onClick={
                                        !deposited && isWalletOwnerOfTournament
                                            ?
                                            (isNFT ? approvedERC721?.[potToken] : (approvedERC20?.[potToken] || isNativePotToken))
                                                ? () => setWantsToDeposit(contractAddress)
                                                : () => setWantsToApprovePotToken(contractAddress)
                                            : !isNativeBidToken && !approvedERC20?.[bidToken]
                                                ? () => setWantsToApprove(contractAddress)
                                                : () => setWantsToBid(contractAddress)}
                                    disabled={now >= timeUntilExpiry * 1000
                                        || ((isNativeBidToken || approvedERC20?.[bidToken]) && notEnoughBidToken)
                                        || (isBlacklisted && (isWalletOwnerOfTournament ? deposited : true))
                                        || (notOnWhitelist && (isWalletOwnerOfTournament ? deposited : true))
                                        || (!deposited && !isWalletOwnerOfTournament)
                                        || ((approvedERC20?.[potToken] || isNativePotToken) && notEnoughPotToken && isWalletOwnerOfTournament)
                                        || (!deposited && approvedERC721?.[potToken] && notOwnerOfToken && isWalletOwnerOfTournament)}
                                >
                                    {!deposited
                                        ? isWalletOwnerOfTournament
                                            ? (isNFT ? !approvedERC721?.[potToken] : !isNativePotToken && !approvedERC20?.[potToken])
                                                ? `Approve ${symbolPotToken || ""}`
                                                : isNFT
                                                    ? notOwnerOfToken
                                                        ? `Not owner of token`
                                                        : `Deposit ${symbolPotToken || ""}`
                                                    : notEnoughPotToken
                                                        ? `Not enough ${symbolPotToken || ""}`
                                                        : `Deposit ${potAmountFormatted} ${symbolPotToken || ""}`
                                            : isNFT ? "No NFT was deposited to the pot" : "No token were deposited to the pot"
                                        : isBlacklisted
                                            ? "You are on blacklist"
                                            : notOnWhitelist
                                                ? "You are not on the whitelist"
                                                : !isNativeBidToken && !approvedERC20?.[bidToken]
                                                    ? `Approve ${symbolBidToken || ""}`
                                                    : notEnoughBidToken
                                                        ? `Not enough ${symbolBidToken || ""} to bid (${bidAmountFormatted})`
                                                        : `Bid (${bidAmountFormatted} ${symbolBidToken || ""})`}
                                </Button>
                            </Tooltip>
                        </>
                        : (expired || creatorCanClaim || topOwner)
                            ?
                            <>
                                {LastBidWinner()}
                                {(expired || creatorCanClaim) &&
                                    <TournamentCountdown
                                        date={(timeUntilExpiry + (expired ? tournament.winnerClaimAllowTime : tournament.creatorClaimAllowTime)) * 1000}
                                        onComplete={async () => await refreshTournamentData(contractAddress)}
                                        completedText={"Time expired!"}
                                        label="Claim time:"
                                        isPriority={isPriority}
                                    />
                                }
                                <Button
                                    bgColor={isPriority ? "orange" : 'green-green-darker'}
                                    borderColor={isPriority ? "orange" : 'green-green-darker'}
                                    className="w-full"
                                    loading={loadingClaim || !initDone}
                                    onClick={() => setWantsToClaim(contractAddress)}
                                    disabled={tournament?.lastBidWinner?.toLowerCase() !== wallet}
                                >
                                    Claim
                                </Button>
                            </>
                            :
                            <>
                                {LastBidWinner()}
                                <div className="sm:flex justify-between">
                                    <div>
                                        <p className='text-grey1 mb-0'>Creation:</p>
                                        <p className={`text-20 font-medium ${createdDateFormatted !== undefined ? "" : "shimmer w-full"}`}>
                                            <span className={createdDateFormatted !== undefined ? "" : "invisible"}>{createdDateFormatted || "."}</span>
                                        </p>
                                    </div>
                                    <div>
                                        <p className='text-grey1 mb-0'>Claim:</p>
                                        <p className={`text-20 font-medium ${claimedDateFormatted !== undefined ? "" : "shimmer w-full"}`}>
                                            <span className={claimedDateFormatted !== undefined ? "" : "invisible"}>{claimedDateFormatted || "."}</span>
                                        </p>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
            <WhitelistModal
                whitelistModalOpen={whitelistModalOpen}
                setWhitelistModalOpen={setWhitelistModalOpen}
                tournament={tournament}
                wallet={wallet}
                getContract={getContract}
                getGasLimit={getGasLimit}
                getGasPrice={getGasPrice}
                whitelistedAddresses={whitelistedAddresses}
                watchTransaction={watchTransaction}
                refreshWhitelistedAddresses={refreshWhitelistedAddresses}
            />
            <NotificationModal
                notificationModalOpen={notificationModalOpen}
                setNotificationModalOpen={setNotificationModalOpen}
                hasNotification={hasNotification}
                setHasNotification={setHasNotification}
                wallet={wallet}
                tournament={tournament}
                currentNetwork={currentNetwork}
                now={now}
            />
        </>
    )
}