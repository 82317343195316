export default function onlyKeep5Digits(number) {
    if (number === 0 || number) {
        const string = number.toFixed(4);
        if (string.length > 5) {
            if (string.includes('.') || string.includes(',')) return parseFloat(string.slice(0, 6)).toString();
            else return parseFloat(string.slice(0, 5)).toString();
        }
        else return string;
    }
    else return number;
}