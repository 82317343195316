class FetchQueue {

    constructor() {
        this.queue = [];
        this.MAX_CALLS = 2;
        this.TIME_WINDOW = 1000;
        this.processing = false;
    }

    pushRequest = (promise, requestId) => {
        return new Promise((resolve, reject) => {
            // Add the promise to the queue.
            this.queue.push({
                requestId,
                promise,
                resolve,
                reject,
            });

            // If the queue is not being processed, we process it.
            if (!this.processing) {
                this.processing = true;
                setTimeout(() => {
                    this.processQueue();
                }, this.TIME_WINDOW / this.MAX_CALLS);
            }
        }
        );
    };

    processQueue = () => {
        const item = this.queue.shift();
        try {
            // Pull first item in the queue and run the request.
            const data = item.promise();
            item.resolve(data);
            if (this.queue.length > 0) {
                this.processing = true;
                setTimeout(() => {
                    this.processQueue();
                }, this.TIME_WINDOW / this.MAX_CALLS);
            } else {
                this.processing = false;
            }
        } catch (e) {
            item.reject(e);
        }
    };

    removeRequest = (requestId) => {
        // We delete the promise from the queue using the given id.
        this.queue.some((item, index) => {
            if (item.requestId === requestId) {
                this.queue.splice(index, 1);
                return true;
            }
        });
    }
}

const instance = new FetchQueue();
Object.freeze(FetchQueue);

export default instance;