import { useEffect } from "react";
import { useState } from "react";
import { Position, TimeUnit } from "../../../types";
import TimeUnitSwitcher from "../../../components/TimeUnitSwitcher";
import isInteger from "../../../utils/isInteger";
import getTimeUnitInSeconds from "../../../utils/getTimeUnitInSeconds";
import Button from "../../../components/Button/Button";
import walletWhite from "../../../assets/icons/walletWhite.svg";
import { chainIds } from "../../../networks";
import BackBtn from "../components/BackBtn";
import RadioButton from "../../../components/RadioButton";
import onlyNumbers1DotOrEmpty from "../../../utils/onlyNumbers1DotOrEmpty";
import Input from "../../../components/Input";
import InfoTooltip from "../components/InfoTooltip";
import useMediaQuery from "../../../hooks/useMediaQuery";

export default function Page2({
  selectedExpireTimeOption,
  setSelectedExpireTimeOption,
  timeUnit1,
  setTimeUnit1,
  expVariable1,
  setExpVariable1,
  timeUnit2,
  setTimeUnit2,
  expVariable2,
  setExpVariable2,
  timeUnit3,
  setTimeUnit3,
  expVariable3,
  setExpVariable3,
  tokenList,
  priorityPrice,
  nonPriorityPrice,
  selectedPriorityPoolOption,
  setSelectedPriorityPoolOption,
  wallet,
  connectMetamask,
  chain,
  loadingCreatePot,
  onCreatePot,
  notEnoughPotToken,
  notEnoughNativeToken,
  selectedPotToken,
  selectedBidToken,
  currentNetwork,
  setNetworksModalOpen,
  setPage,
  roundDuration,
  setRoundDuration,
  roundReward,
  setRoundReward,
  timeUnit4,
  setTimeUnit4,
  potTokenDecimals,
  minPotAmount,
  isPotTokenNative,
  prizeTokenType,
  error,
}) {
  console.log(tokenList?.[selectedPotToken], tokenList?.[selectedBidToken]);

  const isPrizeNFT = prizeTokenType === "1";

  const bp640px = useMediaQuery(640);
  const bp1280px = useMediaQuery(1280);

  const [errors, setErrors] = useState([]);
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const [roundRewardFocused, setRoundRewardFocused] = useState(false);

  useEffect(() => {
    if (!roundRewardFocused && roundReward !== "" && !isNaN(minPotAmount)) {
      if (roundReward < minPotAmount) setRoundReward(minPotAmount);
    }
  }, [roundReward, roundRewardFocused, minPotAmount]);

  useEffect(() => {
    if (roundReward.length > 2 + potTokenDecimals && roundReward.includes("."))
      setRoundReward(roundReward.slice(0, 2 + potTokenDecimals));
  }, [potTokenDecimals]);

  const getExpVariable = (timeUnit) => {
    switch (timeUnit) {
      case TimeUnit.Seconds:
        return "86400";
      case TimeUnit.Minutes:
        return "1440";
      case TimeUnit.Hours:
        return "24";
      case TimeUnit.Days:
        return "1";
    }
  };

  const expVariable1Placeholder = getExpVariable(timeUnit1);
  const expVariable2Placeholder = getExpVariable(timeUnit2);
  const expVariable3Placeholder = getExpVariable(timeUnit3);
  const roundDurationPlaceholder = getExpVariable(timeUnit4);

  const validate = () => {
    const errors = [];

    if (!parseFloat(expVariable1))
      errors.push({
        id: `expiration-time-${
          selectedExpireTimeOption === "0" ? "time" : "initial-time"
        }`,
        message: "Please provide a valid value.",
      });
    if (timeUnit1 === TimeUnit.Seconds && !isInteger(expVariable1))
      errors.push({
        id: `expiration-time-${
          selectedExpireTimeOption === "0" ? "time" : "initial-time"
        }`,
        message: "Please provide an integer.",
      });

    if (selectedExpireTimeOption === "1" || selectedExpireTimeOption === "2") {
      if (!parseFloat(expVariable2))
        errors.push({
          id: `expiration-time-${
            selectedExpireTimeOption === "1" ? "decrease" : "increase"
          }-by`,
          message: "Please provide a valid value.",
        });
      if (timeUnit2 === TimeUnit.Seconds && !isInteger(expVariable2))
        errors.push({
          id: `expiration-time-${
            selectedExpireTimeOption === "1" ? "decrease" : "increase"
          }-by`,
          message: "Please provide an integer.",
        });
    }
    if (selectedExpireTimeOption === "1") {
      if (!expVariable3)
        errors.push({
          id: `expiration-time-minimum-time`,
          message: "Please provide a valid value.",
        });
      if (timeUnit3 === TimeUnit.Seconds && !isInteger(expVariable3))
        errors.push({
          id: `expiration-time-minimum-time`,
          message: "Please provide an integer.",
        });
      if (
        getTimeUnitInSeconds(timeUnit1, expVariable1) <=
        getTimeUnitInSeconds(timeUnit3, expVariable3, 0)
      )
        errors.push({
          id: `expiration-time-initial-time`,
          message: "Must be greater than minimum time.",
        });
    }

    if (parseFloat(roundDuration) === 0)
      errors.push({
        id: `round-duration`,
        message: "Please provide a valid value.",
      });
    if (roundReward && !parseFloat(roundDuration))
      errors.push({
        id: `round-duration`,
        message: "Please provide a valid value.",
      });
    if (parseFloat(roundReward) === 0)
      errors.push({
        id: `round-reward`,
        message: "Please provide a valid value.",
      });
    if (roundDuration && !parseFloat(roundReward))
      errors.push({
        id: `round-reward`,
        message: "Please provide a valid value.",
      });

    setErrors(errors);
    return errors;
  };

  useEffect(() => {
    if (triedToSubmit) validate();
  }, [
    triedToSubmit,
    selectedExpireTimeOption,
    expVariable1,
    expVariable2,
    expVariable3,
    timeUnit1,
    timeUnit2,
    timeUnit3,
    roundDuration,
    roundReward,
  ]);

  return (
    <>
      <p className="text-28 font-bold mb-1">Select expiration time</p>
      <p className="text-18 text-grey2 font-medium mb-8">
        Choose when your tournament timeline will expire
      </p>
      <label
        className="text-16 font-medium mb-1"
        htmlFor="expiration-time-fixed"
      >
        Expiration time
      </label>
      <div className="text-14 mb-3">
        <RadioButton
          id={`expiration-time-fixed`}
          label={
            <>
              <span>After each bid the time resets to this value</span>
              <InfoTooltip
                position={bp1280px ? Position.top : Position.left}
                tooltip={
                  "The expiry time always resets to the same time after every bid. You enter the initial expiration time and that's it."
                }
              />
            </>
          }
          labelClassName="flex space-x-2"
          checked={selectedExpireTimeOption === "0"}
          onChange={() => {
            setSelectedExpireTimeOption("0");
            setExpVariable2("");
            setExpVariable3("");
          }}
        />
        <RadioButton
          id={`expiration-time-decreasing`}
          label={
            <>
              <span>After each bid the reset time decreases</span>
              <InfoTooltip
                position={bp1280px ? Position.top : Position.left}
                tooltip={
                  "The expiry time starts from the initial expiry time and decreases after every bid by the given time until the minimum expiry time is reached. Example: You start with 24 hours and you decrease the time by 1 hour until 1 hours is reached. Then after the first bid the expiry time will reset to 23 hours. After the second bid the expiry time will reset to 22 hours... after the 23th bid the expiry time will reset to 1 hour, and since that is the minimum, after that every bid will reset the time to 1 hour."
                }
              />
            </>
          }
          labelClassName="flex space-x-2"
          checked={selectedExpireTimeOption === "1"}
          onChange={() => setSelectedExpireTimeOption("1")}
        />
        <RadioButton
          id={`expiration-time-increasing`}
          label={
            <>
              <span>After each bid the expiration time increases</span>
              <InfoTooltip
                position={bp1280px ? Position.top : Position.left}
                tooltip={
                  "The expiry time starts from the initial expiry time and starts to decrease. After every bid the given time is added to the time until expiry."
                }
              />
            </>
          }
          labelClassName="flex space-x-2"
          checked={selectedExpireTimeOption === "2"}
          onChange={() => setSelectedExpireTimeOption("2")}
        />
      </div>
      <TimeUnitSwitcher
        timeUnit={timeUnit1}
        setTimeUnit={setTimeUnit1}
        id={`expiration-time-${
          selectedExpireTimeOption === "0" ? "time" : "initial-time"
        }`}
        className="mb-1.5"
        label={selectedExpireTimeOption === "0" ? "Reset time" : "Initial time"}
        placeholder={expVariable1Placeholder}
        value={expVariable1}
        onChange={(e) => setExpVariable1(e.target.value)}
        error={
          errors.find(
            (e) =>
              e.id ===
              `expiration-time-${
                selectedExpireTimeOption === "0" ? "time" : "initial-time"
              }`
          )?.message
        }
      />
      {(selectedExpireTimeOption === "1" ||
        selectedExpireTimeOption === "2") && (
        <TimeUnitSwitcher
          timeUnit={timeUnit2}
          setTimeUnit={setTimeUnit2}
          id={`expiration-time-${
            selectedExpireTimeOption === "1" ? "decrease" : "increase"
          }-by`}
          className="mb-1.5"
          label={`${
            selectedExpireTimeOption === "1" ? "Decrease" : "Increase"
          } by`}
          placeholder={expVariable2Placeholder}
          value={expVariable2}
          onChange={(e) => setExpVariable2(e.target.value)}
          error={
            errors.find(
              (e) =>
                e.id ===
                `expiration-time-${
                  selectedExpireTimeOption === "1" ? "decrease" : "increase"
                }-by`
            )?.message
          }
        />
      )}
      {selectedExpireTimeOption === "1" && (
        <TimeUnitSwitcher
          timeUnit={timeUnit3}
          setTimeUnit={setTimeUnit3}
          id={`expiration-time-minimum-time`}
          className="mb-1.5"
          label={`Minimum time`}
          placeholder={expVariable3Placeholder}
          value={expVariable3}
          onChange={(e) => setExpVariable3(e.target.value)}
          error={
            errors.find((e) => e.id === "expiration-time-minimum-time")?.message
          }
        />
      )}
      <div className="text-16 font-medium mb-0 flex space-x-2 items-center">
        <span>King's reward (only for ERC20 Treasure token addresss)</span>
        <div className="font-normal">
          <InfoTooltip
            position={bp1280px ? Position.top : Position.left}
            tooltip={
              "If the last bidder stays for a period of time, but is kicked (someone else bids), they get paid out from the prize pool the round reward for each round completed. Example: Round time is 10 minutes and round reward is 10 $USDC. Person 1 is last bidder for 45 minutes, the wallet will receive 40 $USDC from the prize pool. Note: If the reward is in average more than the amount of tokens distributed to the prize pool, the prize pool's amount will diminish over time."
            }
          />
        </div>
      </div>
      <TimeUnitSwitcher
        timeUnit={timeUnit4}
        setTimeUnit={setTimeUnit4}
        id={`round-duration`}
        className="mb-1.5"
        label={
          <>
            <span>Round duration</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.top}
              tooltip={
                "The time the bidder must remain as last bidder to receive a round."
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        placeholder={roundDurationPlaceholder}
        value={roundDuration}
        onChange={(e) => setRoundDuration(e.target.value)}
        error={errors.find((e) => e.id === `round-duration`)?.message}
        disabled={isPotTokenNative || isPrizeNFT}
      />
      <Input
        id={`round-reward`}
        label={
          <>
            <span>
              Round reward ${`(${tokenList?.[selectedPotToken]?.label || ""})`}
            </span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.top}
              tooltip={
                "The amount that the last bidder receives per completed round."
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        className="mb-1.5"
        placeholder={"1"}
        fullWidth
        value={roundReward}
        onChange={(e) => {
          const value = e.target.value;
          if (onlyNumbers1DotOrEmpty(value)) {
            const valueTrimmed =
              value.length > 2 + potTokenDecimals && value.includes(".")
                ? value.slice(0, 2 + potTokenDecimals)
                : value;
            setRoundReward(valueTrimmed);
          }
        }}
        onFocus={() => setRoundRewardFocused(true)}
        onBlur={() => setRoundRewardFocused(false)}
        error={errors.find((e) => e.id === `round-reward`)?.message}
        disabled={isPotTokenNative || isPrizeNFT}
      />
      <label
        className="text-16 font-medium mb-1 flex space-x-2"
        htmlFor="priority-pool-yes"
      >
        <span>Priority tournament</span>
        <div className="font-normal">
          <InfoTooltip
            position={bp640px ? Position.right : Position.top}
            tooltip={
              "If a tournament is priority, it is listed in front of non-priority tournaments. To make your tournament priority, you need to pay 10x the price of a non-priority tournament. Non-priority prices: 3 $MATIC, 0.01 $BNB, 6 $FTM, 0.2 $AVAX, plus gas."
            }
          />
        </div>
      </label>
      <div className="text-14 mb-3">
        <RadioButton
          id={`priority-pool-yes`}
          label={`Yes (Costs ${priorityPrice || "-"} ${
            tokenList?.[0]?.label || ""
          })`}
          checked={
            selectedPriorityPoolOption === priorityPrice ||
            !selectedPriorityPoolOption
          }
          onChange={() => setSelectedPriorityPoolOption(priorityPrice)}
        />
        <RadioButton
          id={`priority-pool-no`}
          label={`No (Costs ${nonPriorityPrice || "-"} ${
            tokenList?.[0]?.label || ""
          })`}
          checked={
            selectedPriorityPoolOption === nonPriorityPrice &&
            selectedPriorityPoolOption !== undefined
          }
          onChange={() => setSelectedPriorityPoolOption(nonPriorityPrice)}
        />
      </div>
      <div className="flex space-x-2 mt-4">
        <BackBtn onClick={() => setPage(1)} />
        {!wallet ? (
          <Button bgColor="green-green-darker" onClick={connectMetamask}>
            <div className="flex">
              <img
                src={walletWhite}
                alt=""
                width={24}
                height={24}
                className="mr-2"
              />
              Connect wallet
            </div>
          </Button>
        ) : chainIds.includes(chain) ? (
          <Button
            bgColor="green-green-darker"
            loading={loadingCreatePot}
            onClick={async () => {
              setTriedToSubmit(true);
              const errorsLength = validate().length;
              if (errorsLength === 0)
                await onCreatePot(
                  tokenList?.[selectedPotToken]?.address,
                  tokenList?.[selectedPotToken]?.address
                );
            }}
            disabled={notEnoughPotToken || notEnoughNativeToken || error}
          >
            {notEnoughPotToken || notEnoughNativeToken
              ? `Not enough ${
                  notEnoughPotToken
                    ? tokenList?.[selectedPotToken]?.label
                    : currentNetwork.data.nativeCurrency.symbol
                }`
              : "Build village"}
          </Button>
        ) : (
          <Button
            bgColor="green-green-darker"
            onClick={() => setNetworksModalOpen(true)}
          >
            Switch chain
          </Button>
        )}
      </div>
    </>
  );
}
