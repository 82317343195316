const bgColors = {
    green: "bg-green",
    "green-green-darker": "bg-green",
    grey2: "bg-grey2",
    white: "bg-white",
    black: "bg-black",
    orange: "bg-orange",
}

const bgColorsHover = {
    green: "hover:bg-green",
    "green-green-darker": "hover:bg-green-dark",
    grey2: "hover:bg-grey2",
    white: "hover:bg-white",
    black: "hover:bg-black-dark",
    orange: "hover:bg-orange-dark",
}

const bgColorsFocus = {
    green: "focus:bg-green-dark",
    "green-green-darker": "focus:bg-green-darker",
    grey2: "focus:bg-grey2-dark",
    white: "focus:bg-[#f0f0f0]",
    black: "focus:bg-black-darker",
    orange: "focus:bg-orange-darker",
}

export { bgColors, bgColorsHover, bgColorsFocus };