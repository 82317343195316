import Button from "../../../components/Button/Button";
import Svg from "../../../components/Svg";
import { BtnType } from "../../../types";

export default function Pagination({
    page,
    setPage,
    completedPage,
    lastPageIndex,
    className = "",
    error,
}) {
    return (
        <div className={`flex space-x-3 ${className}`}>
            {Array.from(Array(lastPageIndex).keys()).map(p => {
                const past = p < page;
                const active = p === page;
                const pastOrActive = past || active;
                return (
                    <Button
                        key={p.toString()}
                        type={past && !active ? BtnType.filled : BtnType.outlined}
                        bgColor={pastOrActive ? "green" : "grey2"}
                        textColor={active ? "green" : past ? "white" : "grey2"}
                        fontSize="text-12"
                        fontWeight="font-semibold"
                        borderColor={pastOrActive ? "green" : "grey2"}
                        borderRadius="rounded-full"
                        padding=""
                        className="w-[22px] h-[22px]"
                        onClick={() => setPage(p)}
                        disabled={p > completedPage || completedPage === undefined || error}
                    >
                        {past ? <Svg name={"check"} /> : p + 1}
                    </Button>
                )
            })}
        </div>
    )
}