import { useEffect } from "react";
import { useState } from "react";
import useNFTPots from "../contracts/NFTPot/useNFTPots";
import usePots from "../contracts/pot/usePots";
import useTournaments from "../contracts/tournaments/useTournaments";
import useWallet from "../hooks/useWallet";

import parseBigNumber from "../utils/parseBigNumber";

export default function useTournamentDetailsData(
  contractAddress,
  getContract,
  getGasLimit,
  getGasPrice,
  refreshTournamentData,
  decimalsBidToken,
  decimalsPotToken
) {
  const {
    canCallPots,
    getBidOption,
    getBidVariable1,
    getBidVariable2,
    getOwnerFee,
    getToPotFee,
    getLastBidWinner,
    getToPreviousBidderFee,
    getToTournamentCreator,
    getToTournamentCreatorFee,
    getToBurnFee,
    getBidInfo,
    getExpirationTime,
    getExpExpiryOption,
    getExpChangeBy,
    getExpMinimumTime,
    getRoundDuration,
    getRoundReward,
  } = usePots([contractAddress], getContract, getGasLimit, getGasPrice);

  const { wallet, currentNetwork } = useWallet();

  const { getOwner: getTopOwner } = useTournaments(wallet, currentNetwork);

  const {
    canCallNFTPots,
    getBidOption: getBidOptionNFT,
    getBidVariable1: getBidVariable1NFT,
    getBidVariable2: getBidVariable2NFT,
    getTopOwner: getTopOwnerNFT,
    getOwnerFee: getOwnerFeeNFT,
    getLastBidWinner: getLastBidWinnerNFT,
    getToPreviousBidderFee: getToPreviousBidderFeeNFT,
    getToTournamentCreator: getToTournamentCreatorNFT,
    getToTournamentCreatorFee: getToTournamentCreatorFeeNFT,
    getToBurnFee: getToBurnFeeNFT,
    getBidInfo: getBidInfoNFT,
    getExpirationTime: getExpirationTimeNFT,
    getExpExpiryOption: getExpExpiryOptionNFT,
    getExpChangeBy: getExpChangeByNFT,
    getExpMinimumTime: getExpMinimumTimeNFT,
  } = useNFTPots([contractAddress], getContract, getGasLimit, getGasPrice);

  const [loading, setLoading] = useState(false);
  const [initDone, setInitDone] = useState(false);

  const [bidOption, setBidOption] = useState();
  const [bidVariable1, setBidVariable1] = useState();
  const [bidVariable2, setBidVariable2] = useState();
  const [topOwner, setTopOwner] = useState();
  const [ownerFee, setOwnerFee] = useState();
  const [toPotFee, setToPotFee] = useState();
  const [toPreviousBidder, setToPreviousBidder] = useState();
  const [toPreviousBidderFee, setToPreviousBidderFee] = useState();
  const [toTournamentCreator, setToTournamentCreator] = useState();
  const [toTournamentCreatorFee, setToTournamentCreatorFee] = useState();
  const [toBurnFee, setToBurnFee] = useState();
  const [bidInfo, setBidInfo] = useState([]);
  const [expExpiryOption, setExpExpiryOption] = useState();
  const [expirationTime, setExpirationTime] = useState();
  const [expChangeBy, setExpChangeBy] = useState();
  const [expMinimumTime, setExpMinimumTime] = useState();
  const [roundDuration, setRoundDuration] = useState();
  const [roundReward, setRoundReward] = useState();

  const updateData = async (refreshAll) => {
    const tournamentAddress =
      currentNetwork?.tournamentsAddresses?.[
        currentNetwork?.tournamentsAddresses.length - 1
      ];

    setLoading(true);

    if (refreshAll) await refreshTournamentData(contractAddress);
    const bidOption = await getBidOption(contractAddress);
    setBidOption(bidOption);
    if (bidOption === 1)
      setBidVariable1((await getBidVariable1(contractAddress))?.toNumber());
    else
      setBidVariable1(
        parseBigNumber(await getBidVariable1(contractAddress), decimalsBidToken)
      );
    setBidVariable2((await getBidVariable2(contractAddress))?.toNumber());

    setTopOwner(await getTopOwner(tournamentAddress));
    setOwnerFee(await getOwnerFee(contractAddress));
    setToPotFee(await getToPotFee(contractAddress));
    setToPreviousBidder(await getLastBidWinner(contractAddress));
    setToPreviousBidderFee(await getToPreviousBidderFee(contractAddress));
    setToTournamentCreator(await getToTournamentCreator(contractAddress));
    setToTournamentCreatorFee(await getToTournamentCreatorFee(contractAddress));
    setToBurnFee(await getToBurnFee(contractAddress));
    setBidInfo(await getBidInfo(contractAddress));
    setExpirationTime(await getExpirationTime(contractAddress));
    setExpExpiryOption(await getExpExpiryOption(contractAddress));
    setExpChangeBy(await getExpChangeBy(contractAddress));
    setExpMinimumTime(await getExpMinimumTime(contractAddress));

    try {
      setRoundDuration(await getRoundDuration(contractAddress));
      const roundReward = await getRoundReward(contractAddress);
      setRoundReward(Number(roundReward) / 10 ** decimalsPotToken);
    } catch {}

    setLoading(false);
    if (!initDone) setInitDone(true);
  };

  const updateNFTData = async (refreshAll) => {
    setLoading(true);

    if (refreshAll) await refreshTournamentData(contractAddress);
    const bidOption = await getBidOptionNFT(contractAddress);
    setBidOption(bidOption);
    setBidVariable1(
      parseBigNumber(
        await getBidVariable1NFT(contractAddress),
        decimalsBidToken
      )
    );
    setBidVariable2((await getBidVariable2NFT(contractAddress))?.toNumber());
    setTopOwner(await getTopOwnerNFT(contractAddress));
    setOwnerFee(await getOwnerFeeNFT(contractAddress));
    setToPreviousBidder(await getLastBidWinnerNFT(contractAddress));
    setToPreviousBidderFee(await getToPreviousBidderFeeNFT(contractAddress));
    setToTournamentCreator(await getToTournamentCreatorNFT(contractAddress));
    setToTournamentCreatorFee(
      await getToTournamentCreatorFeeNFT(contractAddress)
    );
    setToBurnFee(await getToBurnFeeNFT(contractAddress));
    setBidInfo(await getBidInfoNFT(contractAddress));
    setExpirationTime(await getExpirationTimeNFT(contractAddress));
    setExpExpiryOption(await getExpExpiryOptionNFT(contractAddress));
    setExpChangeBy(await getExpChangeByNFT(contractAddress));
    setExpMinimumTime(await getExpMinimumTimeNFT(contractAddress));

    setLoading(false);
    if (!initDone) setInitDone(true);
  };

  const getBidPriceDistributionsSorted = () => {
    return bidInfo
      .concat([
        {
          toAddress: `tournament - ${contractAddress}`,
          percentage: toPotFee || 0,
        },
        {
          toAddress: `creator - ${toTournamentCreator}`,
          percentage: toTournamentCreatorFee || 0,
        },
        { toAddress: `team - ${topOwner}`, percentage: ownerFee || 0 },
        {
          toAddress: `burn - 0x000000000000000000000000000000000000dEaD`,
          percentage: toBurnFee || 0,
        },
        {
          toAddress: `previous bidder - ${toPreviousBidder}`,
          percentage: toPreviousBidderFee || 0,
        },
      ])
      .filter((dist) => dist.percentage > 0)
      .sort((a, b) => b.percentage - a.percentage);
  };

  return {
    canCallPots,
    canCallNFTPots,
    loading,
    initDone,
    bidOption,
    bidVariable1,
    bidVariable2,
    toPotFee,
    expExpiryOption,
    expirationTime,
    expChangeBy,
    expMinimumTime,
    roundDuration,
    roundReward,
    updateData,
    updateNFTData,
    getBidPriceDistributionsSorted,
  };
}
