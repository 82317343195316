import useMediaQuery from "../../hooks/useMediaQuery";
import useWhitelist from "../../hooks/useWhitelist";
import InfoTooltip from "../../pages/Tournament/components/InfoTooltip";
import { Position, Size, TournamentType } from "../../types";
import Button from "../Button/Button";
import Input from "../Input";
import Svg from "../Svg";
import Modal from "./Modal";

export default function WhitelistModal({
    whitelistModalOpen,
    setWhitelistModalOpen,
    tournament,
    wallet,
    getContract,
    getGasLimit,
    getGasPrice,
    whitelistedAddresses,
    watchTransaction,
    refreshWhitelistedAddresses,
}) {
    const bp640px = useMediaQuery(640);

    const contractAddress = tournament.contractAddress;
    const isNFT = tournament.isNFT;
    const tournamentType = tournament.tournamentType;
    const isActive = tournamentType === TournamentType.active;
    const ownerOfTournament = tournament?.ownerOfTournament?.toLowerCase();
    const isWalletOwnerOfTournament = ownerOfTournament === wallet;
    const isPriority = tournament.isPriority;

    const {
        whitelistErrors,
        setWhitelistErrors,
        newWhitelistedAddress,
        setNewWhitelistedAddress,
        setTriedToSubmitNewWhitelistAddress,
        loadingAddWhitelistedAddress,
        onWhitelist,
        validateWhitelist,
    } = useWhitelist(contractAddress,
        isNFT,
        getContract,
        getGasLimit,
        getGasPrice,
        refreshWhitelistedAddresses,
        watchTransaction,
        whitelistedAddresses
    );

    return (
        <Modal
            open={whitelistModalOpen}
            setOpen={setWhitelistModalOpen}
            onHide={() => {
                setNewWhitelistedAddress("");
                setWhitelistErrors([]);
                setTriedToSubmitNewWhitelistAddress(false);
            }}
            title={
                <div className="flex space-x-2 items-center">
                    <span>Whitelisted addresses</span>
                    <InfoTooltip
                        optionalRefresh={whitelistModalOpen}
                        size={Size.lg}
                        position={bp640px ? Position.top : Position.left}
                        tooltip={"As the creator, you can choose to make your tournament only available to certain address. You do this by changing the nature of the tournament to whitelist only after you create it but before you deposit the prize. These are permanent, cannot be removed. However, you can blacklist an address if they display an unsportsmanlike conduct this will prevent that address from ever bidding again."} />
                </div>
            }
        >
            <div className="mb-8">
                {whitelistedAddresses.length > 0
                    ?
                    <ul className="w-full max-h-40 overflow-y-auto pr-2 list-disc list-inside">
                        {whitelistedAddresses.map((address, i) => {
                            return (
                                <li key={i.toString()} className="mb-0 break-all">{address}</li>
                            )
                        })}
                    </ul>
                    : <p className='italic max-h-40 mb-0'>There are no whitelisted addresses</p>
                }
            </div>
            {isActive && isWalletOwnerOfTournament &&
                <>
                    <Input
                        id={`${contractAddress}-whitelist-new-address`}
                        label={"Add new address"}
                        className="mb-2"
                        fullWidth
                        value={newWhitelistedAddress}
                        onChange={(e) => setNewWhitelistedAddress(e.target.value)}
                        error={whitelistErrors.find(e => e.id === `whitelist-new-address`)?.message}
                    />
                    <Button
                        bgColor={isPriority ? "orange" : 'green-green-darker'}
                        borderColor={isPriority ? "orange" : 'green-green-darker'}
                        className="w-full"
                        loading={loadingAddWhitelistedAddress}
                        onClick={async () => {
                            setTriedToSubmitNewWhitelistAddress(true);
                            const errorsLength = validateWhitelist().length;
                            if (errorsLength === 0) await onWhitelist(newWhitelistedAddress);
                        }}>
                        <div className='flex items-center'>
                            <Svg name={"plus"} />
                            Add
                        </div>
                    </Button>
                </>
            }
        </Modal>
    )
}