import { useEffect, useRef } from "react";
import { Size } from "../../types";
import Svg from "../Svg";

export default function Modal({
    open,
    setOpen,
    onHide,
    children,
    title,
    titleFontSize = "text-20 sm:text-24",
    padding = "px-5 py-3",
    size = Size.sm,
}) {
    const ref = useRef();

    useEffect(() => {
        const hideModal = (e) => {
            if (e.target === ref?.current) {
                setOpen(false);
                if (onHide) onHide();
            }
        };
        window.addEventListener("mousedown", hideModal);
        return () => window.removeEventListener("mousedown", hideModal);
    }, [ref]);

    return (
        <div
            ref={ref}
            className={`${open ? "flex" : "hidden"} fixed z-50 left-0 top-0 w-full h-full overflow-auto bg-black bg-opacity-40 justify-center items-center px-1 py-10`}
        >
            <div className={`relative bg-white mx-3 my-auto sm:mx-auto rounded-[8px]
                ${size === Size.sm ? "w-full sm:w-4/5 md:w-3/4 lg:w-2/3 xl:w-1/2 2xl:w-1/3" : "w-full md:w-4/5 lg:w-3/4 xl:w-2/3 2xl:w-1/2"}`}>
                <div className="bg-[#F3F7FD] px-5 py-3 flex justify-between items-start sm:items-center space-x-3
                border-b border-[#DBE0EB] rounded-t-[8px]">
                    <h2 className={`${titleFontSize} font-medium text-left w-full`}>{title}</h2>
                    <Svg
                        name={"close"}
                        className="fill-black opacity-75 hover:opacity-100 cursor-pointer transition duration-300"
                        onClick={() => {
                            setOpen(false);
                            if (onHide) onHide();
                        }} />
                </div>
                <div className={padding}>
                    {children}
                </div>
            </div>
        </div>
    )
}