const strokeColors = {
    green: "stroke-green",
    "green-green-darker": "stroke-green",
    grey2: "stroke-grey2",
    "grey2-grey2-darker": "stroke-grey2",
    white: "stroke-white",
    red: "stroke-red",
    "white-black": "stroke-white",
    "black-white": "stroke-black",
    "grey3": "stroke-grey3",
    "orange": "stroke-orange",
}

const strokeColorsHover = {
    green: "hover:stroke-white",
    "green-green-darker": "hover:stroke-green-dark",
    grey2: "hover:stroke-white",
    "grey2-grey2-darker": "hover:stroke-grey2-dark",
    white: "hover:stroke-white",
    red: "hover:stroke-red-dark",
    "white-black": "hover:stroke-black",
    "black-white": "hover:stroke-white",
    "grey3": "hover:stroke-grey3-light",
    "orange": "hover:stroke-orange-dark",
}

const strokeColorsFocus = {
    green: "focus:stroke-white",
    "green-green-darker": "focus:stroke-green-darker",
    grey2: "focus:stroke-white",
    "grey2-grey2-darker": "focus:stroke-grey2-darker",
    white: "focus:stroke-white",
    red: "focus:stroke-red-darker",
    "white-black": "focus:stroke-black",
    "black-white": "focus:stroke-white",
    "grey3": "focus:stroke-white",
    "orange": "focus:stroke-orange-darker",
}

export { strokeColors, strokeColorsHover, strokeColorsFocus };