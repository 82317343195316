const textColors = {
    white: "text-white",
    green: "text-green",
    "green-green-darker": "text-green",
    grey2: "text-grey2",
    "grey2-grey2-darker": "grey2-grey2",
    red: "text-red",
    "white-black": "text-white",
    "black-white": "text-black",
    "grey3": "text-grey3",
    "orange": "text-orange",
}

const textColorsHover = {
    white: "hover:text-white",
    green: "hover:text-white",
    "green-green-darker": "hover:text-green-dark",
    grey2: "hover:text-white",
    "grey2-grey2-darker": "hover:text-grey2-dark",
    red: "hover:text-red-dark",
    "white-black": "hover:text-black",
    "black-white": "hover:text-white",
    "grey3": "hover:text-grey3-light",
    "orange": "hover:text-orange-dark",
}

const textColorsFocus = {
    white: "focus:text-white",
    green: "focus:text-white",
    "green-green-darker": "hover:text-green-darker",
    grey2: "focus:text-white",
    "grey2-grey2-darker": "focus:text-grey2-darker",
    red: "focus:text-red-darker",
    "white-black": "focus:text-black",
    "black-white": "focus:text-white",
    "grey3": "focus:text-white",
    "orange": "focus:text-orange-darker",
}

export { textColors, textColorsHover, textColorsFocus };