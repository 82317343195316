import { useLocation } from "react-router-dom";
import logo from "../assets/icons/footer/logo.svg";
import { BtnType, TournamentType } from "../types";
import Button from "./Button/Button";
import Link from "./Link/Link";
import Svg from "./Svg";

export default function Footer({ setTournamentTypeToGoTo }) {
  const location = useLocation();
  const onHome = location.pathname === "/";

  const tournamentLinks = [
    { label: "Villages under attack", tournamentType: TournamentType.active },
    { label: "Successful raids", tournamentType: TournamentType.expired },
    { label: "Raiders already left", tournamentType: TournamentType.creator },
    {
      label: "Abandoned villages",
      tournamentType: TournamentType.topOwner,
    },
    { label: "Destroyed villages", tournamentType: TournamentType.closed },
  ];

  const apiLinks = [
    { label: "PolygonScan", href: "https://polygonscan.com/" },
    { label: "SnowTrace", href: "https://snowtrace.io/" },
    { label: "BscScan", href: "https://bscscan.com/" },
    { label: "FTMScan", href: "https://ftmscan.com" },
    { label: "ArbiScan", href: "https://arbiscan.io" },
  ];

  return (
    <footer>
      <div className="bg-black flex justify-center px-3 py-20 text-center sm:text-left">
        <div className="container px-3">
          <div className="grid grid-cols-2 gap-12">
            <div className="col-span-2 2xl:col-span-1">
              <div className="flex space-x-2 justify-center sm:justify-start mb-6">
                <img src={logo} alt="" width={48} height={48} />
                <p className="text-32 text-white font-medium">Clash of Memes</p>
              </div>
              <p className="text-20 text-grey3 font-medium">
                Life changing opportunities at your fingertips
              </p>
            </div>
            <div className="col-span-2 2xl:col-span-1 grid grid-cols-3 gap-6">
              <div className="col-span-3 md:col-span-1 flex flex-col items-center sm:items-start">
                <p className="text-16 text-grey3 font-medium mb-4">
                  Map of villages
                </p>
                {tournamentLinks.map((link) => {
                  return onHome ? (
                    <Button
                      key={link.label}
                      type={BtnType.linklike}
                      textColor="grey3"
                      fontSize="text-14"
                      fontWeight=""
                      className="opacity-60 mb-3"
                      padding=""
                      onClick={() =>
                        setTournamentTypeToGoTo(link.tournamentType)
                      }
                    >
                      {link.label}
                    </Button>
                  ) : (
                    <Link
                      key={link.label}
                      textColor="grey3"
                      padding=""
                      fontWeight=""
                      fontSize="text-14"
                      className="opacity-60 mb-3"
                      asRouterLink
                      to={{ pathname: "/" }}
                      state={{ tournamentType: link.tournamentType }}
                    >
                      {link.label}
                    </Link>
                  );
                })}
              </div>
              <div className="col-span-3 md:col-span-1 flex flex-col items-center sm:items-start">
                <p className="text-16 text-grey3 font-medium mb-4">
                  Powered by APIs
                </p>
                {apiLinks.map((apiLink) => {
                  return (
                    <Link
                      key={apiLink.label}
                      href={apiLink.href}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                      textColor="grey3"
                      padding=""
                      fontWeight=""
                      fontSize="text-14"
                      className="opacity-60 mb-3"
                    >
                      {apiLink.label}
                    </Link>
                  );
                })}
              </div>
              <div className="col-span-3 md:col-span-1 flex flex-col items-center sm:items-start">
                <p className="text-16 text-grey3 font-medium mb-4">About</p>
                <Link
                  href={"https://twitter.com/TokenCombat"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  textColor="grey3"
                  padding=""
                  fontWeight=""
                  fontSize="text-14"
                  className="opacity-60 mb-3"
                >
                  <div className="flex space-x-1 items-center">
                    <div className="w-5">
                      <Svg name={"twitter"} />
                    </div>
                    <span>Twitter</span>
                  </div>
                </Link>
                <Link
                  href={"https://t.me/tokencombat"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  textColor="grey3"
                  padding=""
                  fontWeight=""
                  fontSize="text-14"
                  className="opacity-60 mb-3"
                >
                  <div className="flex space-x-1 items-center">
                    <div className="w-5">
                      <Svg name={"telegram"} />
                    </div>
                    <span>Telegram</span>
                  </div>
                </Link>
                <Link
                  href={"https://discord.gg/y2bBtyPZv6"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  textColor="grey3"
                  padding=""
                  fontWeight=""
                  fontSize="text-14"
                  className="opacity-60 mb-3"
                >
                  <div className="flex space-x-1 items-center">
                    <div className="w-5">
                      <Svg name={"discord"} />
                    </div>
                    <span>Discord</span>
                  </div>
                </Link>
                <Link
                  href={"https://docs.tokencombat.io"}
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  textColor="grey3"
                  padding=""
                  fontWeight=""
                  fontSize="text-14"
                  className="opacity-60 mb-3"
                >
                  <div className="flex space-x-1 items-center">
                    <div className="w-5">
                      <Svg name={"doc"} />
                    </div>
                    <span>Docs</span>
                  </div>
                </Link>
                <Link
                  target={"_blank"}
                  rel={"noopener noreferrer"}
                  textColor="grey3"
                  padding=""
                  fontWeight=""
                  fontSize="text-14"
                  disabled
                >
                  <div className="flex space-x-1 items-center">
                    <div className="w-5">
                      <Svg name={"handshake"} />
                    </div>
                    <span>Partners</span>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#222733] px-3 py-5">
        <p className="text-14 text-grey3 text-center">
          Copyright © Clash of Memes | 2024
        </p>
      </div>
    </footer>
  );
}
