import { useEffect } from "react";
import useNFTPots from "../../../contracts/NFTPot/useNFTPots";

export default function useNFTPotsInit(
    prizeTokenType,
    pairAddress,
    getContract,
    getGasLimit,
    getGasPrice,
    setWhitelisted,
) {
    const {
        depositERC721Token,
        setWhiteListOnly,
        getWhitelistOnly,
        canCallNFTPots,
    } = useNFTPots([pairAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    useEffect(() => {
        const init = async () => {
            setWhitelisted(await getWhitelistOnly(pairAddress));
        }
        if (canCallNFTPots && prizeTokenType === "1" && pairAddress) init();
    }, [canCallNFTPots, prizeTokenType, pairAddress]);

    return {
        depositERC721Token,
        setWhiteListOnly,
    }
}