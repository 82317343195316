import polygon from "../src/assets/icons/polygon.svg";
import avalanche from "../src/assets/icons/avalanche.svg";
import bsc from "../src/assets/icons/bsc.svg";
import eth from "../src/assets/icons/eth.svg";
import fantom from "../src/assets/icons/fantom.svg";
import arbitrum from "../src/assets/icons/arbitrum.svg";

const polygonIcon = () => (
  <img src={polygon} alt="" width={19.2} height={16.75} />
);
const avalancheIcon = () => (
  <img src={avalanche} alt="" width={16.75} height={16.75} />
);
const bscIcon = () => <img src={bsc} alt="" width={16.75} height={16.75} />;
const fantomIcon = () => (
  <img src={fantom} alt="" width={16.75} height={16.75} />
);
const ethIcon = () => <img src={eth} alt="" width={16.75} height={16.75} />;
const arbitrumIcon = () => (
  <img src={arbitrum} alt="" width={16.75} height={16.75} />
);

const polygonMumbaiNetwork = {
  icon: polygonIcon(),
  tournamentsAddresses: [
    "0x1C251CED8b7B4c6885dF42020f467E6b6ecA17F0",
    "0xe9527C12186cb1d8b69A6D3EDdB4C2d2DB6F7274",
    "0x5B5C3c42f12134D9901fF17410598DB275bEd9e6",
    "0xa2Faa81c6c79A5646C2E718BF2CF299670f4C73a",
  ],
  NFTTournamentsAddresses: ["0x1B234A7457DF8303f3C65747448BB3D2AF0039D3"],
  displayName: "Mumbai",
  data: {
    chainId: 80001,
    chainName: "Mumbai",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    blockExplorerUrls: ["https://mumbai.polygonscan.com/"],
  },
};

const polygonNetwork = {
  icon: polygonIcon(),
  tournamentsAddresses: ["0xeb94CB41EAB3bBeA3be756ABAe7dFB50dfDF6FB1"],
  NFTTournamentsAddresses: ["0xdD96330a3c7194CB2C881187f5F9f598BA4D1875"],
  displayName: "Polygon",
  apiKey: "YFDYAGI15T4B36C9YDYI2MCC7WEF8D549R",
  data: {
    chainId: 137,
    chainName: "Polygon Mainnet",
    nativeCurrency: {
      name: "Matic",
      symbol: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
  },
};

const avalancheFujiNetwork = {
  icon: avalancheIcon(),
  tournamentsAddresses: ["0xD32Fae8aB6fA5c0DFb5bCbc89fAE23DEb5C41Eb6"],
  NFTTournamentsAddresses: [],
  displayName: "Avalanche Fuji",
  data: {
    chainId: 43113,
    chainName: "Avalanche FUJI C-Chain",
    nativeCurrency: {
      name: "Avax",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax-test.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://testnet.snowtrace.io/"],
  },
};

const avalancheNetwork = {
  icon: avalancheIcon(),
  tournamentsAddresses: ["0x3ccb6A0cdDFfd33BD176108462cD59f4B9344B9B"],
  NFTTournamentsAddresses: ["0xa5EdBCCAFd9bfC4517Ab62299cA7461BE3DE591a"],
  displayName: "Avalanche",
  apiKey: "DSZCIWVJ3M93X4K25TB5PVHINRMYSGPWXM",
  data: {
    chainId: 43114,
    chainName: "Avalanche Network",
    nativeCurrency: {
      name: "Avax",
      symbol: "AVAX",
      decimals: 18,
    },
    rpcUrls: ["https://api.avax.network/ext/bc/C/rpc"],
    blockExplorerUrls: ["https://snowtrace.io/"],
  },
};

const bscTestNetwork = {
  icon: bscIcon(),
  tournamentsAddresses: ["0xeC307aa16AecE460fD6eEf49121A3ce25A1eE352"],
  NFTTournamentsAddresses: ["0x568aa4f5c20f3B59e7D3DeE420F3e4CED537abd1"],
  displayName: "BNB Testnet",
  data: {
    chainId: 97,
    chainName: "Binance Smart Chain Testnet",
    nativeCurrency: {
      name: "tBNB",
      symbol: "tBNB",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],
  },
};

const bscNetwork = {
  icon: bscIcon(),
  tournamentsAddresses: [
    "0x0197ba4cEB059e0d81A7588465bD21469074a317",
    "0xa0D0265c4eEF1AF1EF6986db0D90150c68D74050",
  ],
  NFTTournamentsAddresses: ["0x4D1f4E26ACdF0b413AD00a146E9CbD6b67F0cc0b"],
  displayName: "BNB Smart Chain",
  apiKey: "D9HVT34CHHTVUMNEPZFVHV8ZW84NNTV93G",
  data: {
    chainId: 56,
    chainName: "BNB Smart Chain (previously Binance Smart Chain Mainnet)",
    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com/"],
  },
};

const fantomNetwork = {
  icon: fantomIcon(),
  tournamentsAddresses: ["0xc31DE2e0Cf568E3de04FC0fF52391Ccdab7e1Acf"],
  NFTTournamentsAddresses: ["0xF24ADA38c1D4F1A91c226a574C488a44e922A0E9"],
  displayName: "Fantom Opera",
  apiKey: "FCXITDN5RJHH7KIIDGMGEMDZEFRY4B5N7D",
  data: {
    chainId: 250,
    chainName: "Fantom Opera Mainnet",
    nativeCurrency: {
      name: "Ftm",
      symbol: "FTM",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.ftm.tools/"],
    blockExplorerUrls: [" "],
  },
};

const ethSepoliaNetwork = {
  icon: ethIcon(),
  tournamentsAddresses: ["0xc19823f6C1e2E4B7b31a87C05ccaa33fe83BDfe7"],
  NFTTournamentsAddresses: [""],
  displayName: "Ethereum Sepolia",
  apiKey: "2Q6PMWPKRN9EJ77AZ4QR2PGIYNS1S1P6SS",
  data: {
    chainId: 11155111,
    chainName: "Sepolia",
    nativeCurrency: {
      name: "Weth",
      symbol: "ETH",
      decimals: 18,
      address: "0x0000000000000000000000000000000000000000",
    },
    rpcUrls: [
      "wss://eth-sepolia.g.alchemy.com/v2/PJhzqyWhzkKGLz_bwAjYpjmjOsJxF24H",
      "https://endpoints.omniatech.io/v1/eth/sepolia/public",
    ],
    blockExplorerUrls: ["https://sepolia.etherscan.io"],
  },
};

const arbitrumNetwork = {
  icon: arbitrumIcon(),
  tournamentsAddresses: ["0x572A1953D17aA838468FBA6199968986e64E7A5D"],
  NFTTournamentsAddresses: ["0x3f9641F6778aBB6b128ac3aF693cbC3BAE6DaC5b"],
  displayName: "Arbitrum One",
  apiKey: "UVT363JWP7N8YS2WZMAGCC8NIS69UPD3J8",
  data: {
    chainId: 42161,
    chainName: "Arbitrum One",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18,
    },
    rpcUrls: ["https://arb1.arbitrum.io/rpc"],
    blockExplorerUrls: ["https://arbiscan.io"],
  },
};

const networksInBothMode = {
  development: [
    polygonMumbaiNetwork,
    avalancheFujiNetwork,
    bscTestNetwork,
    fantomNetwork,
    ethSepoliaNetwork,
  ],
  production: [
    polygonNetwork,
    avalancheNetwork,
    bscNetwork,
    fantomNetwork,
    arbitrumNetwork,
    ethSepoliaNetwork,
  ],
};
const env = process.env.NODE_ENV;
const networks = networksInBothMode[env];

const chainIds = networks.map((network) => network.data.chainId);

export default networks;

export { chainIds };
