import onlyNumbersOrEmpty from "../../../utils/onlyNumbersOrEmpty";
import { useState } from "react";
import { useEffect } from "react";
import Button from "../../../components/Button/Button";
import { BtnType, Position, Size } from "../../../types";
import Svg from "../../../components/Svg";
import NextBtn from "../components/NextBtn";
import BackBtn from "../components/BackBtn";
import Input from "../../../components/Input";
import PercentEndAdornment from "../components/PercentEndAdornment";
import InfoTooltip from "../components/InfoTooltip";
import useMediaQuery from "../../../hooks/useMediaQuery";

export default function Page1({
  prizeTokenType,
  topOwnerFee,
  creatorFee,
  setCreatorFee,
  potFee,
  setPotFee,
  toPreviousFee,
  setToPreviousFee,
  burn,
  setBurn,
  distributions,
  setDistributions,
  isBidTokenNative,
  setPage,
  wallet,
  connectMetamask,
  chain,
  setNetworksModalOpen,
  error,
}) {
  const isPrizeNFT = prizeTokenType === "1";

  const bp640px = useMediaQuery(640);
  const bp1280px = useMediaQuery(1280);

  const [errors, setErrors] = useState([]);
  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const sumOfDistributions =
    parseFloat(topOwnerFee) +
    parseFloat(creatorFee || 0) +
    parseFloat(potFee || 0) +
    parseFloat(toPreviousFee || 0) +
    parseFloat(burn || 0) +
    distributions
      .map((d) => parseFloat(d.value || 0))
      .reduce((a, b) => a + b, 0);

  const validate = () => {
    const errors = [];

    const distributionAddressNumbers = [];
    distributions.forEach((distribution, i) => {
      const element = distributionAddressNumbers.find(
        (d) => d.address.toLowerCase() === distribution.address.toLowerCase()
      );
      if (!!element) element.count++;
      else
        distributionAddressNumbers.push({
          address: distribution.address.toLowerCase(),
          count: 1,
        });
    });

    distributions.forEach((distribution, i) => {
      if (!distribution.address)
        errors.push({
          id: `distribution-address-${i}`,
          message: "Please provide a valid address.",
        });

      if (!parseFloat(distribution.value))
        errors.push({
          id: `distribution-value-${i}`,
          message: "Please provide a valid value.",
        });

      const element = distributionAddressNumbers.find(
        (d) =>
          d.address.toLowerCase() === distribution.address.toLowerCase() &&
          d.count > 1
      );
      if (!!element)
        errors.push({
          id: `distribution-address-${i}`,
          message: "The address is listed twice.",
        });
    });

    if (sumOfDistributions !== 100) {
      const message = "The sum of the distributions must be 100%.";
      errors.push({
        id: "bid-price-distribution-creator-of-tournament",
        message: message,
      });
      if (!isPrizeNFT)
        errors.push({ id: "bid-price-distribution-pot-fee", message: message });
      errors.push({
        id: "bid-price-distribution-previous-bidder",
        message: message,
      });
      if (!isBidTokenNative)
        errors.push({ id: "bid-price-distribution-burn", message: message });
      distributions.forEach((distribution, i) => {
        errors.push({ id: `distribution-value-${i}`, message: message });
      });
    }

    setErrors(errors);
    return errors;
  };

  useEffect(() => {
    if (triedToSubmit) validate();
  }, [
    triedToSubmit,
    topOwnerFee,
    creatorFee,
    potFee,
    toPreviousFee,
    burn,
    distributions,
  ]);

  return (
    <>
      <div className="text-28 font-bold mb-1 flex space-x-2 items-center">
        <span>How is the raid fee distributed?</span>
        <div className="font-normal">
          <InfoTooltip
            position={bp1280px ? Position.top : Position.left}
            size={Size.lg}
            tooltip={
              "You are in full control of how every bid is distributed! You can set any address and assign any percentage to it. Note that you can only enter round numbers and the sum must add up to 100%."
            }
          />
        </div>
      </div>
      <p className="text-18 text-grey2 font-medium mb-8">
        (must add up to 100%)
      </p>
      <Input
        id={`bid-price-distribution-creator-of-tournament`}
        label={
          <>
            <span>Tournament Creator (your address)</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.left}
              tooltip={
                "The given percentage of each bid going to the wallet address with which you create the tournament."
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        placeholder={"10"}
        padding={"pr-16 pl-4"}
        className="mb-1.5"
        fullWidth
        value={creatorFee}
        onChange={(e) => {
          const value = e.target.value;
          if (onlyNumbersOrEmpty(value)) setCreatorFee(value);
        }}
        error={
          errors.find(
            (e) => e.id === "bid-price-distribution-creator-of-tournament"
          )?.message
        }
        endAdornment={<PercentEndAdornment />}
      />
      {!isPrizeNFT && (
        <Input
          id={`bid-price-distribution-pot-fee`}
          label={
            <>
              <span>Prize pool</span>
              <InfoTooltip
                position={bp640px ? Position.right : Position.top}
                tooltip={
                  "The given percentage of each bid going to the tournament contract itself that will be created by you. Note that if the raid token and the Treasure token address differ, the contract will keep both tokens and send both to the winner, when claimed."
                }
              />
            </>
          }
          labelClassName="flex space-x-2"
          placeholder={"10"}
          padding={"pr-16 pl-4"}
          className="mb-1.5"
          fullWidth
          value={potFee}
          onChange={(e) => {
            const value = e.target.value;
            if (onlyNumbersOrEmpty(value)) setPotFee(value);
          }}
          error={
            errors.find((e) => e.id === "bid-price-distribution-pot-fee")
              ?.message
          }
          endAdornment={<PercentEndAdornment />}
        />
      )}
      <Input
        id={`bid-price-distribution-previous-bidder`}
        label={
          <>
            <span>Previous bidder (first goes to creator)</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.left}
              tooltip={
                "The given percentage of each bid going to the last bidder. In case of the first bid it is going to the tournament creator's wallet address."
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        placeholder={"10"}
        padding={"pr-16 pl-4"}
        className="mb-1.5"
        fullWidth
        value={toPreviousFee}
        onChange={(e) => {
          const value = e.target.value;
          if (onlyNumbersOrEmpty(value)) setToPreviousFee(value);
        }}
        error={
          errors.find((e) => e.id === "bid-price-distribution-previous-bidder")
            ?.message
        }
        endAdornment={<PercentEndAdornment />}
      />
      <Input
        id={`bid-price-distribution-burn`}
        label={
          <>
            <span>To be burned</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.top}
              tooltip={
                <p>
                  The given percentage of each bid going to the burn address
                  <br />
                  <span className="break-all">
                    (0x000000000000000000000000000000000000dEaD)
                  </span>
                  .
                </p>
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        placeholder={isBidTokenNative ? "" : "10"}
        padding={"pr-16 pl-4"}
        className="mb-1.5"
        fullWidth
        value={burn}
        onChange={(e) => {
          const value = e.target.value;
          if (onlyNumbersOrEmpty(value)) setBurn(value);
        }}
        error={
          errors.find((e) => e.id === "bid-price-distribution-burn")?.message
        }
        endAdornment={<PercentEndAdornment />}
        disabled={isBidTokenNative}
      />
      <Input
        id={`defi-battle-royale-team`}
        label={
          <>
            <span>Clash of Memes team</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.top}
              tooltip={
                "Our team takes a modest 3% of each bid to fund the further development and marketing of the protocol."
              }
            />
          </>
        }
        labelClassName="flex space-x-2"
        className="col-span-2 lg:col-span-1"
        fullWidth
        value={`${topOwnerFee}%`}
        disabled
      />
      {distributions.map((distribution, i) => {
        return (
          <div key={i.toString()} className="mt-4">
            <div className="flex justify-between items-center">
              <p className="text-16 font-medium mb-0">Distribution {i + 1}</p>
              <Button
                type={BtnType.linklike}
                textColor="red"
                padding=""
                onClick={() =>
                  setDistributions(distributions.filter((d, j) => j !== i))
                }
              >
                <Svg name={"trashbin"} />
              </Button>
            </div>
            <div className="grid grid-cols-2 gap-2 lg:gap-4">
              <div className="col-span-2 lg:col-span-1">
                <Input
                  id={`distribution-address-${i}`}
                  label={"Address"}
                  fullWidth
                  value={distribution.address}
                  onChange={(e) => {
                    const newState = distributions.slice();
                    newState[i].address = e.target.value;
                    setDistributions(newState);
                  }}
                  error={
                    errors.find((e) => e.id === `distribution-address-${i}`)
                      ?.message
                  }
                />
              </div>
              <div className="col-span-2 lg:col-span-1">
                <Input
                  id={`distribution-value-${i}`}
                  label={"Amount"}
                  placeholder={"10"}
                  padding={"pr-16 pl-4"}
                  fullWidth
                  value={distribution.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (onlyNumbersOrEmpty(value)) {
                      const newState = distributions.slice();
                      newState[i].value = value;
                      setDistributions(newState);
                    }
                  }}
                  error={
                    errors.find((e) => e.id === `distribution-value-${i}`)
                      ?.message
                  }
                  endAdornment={<PercentEndAdornment />}
                />
              </div>
            </div>
          </div>
        );
      })}
      <Button
        type={BtnType.linklike}
        textColor="green-green-darker"
        padding=""
        className="mt-4 mb-2"
        onClick={() => {
          const newState = distributions.slice();
          newState.push({
            value: "",
            address: "",
          });
          setDistributions(newState);
        }}
        disabled={distributions.length >= 100 - Number(topOwnerFee)}
      >
        {distributions.length >= 100 - Number(topOwnerFee) ? (
          `Limit of ${100 - Number(topOwnerFee)} reached`
        ) : (
          <div className="flex items-center space-x-2">
            <Svg name={"plus"} />
            <span>Add Bid Distribution</span>
            <InfoTooltip
              position={bp640px ? Position.right : Position.left}
              tooltip={
                "Aside from the previous inputs, you can add as many distributions as you want."
              }
            />
          </div>
        )}
      </Button>
      <p
        className={`text-14 ${
          sumOfDistributions === 100 ? "text-green" : "text-red"
        } mb-1`}
      >
        Remaining percentages: {100 - sumOfDistributions}%
      </p>
      <div className="flex space-x-2">
        <BackBtn onClick={() => setPage(0)} />
        <NextBtn
          onClick={() => {
            setTriedToSubmit(true);
            const errorsLength = validate().length;
            if (errorsLength === 0) {
              setPage(2);
            }
          }}
          wallet={wallet}
          connectMetamask={connectMetamask}
          chain={chain}
          setNetworksModalOpen={setNetworksModalOpen}
          disabled={error}
        />
      </div>
    </>
  );
}
