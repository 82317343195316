import { Size } from "../../types";
import LoadingPart from "./components/LoadingPart";

export default function Loading({
    size = Size.lg,
    color = "white",
    className = "",
}) {
    const getDimensions = () => {
        switch (size) {
            case Size.sm: return "w-[18px] h-[18px]";
            case Size.md: return "w-[23px] h-[23px]";
            case Size.lg: return "w-[23px] h-[23px] sm:w-[33px] sm:h-[33px]";
        }
    }
    const dimensions = getDimensions();

    const colors = {
        white: "border-t-white",
        black: "border-t-black",
    }

    return (
        <div className={`inline-block relative ${dimensions} ${className}`}>
            <LoadingPart color={colors[color]} size={size} className="animate-[ldsRing_1.2s_cubic-bezier(0.5,0,0.5,1)_infinite]" />
            <LoadingPart color={colors[color]} size={size} className="animate-[ldsRing_1.2s_cubic-bezier(0.5,0,0.5,1)_-0.15s_infinite]" />
            <LoadingPart color={colors[color]} size={size} className="animate-[ldsRing_1.2s_cubic-bezier(0.5,0,0.5,1)_-0.3s_infinite]" />
            <LoadingPart color={colors[color]} size={size} className="animate-[ldsRing_1.2s_cubic-bezier(0.5,0,0.5,1)_-0.45s_infinite]" />
        </div>
    )
}