import { useEffect, useRef, useState } from "react";
import Svg from "./Svg";

export default function Dropdown({
    id,
    value,
    onChange,
    placeholder,
    options,
    minWidth,
    fullWidth,
    className = "",
    disabled,
    ...rest
}) {
    const [optionsVisible, setOptionsVisible] = useState(false);

    const refContainer = useRef();
    const refOptions = useRef();

    useEffect(() => {
        const closeOptions = (e) => {
            if (!refOptions?.current?.contains(e.target)
                && !refContainer?.current?.contains(e.target)) setOptionsVisible(false);
        };
        window.addEventListener("click", closeOptions);
        return () => window.removeEventListener("click", closeOptions);
    }, [refOptions, refContainer]);

    return (
        <div
            id={id}
            ref={refContainer}
            onClick={!disabled ? () => setOptionsVisible(!optionsVisible) : undefined}
            className={`h-[48px] relative px-4 py-[10px] ${fullWidth ? "w-full" : ""} ${minWidth} bg-white
            stroke-black text-16
            outline 
            ${optionsVisible
                    ? "rounded-t-[6px] outline-2 outline-black"
                    : `rounded-[6px] outline-1 outline-grey3 ${!disabled ? "hover:outline-grey2" : ""}`}
            transition-[outline-color, background-color] duration-100
            flex justify-between items-center
            ${disabled ? "opacity-40 cursor-not-allowed bg-grey3" : "cursor-pointer"}
            ${className}`}
            {...rest}
        >
            <span className="text-black mr-4">
                {options.find(option => option.value == value)?.label || placeholder}
            </span>
            <Svg name="caretDown" className={optionsVisible ? "rotate-180" : ""} />
            <div
                ref={refOptions}
                className={`absolute z-10 w-full max-h-[300px] overflow-y-auto left-0 top-[100%] 
                bg-white outline outline-2 outline-black rounded-b-[6px]
                ${optionsVisible ? "block" : "hidden"}`}
            >
                {options.map((option, i) => {
                    return (
                        <div
                            key={`${id}-${option.value}-${i}`}
                            className={`px-4 py-1 transition duration-100 ${value == option.value ? `bg-black text-white` : "hover:bg-gray-200"}`}
                            onClick={() => onChange(option.value)}
                        >
                            {option.label}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}