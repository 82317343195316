import { useEffect } from "react";
import { useState } from "react";
import useNFTPots from "../contracts/NFTPot/useNFTPots";
import usePots from "../contracts/pot/usePots";
import useContract from "./useContract";

export default function useWhitelist(contractAddress,
    isNFT,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshWhitelistedAddresses,
    watchTransaction,
    whitelistedAddresses,
) {
    const {
        whitelist,
    } = usePots([contractAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    const {
        whitelist: whitelistNFT,
    } = useNFTPots([contractAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    const [whitelistErrors, setWhitelistErrors] = useState([]);
    const [newWhitelistedAddress, setNewWhitelistedAddress] = useState("");
    const [triedToSubmitNewWhitelistAddress, setTriedToSubmitNewWhitelistAddress] = useState(false);
    const [loadingAddWhitelistedAddress, setLoadingAddWhitelistedAddress] = useState(false);

    const validateWhitelist = () => {
        const errors = [];
        if (!newWhitelistedAddress) errors.push({ id: "whitelist-new-address", message: "Please provide a valid address." });
        if (!!whitelistedAddresses.find(address => address.toLowerCase() === newWhitelistedAddress.toLowerCase()))
            errors.push({ id: "whitelist-new-address", message: "The whitelist already includes this address." });

        setWhitelistErrors(errors);
        return errors;
    }

    useEffect(() => {
        if (triedToSubmitNewWhitelistAddress) validateWhitelist();
        else setWhitelistErrors([]);
    }, [triedToSubmitNewWhitelistAddress, newWhitelistedAddress]);

    const { state: stateWhitelist, callMethod: onWhitelist } = useContract(
        watchTransaction,
        async (newWhitelistedAddress) => {
            setLoadingAddWhitelistedAddress(true);
            return isNFT
                ? await whitelistNFT(contractAddress, newWhitelistedAddress)
                : await whitelist(contractAddress, newWhitelistedAddress);
        },
        async () => {
            setLoadingAddWhitelistedAddress(false);
            setNewWhitelistedAddress("");
            setWhitelistErrors([]);
            setTriedToSubmitNewWhitelistAddress(false);
            await refreshWhitelistedAddresses(contractAddress);
        },
        () => {
            setLoadingAddWhitelistedAddress(false);
        }
    );

    return {
        whitelistErrors,
        setWhitelistErrors,
        newWhitelistedAddress,
        setNewWhitelistedAddress,
        setTriedToSubmitNewWhitelistAddress,
        loadingAddWhitelistedAddress,
        onWhitelist,
        validateWhitelist
    }
}