import networks from "../../networks";
import Modal from "./Modal";

export default function NetworksModal({
    open,
    setOpen,
    currentNetwork,
    switchToNetwork,
}) {
    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="Change network"
        >
            <div className="rounded-md">
                {networks.map((network, i) => {
                    const chainId = network.data.chainId;
                    const active = currentNetwork?.data?.chainId === chainId
                    return (
                        <div
                            key={network.data.chainName}
                            className={`cursor-pointer px-3 py-2.5
                            transition duration-100
                            ${active ? "bg-black text-white" : "bg-gray-200 hover:bg-gray-300"}
                            ${i === 0 ? "rounded-t-md" : ""} ${i == networks.length - 1 ? "rounded-b-md" : ""}
                            `}
                            onClick={() => {
                                switchToNetwork(chainId);
                                setOpen(false);
                            }}
                        >
                            <div className="flex space-x-1">
                                <div className="bg-white rounded-full p-1">{network.icon}</div>
                                <span>{network.displayName}</span>
                            </div>
                        </div>
                    );
                })}
            </div>
        </Modal>
    )
}