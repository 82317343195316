import Button from "../../../components/Button/Button";
import Svg from "../../../components/Svg";
import walletWhite from "../../../assets/icons/walletWhite.svg";
import { chainIds } from "../../../networks";

export default function NextBtn({
    onClick,
    wallet,
    connectMetamask,
    chain,
    setNetworksModalOpen,
    disabled,
}) {
    return (
        <>
            {!wallet
                ?
                <Button
                    bgColor='green-green-darker'
                    onClick={connectMetamask}>
                    <div className="flex">
                        <img src={walletWhite} alt="" width={24} height={24} className="mr-2" />
                        Connect wallet
                    </div>
                </Button>
                : chainIds.includes(chain)
                    ?
                    <Button
                        bgColor='green-green-darker'
                        onClick={onClick}
                        disabled={disabled}
                    >
                        <div className='flex space-x-3 items-center'>
                            <span>Next</span>
                            <Svg name={"arrowRight"} />
                        </div>
                    </Button>
                    :
                    <Button
                        bgColor='green-green-darker'
                        onClick={() => setNetworksModalOpen(true)}>
                        Switch chain
                    </Button>
            }
        </>
    )
}