import Button from "../../../components/Button/Button";
import walletWhite from "../../../assets/icons/walletWhite.svg";
import { chainIds } from "../../../networks";
import useContract from "../../../hooks/useContract";
import { useState } from "react";
import useERC20 from "../../../contracts/ERC20/useERC20";
import { BigNumber } from "ethers";
import Svg from "../../../components/Svg";
import usePotsInit from "../hooks/usePotsInit";
import useNFTPotsInit from "../hooks/useNFTPotsInit";
import useERC721 from "../../../contracts/ERC721/useERC721";
import InfoTooltip from "../components/InfoTooltip";
import { Position, Size } from "../../../types";
import useMediaQuery from "../../../hooks/useMediaQuery";

export default function Page3({
  prizeTokenType,
  connectMetamask,
  wallet,
  chain,
  pairAddress,
  getContract,
  getGasLimit,
  getGasPrice,
  watchTransaction,
  isPotTokenNative,
  selectedPotTokenAddress,
  potAmount,
  potAmountParsed,
  notEnoughPotToken,
  tokenList,
  NFTTokenList,
  selectedPotToken,
  setNetworksModalOpen,
  setPage,
  lastPageIndex,
  potTokenID,
  notOwnerOfToken,
  error,
}) {
  const isPrizeNFT = prizeTokenType === "1";

  const bp380px = useMediaQuery(380);

  const [loadingDeposit, setLoadingDeposit] = useState(false);
  const [loadingWhitelist, setLoadingWhitelist] = useState(false);
  const [whitelisted, setWhitelisted] = useState(false);

  const { depositToken, depositERC20Token, setWhiteListOnly } = usePotsInit(
    prizeTokenType,
    pairAddress,
    getContract,
    getGasLimit,
    getGasPrice,
    setWhitelisted
  );

  const { depositERC721Token, setWhiteListOnly: setNFTWhiteListOnly } =
    useNFTPotsInit(
      prizeTokenType,
      pairAddress,
      getContract,
      getGasLimit,
      getGasPrice,
      setWhitelisted
    );

  const { state: stateDeposit, callMethod: onDeposit } = useContract(
    watchTransaction,
    async (pairAddress, amount) => {
      setLoadingDeposit(true);

      if (isPotTokenNative) return await depositToken(pairAddress, amount);
      else return await depositERC20Token(pairAddress);
    },
    () => {
      setLoadingDeposit(false);
      setPage(lastPageIndex);
    },
    () => {
      setLoadingDeposit(false);
    }
  );

  const { state: stateDepositNFT, callMethod: onDepositNFT } = useContract(
    watchTransaction,
    async (pairAddress) => {
      setLoadingDeposit(true);
      return await depositERC721Token(pairAddress);
    },
    () => {
      setLoadingDeposit(false);
      setPage(lastPageIndex);
    },
    () => {
      setLoadingDeposit(false);
    }
  );

  const { state: stateSetWhitelist, callMethod: onSetWhitelist } = useContract(
    watchTransaction,
    async (pairAddress) => {
      setLoadingWhitelist(true);
      if (isPrizeNFT) return await setNFTWhiteListOnly(pairAddress);
      return await setWhiteListOnly(pairAddress);
    },
    () => {
      setLoadingWhitelist(false);
      setWhitelisted(true);
    },
    () => {
      setLoadingWhitelist(false);
    }
  );

  const {
    loading: loadingERC20,
    approved: approvedERC20,
    callMethod: approveERC20,
  } = useERC20(
    watchTransaction,
    wallet,
    getContract,
    [selectedPotTokenAddress],
    pairAddress
  );

  const {
    loading: loadingERC721,
    approved: approvedERC721,
    callMethod: approveERC721,
  } = useERC721(
    watchTransaction,
    wallet,
    getContract,
    [selectedPotTokenAddress],
    pairAddress,
    potTokenID
  );

  const ConnectWalletBtn = () => (
    <Button bgColor="green-green-darker" onClick={connectMetamask}>
      <div className="flex">
        <img src={walletWhite} alt="" width={24} height={24} className="mr-2" />
        Connect wallet
      </div>
    </Button>
  );

  const SwitchChainBtn = () => (
    <Button
      bgColor="green-green-darker"
      onClick={() => setNetworksModalOpen(true)}
    >
      Switch chain
    </Button>
  );

  return (
    <>
      <div className="pt-2 mb-1 flex space-x-2 items-center">
        <span className="text-28 font-bold">
          Do you want to whitelist your tournament?
        </span>
        <InfoTooltip
          bpSm={bp380px}
          position={Position.left}
          size={Size.lg}
          tooltip={
            "As the creator, you can choose to make your tournament only available to certain address. You do this by changing the nature of the tournament to whitelist only after you create it but before you deposit the prize. These are permanent, cannot be removed. However, you can blacklist an address if they display an unsportsmanlike conduct this will prevent that address from ever bidding again."
          }
        />
      </div>
      <p className="text-18 text-grey2 font-medium mb-4">
        Only addresses that you have added to the tournament's whitelist can
        bid. You can only remove an address by blacklisting it later. It's free.
      </p>
      <div className="mb-4">
        {!wallet ? (
          ConnectWalletBtn()
        ) : chainIds.includes(chain) ? (
          whitelisted ? (
            <div className="flex space-x-2 items-center">
              <div className="flex justify-center items-center rounded-full bg-green w-7 h-7">
                <Svg name="checkMd" className="stroke-white" />
              </div>
              <span className="text-18 font-medium">Whitelisted</span>
            </div>
          ) : (
            <Button
              bgColor="green-green-darker"
              loading={loadingWhitelist}
              onClick={async () => await onSetWhitelist(pairAddress)}
              disabled={error}
            >
              Make Village whitelist only
            </Button>
          )
        ) : (
          SwitchChainBtn()
        )}
      </div>
      <p className="text-28 font-bold mb-1">
        Deposit{" "}
        {isPrizeNFT
          ? NFTTokenList?.[selectedPotToken]?.label
          : `${potAmount} ${tokenList?.[selectedPotToken]?.label}`}{" "}
        to start your tournament
      </p>
      <p className="text-18 text-grey2 font-medium mb-4">
        Easy and simple steps to build your own village FOR FREE!
      </p>
      {!wallet ? (
        ConnectWalletBtn()
      ) : chainIds.includes(chain) ? (
        isPrizeNFT ? (
          <Button
            bgColor="green-green-darker"
            loading={loadingDeposit || loadingERC721}
            onClick={
              approvedERC721?.[selectedPotTokenAddress]
                ? async () => await onDepositNFT(pairAddress)
                : async () => await approveERC721(selectedPotTokenAddress)
            }
            disabled={
              (approvedERC721?.[selectedPotTokenAddress] && notOwnerOfToken) ||
              error
            }
          >
            {approvedERC721?.[selectedPotTokenAddress]
              ? notOwnerOfToken
                ? `Not owner of token`
                : `Deposit`
              : `Approve ${NFTTokenList?.[selectedPotToken]?.label || ""}`}
          </Button>
        ) : (
          <Button
            bgColor="green-green-darker"
            loading={loadingDeposit || loadingERC20}
            onClick={
              approvedERC20?.[selectedPotTokenAddress] || isPotTokenNative
                ? async () =>
                    await onDeposit(
                      pairAddress,
                      BigNumber.from(potAmountParsed)
                    )
                : async () => await approveERC20(selectedPotTokenAddress)
            }
            disabled={
              ((approvedERC20?.[selectedPotTokenAddress] || isPotTokenNative) &&
                notEnoughPotToken) ||
              error
            }
          >
            {approvedERC20?.[selectedPotTokenAddress] || isPotTokenNative
              ? notEnoughPotToken
                ? `Not enough ${tokenList?.[selectedPotToken]?.label || ""}`
                : `Deposit`
              : `Approve ${tokenList?.[selectedPotToken]?.label || ""}`}
          </Button>
        )
      ) : (
        SwitchChainBtn()
      )}
    </>
  );
}
