import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import FetchQueue from "../services/FetchQueue";

const useFetchQueue = (fetcher, id, ...params) => {

    const [fetch, setFetch] = useState(false);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const requestId = useRef();

    const cancel = () => {
        FetchQueue.removeRequest(requestId.current);
    }

    useEffect(() => {
        requestId.current = id;
        const fetchData = async () => {
            try {
                const data = await FetchQueue.pushRequest(
                    async () => await fetcher(...params),
                    requestId.current
                );
                setData(data)
            } catch (e) {
                setError(e);
            }
        };
        if (fetch && fetcher && params.filter(param => !param).length === 0) {
            fetchData();
        }
        return () => {
            cancel();
        };
    }, [fetcher, fetch]);

    return {
        setFetch,
        data,
        loading: !data && !error,
        error,
        cancel,
    };

};

export default useFetchQueue;