import ABI from "./comvillageabi";
import { useState, useEffect } from "react";
import Web3 from "web3";
import parseBigNumber from "../../utils/parseBigNumber";

export default function useTournaments(wallet, currentNetwork) {
  const [contracts, setContracts] = useState({});
  const [canCallTournaments, setCanCallTournaments] = useState(false);

  const loadWeb3 = async () => {
    if (window.ethereum) {
      window.web3 = new Web3(window.ethereum);
      window.ethereum.enable();
    }
  };

  const loadContract = async (address) => {
    return await new window.web3.eth.Contract(ABI, address);
  };

  useEffect(() => {
    const init = async () => {
      setCanCallTournaments(false);
      await loadWeb3();

      const contracts = {};
      for (var i = 0; i < currentNetwork.tournamentsAddresses.length; i++) {
        const address = currentNetwork.tournamentsAddresses[i];
        contracts[address] = await loadContract(address);
      }
      setContracts(contracts);
    };
    if (currentNetwork) init();
  }, [currentNetwork]);

  const isCurrentNetwork = () => {
    var result = true;
    const addresses = Object.keys(contracts);
    for (var i = 0; i < addresses.length; i++) {
      if (addresses[i] !== currentNetwork.tournamentsAddresses[i])
        result = false;
    }
    return result;
  };

  useEffect(() => {
    if (
      Object.keys(contracts).length > 0 &&
      !!(currentNetwork && isCurrentNetwork() && wallet && !canCallTournaments)
    )
      setCanCallTournaments(true);
  }, [contracts, currentNetwork, wallet, canCallTournaments]);

  useEffect(() => {
    if (!wallet) setCanCallTournaments(false);
  }, [wallet]);

  const getPots = async () => {
    const pots = [];
    const values = Object.values(contracts);
    for (var i = 0; i < values.length; i++) {
      try {
        pots.push(...(await values[i].methods.getPots().call()));
      } catch {}
    }
    return pots;
  };
  const getTopOwnerFee = async (address) => 5;
  const getOwner = async (address) =>
    await contracts[address].methods.getOwner().call();

  const getTokenList = async () => [
    "0xfff9976782d46cc05630d1f6ebab18b2324d6b14",
    "0xf9e6ad49f6ba9a4fcb3989d1ddffd103382028b4",
  ];

  const getPriorityPrice = async (address) =>
    parseBigNumber(await contracts[address].methods.priorityPrice().call());

  const getNonPriorityPrice = async (address) => {
    const s = await contracts[address].methods.nonPriorityPrice().call();
    console.log(s);

    return parseBigNumber(
      await contracts[address].methods.nonPriorityPrice().call()
    ).toFixed(18);
    // return s;
  };

  const createPot = async (
    contractAddress,
    potAddress,
    bidAddress,
    bidAmount,
    toAddress,
    toPercent,
    expirationTime,
    priorityPool,
    fees,
    depositValue
  ) => {
    return await contracts[contractAddress].methods
      .createPot(
        potAddress,
        bidAddress,
        bidAmount,
        toAddress,
        toPercent,
        expirationTime,
        priorityPool,
        fees
      )
      .send({
        from: wallet,
        value: depositValue,
      });
  };

  return {
    canCallTournaments,
    getPots,
    getTopOwnerFee,
    getOwner,
    getTokenList,
    getPriorityPrice,
    getNonPriorityPrice,
    createPot,
  };
}
