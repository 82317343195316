import { useEffect } from "react";
import { useState } from "react";
import Button from "../../components/Button/Button";
import Svg from "../../components/Svg";
import Tooltip from "../../components/Tooltip/Tooltip";
import { BtnType, Position, Size } from "../../types";
import getTimeUnitFromSeconds from "../../utils/getTimeUnitFromSeconds";
import Modal from "../../components/Modals/Modal";
import Loading from "../../components/Loading/Loading";
import Input from "../../components/Input";
import InfoTooltip from "../Tournament/components/InfoTooltip";
import useMediaQuery from "../../hooks/useMediaQuery";
import useWhitelist from "../../hooks/useWhitelist";
import useBlacklist from "../../hooks/useBlacklist";
import useTournamentDetailsData from "../../hooks/useTournamentDetailsData";
import CountdownTextLike from "../../components/CountdownTextLike";

export default function TournamentDetails({
  contractAddress,
  isNFT,
  getContract,
  getGasLimit,
  getGasPrice,
  potToken,
  decimalsPotToken,
  symbolPotToken,
  tokenURI,
  isNativePotToken,
  isNativeBidToken,
  bidToken,
  bidAmountFormatted,
  symbolBidToken,
  decimalsBidToken,
  timeUntilExpiry,
  refreshTournamentData,
  deposited,
  isWalletOwnerOfTournament,
  whitelistOnly,
  whitelistedAddresses,
  blacklistedAddresses,
  isActive,
  refreshBlacklistedAddresses,
  refreshWhitelistedAddresses,
  watchTransaction,
  currentNetwork,
  isPriority,
  bidAmountDivided,
  potAmountDivided,
  balanceOfBidTokenInContractDivided,
  balanceOfContractDivided,
}) {
  const bp640px = useMediaQuery(640);

  const [modalOpen, setModalOpen] = useState(false);

  const {
    canCallPots,
    canCallNFTPots,
    loading,
    initDone,
    bidOption,
    bidVariable1,
    bidVariable2,
    toPotFee,
    expExpiryOption,
    expirationTime,
    expChangeBy,
    expMinimumTime,
    roundDuration,
    roundReward,
    updateData,
    updateNFTData,
    getBidPriceDistributionsSorted,
  } = useTournamentDetailsData(
    contractAddress,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshTournamentData,
    decimalsBidToken,
    decimalsPotToken
  );

  useEffect(() => {
    if (isNFT && canCallNFTPots && modalOpen && !initDone) updateNFTData();
    else if (canCallPots && modalOpen && !initDone) updateData();
  }, [canCallPots, canCallNFTPots, modalOpen, initDone]);

  const {
    whitelistErrors,
    setWhitelistErrors,
    newWhitelistedAddress,
    setNewWhitelistedAddress,
    setTriedToSubmitNewWhitelistAddress,
    loadingAddWhitelistedAddress,
    onWhitelist,
    validateWhitelist,
  } = useWhitelist(
    contractAddress,
    isNFT,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshWhitelistedAddresses,
    watchTransaction,
    whitelistedAddresses
  );

  const {
    blacklistErrors,
    setBlacklistErrors,
    newBlacklistedAddress,
    setNewBlacklistedAddress,
    setTriedToSubmitNewBlacklistAddress,
    loadingAddBlacklistedAddress,
    onBlacklist,
    validateBlacklist,
  } = useBlacklist(
    contractAddress,
    isNFT,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshBlacklistedAddresses,
    watchTransaction,
    blacklistedAddresses
  );

  return (
    <div>
      <Tooltip tooltip={"Details"} position={Position.top}>
        <Button
          type={BtnType.linklike}
          textColor="grey2-grey2-darker"
          padding="p-1"
          onClick={() => setModalOpen(true)}
        >
          <Svg name={"magnifier"} />
        </Button>
      </Tooltip>
      <Modal
        open={modalOpen}
        setOpen={setModalOpen}
        onHide={() => {
          setNewBlacklistedAddress("");
          setBlacklistErrors([]);
          setTriedToSubmitNewBlacklistAddress(false);
          setNewWhitelistedAddress("");
          setWhitelistErrors([]);
          setTriedToSubmitNewWhitelistAddress(false);
        }}
        size={Size.md}
        titleFontSize=""
        title={
          <div className="flex items-center">
            <Tooltip
              tooltip={"Refresh"}
              position={Position.bottom}
              hide={loading}
            >
              <Button
                type={BtnType.linklike}
                textColor="grey2-grey2-darker"
                padding="p-1"
                onClick={async () =>
                  isNFT ? await updateNFTData(true) : await updateData(true)
                }
                disabled={loading}
              >
                <Svg name={"refresh"} />
              </Button>
            </Tooltip>
            <span className="text-20 sm:text-24 ml-2">
              <span className="break-all mr-2">
                {contractAddress?.slice(0, 6)}...{contractAddress?.slice(-4)}
              </span>
              details
            </span>
          </div>
        }
      >
        {loading ? (
          <div className="flex justify-center">
            <Loading color="black" />
          </div>
        ) : (
          <div className="text-black">
            <div className="mb-3">
              <p className="text-grey2 mb-0">
                {isNFT ? "NFT" : "Treasure token address"} address
              </p>
              <p className={`text-20 font-medium mb-0 break-all`}>
                {isNativePotToken
                  ? `${currentNetwork?.data.nativeCurrency.symbol} (native)`
                  : potToken}
              </p>
            </div>
            <div className="mb-3">
              {isNFT ? (
                <>
                  <p className="text-grey2 mb-0">NFT sprite</p>
                  <div className="max-w-[450px]">
                    <img src={tokenURI} alt="" />
                  </div>
                </>
              ) : (
                <>
                  <p className="text-grey2 mb-0">
                    Treasure token address amount in tournament
                  </p>
                  <p className={`text-20 font-medium mb-0 break-all`}>
                    {deposited ? potAmountDivided : 0}
                  </p>
                </>
              )}
            </div>
            <div className="mb-3">
              <p className="text-grey2 mb-0">Raid token address</p>
              <p className={`text-20 font-medium mb-0 break-all`}>
                {isNativeBidToken
                  ? `${currentNetwork?.data.nativeCurrency.symbol} (native)`
                  : bidToken}
              </p>
            </div>
            {potToken != bidToken && toPotFee > 0 && !isNFT && (
              <div className="mb-3">
                <p className="text-grey2 mb-0">
                  Raid token amount in tournament
                </p>
                <p className={`text-20 font-medium mb-0 break-all`}>
                  {deposited ? (
                    (isNativePotToken && isNativeBidToken) ||
                    (!isNativePotToken &&
                      !isNativeBidToken &&
                      potToken == bidToken) ? (
                      potAmountDivided
                    ) : (isNativePotToken ||
                        (!isNativePotToken && potToken != bidToken)) &&
                      !isNativeBidToken ? (
                      balanceOfBidTokenInContractDivided
                    ) : !isNativePotToken && isNativeBidToken ? (
                      balanceOfContractDivided
                    ) : (
                      <></>
                    )
                  ) : (
                    0
                  )}
                </p>
              </div>
            )}
            <div className="mb-3">
              <p className="text-grey2 mb-0">Bid amount</p>
              <p className={`text-20 font-medium mb-0 break-all`}>
                {bidAmountDivided}
              </p>
            </div>
            <div className="mb-3">
              <p className="text-grey2 mb-0">Raid fee rule</p>
              <p className={`text-20 font-medium mb-0`}>
                {bidOption === 0
                  ? `${bidAmountFormatted} ${symbolBidToken}`
                  : bidOption === 1 && !isNFT
                  ? `${bidVariable1}% of Pot amount`
                  : `${bidAmountFormatted} ${symbolBidToken} growing by ${bidVariable2}% after every bid`}
              </p>
            </div>
            <div className="mb-3">
              <p className="text-grey2 mb-0">Raid fee distribution</p>
              <div className="max-h-40 overflow-y-auto pr-1">
                {getBidPriceDistributionsSorted().map((distribution) => {
                  return (
                    <p
                      key={distribution.toAddress}
                      className="grid grid-cols-12 text-20 mb-1"
                    >
                      <span className="col-span-3 sm:col-span-1 font-medium">
                        {distribution.percentage}%
                      </span>
                      <span className="col-span-9 sm:col-span-11 text-grey2 break-all">
                        {distribution.toAddress}
                      </span>
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="mb-3">
              <p className="text-grey2 mb-0">Time until expiry</p>
              <CountdownTextLike
                timeUntilExpiry={timeUntilExpiry}
                className="mb-3"
              />
            </div>
            <div className="mb-3">
              <p className="text-grey2 mb-0">Time until expiry rule</p>
              <p className={`text-20 text-grey2 font-medium mb-0`}>
                {expExpiryOption === 0 ||
                (expExpiryOption === 1 &&
                  expirationTime - expChangeBy <= expMinimumTime) ? (
                  <>
                    Resets to{" "}
                    <span className="text-black font-semibold">
                      {getTimeUnitFromSeconds(expirationTime)}
                    </span>{" "}
                    after each bid
                  </>
                ) : expExpiryOption === 1 ? (
                  <>
                    Resets to{" "}
                    <span className="text-black font-semibold">
                      {getTimeUnitFromSeconds(expirationTime - expChangeBy)}
                    </span>{" "}
                    but it decreases by{" "}
                    <span className="text-black font-semibold">
                      {getTimeUnitFromSeconds(expChangeBy)}
                    </span>{" "}
                    after every bid, but reset time can never be smaller than{" "}
                    <span className="text-black font-semibold">
                      {getTimeUnitFromSeconds(expMinimumTime)}
                    </span>
                  </>
                ) : (
                  <>
                    Increases by{" "}
                    <span className="text-black font-semibold">
                      {getTimeUnitFromSeconds(expChangeBy)}
                    </span>{" "}
                    after every bid
                  </>
                )}
              </p>
            </div>
            {!!(roundDuration && roundReward) && (
              <>
                <div className="mb-3">
                  <p className="text-grey2 mb-0">Round duration</p>
                  <p className={`text-20 font-medium mb-0`}>
                    {getTimeUnitFromSeconds(roundDuration)}
                  </p>
                </div>
                <div className="mb-3">
                  <p className="text-grey2 mb-0">Round reward</p>
                  <p className={`text-20 font-medium mb-0`}>
                    {roundReward} {symbolPotToken}
                  </p>
                </div>
              </>
            )}
            <div className="mb-3">
              <p className="text-grey2 mb-0">Priority tournament</p>
              <p className={`text-20 font-medium mb-0`}>
                {isPriority ? "Yes" : "No"}
              </p>
            </div>
            {((isWalletOwnerOfTournament && whitelistOnly) ||
              whitelistedAddresses.length > 0) && (
              <div className="mb-3">
                <div className="text-grey2 mb-0 flex space-x-2 items-center">
                  <span>Whitelisted addresses</span>
                  <InfoTooltip
                    position={bp640px ? Position.top : Position.left}
                    optionalRefresh={modalOpen}
                    tooltip={
                      "As the creator, you can choose to make your tournament only available to certain address. You do this by changing the nature of the tournament to whitelist only after you create it but before you deposit the prize. These are permanent, cannot be removed. However, you can blacklist an address if they display an unsportsmanlike conduct this will prevent that address from ever bidding again."
                    }
                  />
                </div>
                {whitelistedAddresses.length > 0 ? (
                  <ul className="max-h-40 overflow-y-auto mb-3 pr-1 list-disc list-inside">
                    {whitelistedAddresses.map((address) => {
                      return (
                        <li
                          key={address}
                          className="text-20 font-medium mb-0 break-all"
                        >
                          {address}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="text-20 font-medium mb-3">
                    There are no whitelisted addresses
                  </p>
                )}
                {isActive && isWalletOwnerOfTournament && (
                  <>
                    <Input
                      id={`${contractAddress}-details-whitelist-new-address`}
                      label={"Add new address to whitelist"}
                      className="mb-2"
                      fullWidth
                      value={newWhitelistedAddress}
                      onChange={(e) => setNewWhitelistedAddress(e.target.value)}
                      error={
                        whitelistErrors.find(
                          (e) => e.id === `whitelist-new-address`
                        )?.message
                      }
                    />
                    <div className="flex justify-end">
                      <Button
                        bgColor="green-green-darker"
                        className="w-full sm:w-auto"
                        loading={loadingAddWhitelistedAddress}
                        onClick={async () => {
                          setTriedToSubmitNewWhitelistAddress(true);
                          const errorsLength = validateWhitelist().length;
                          if (errorsLength === 0)
                            await onWhitelist(newWhitelistedAddress);
                        }}
                      >
                        <div className="flex items-center">
                          <Svg name={"plus"} />
                          Add
                        </div>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
            {(isWalletOwnerOfTournament || blacklistedAddresses.length > 0) && (
              <div className="mb-3">
                <div className="text-grey2 mb-0 flex space-x-2 items-center">
                  <span>Blacklisted addresses</span>
                  <InfoTooltip
                    position={bp640px ? Position.top : Position.left}
                    optionalRefresh={modalOpen}
                    tooltip={
                      "As the creator, you can put addresses on the blacklist of your tournament. These are permanent, cannot be removed. This is to fight against bots to make the game fair to all champions. Note that blacklisted addresses are only restricted from bidding, not from claiming if they would win."
                    }
                  />
                </div>
                {blacklistedAddresses.length > 0 ? (
                  <ul className="max-h-40 overflow-auto mb-3 pr-1 list-disc list-inside">
                    {blacklistedAddresses.map((address) => {
                      return (
                        <li
                          key={address}
                          className="text-20 font-medium mb-0 break-all"
                        >
                          {address}
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <p className="text-20 font-medium mb-3">
                    There are no blacklisted addresses
                  </p>
                )}
                {isActive && isWalletOwnerOfTournament && (
                  <>
                    <Input
                      id={`${contractAddress}-details-blacklist-new-address`}
                      label={"Add new address to blacklist"}
                      className="mb-2"
                      fullWidth
                      value={newBlacklistedAddress}
                      onChange={(e) => setNewBlacklistedAddress(e.target.value)}
                      error={
                        blacklistErrors.find(
                          (e) => e.id === `blacklist-new-address`
                        )?.message
                      }
                    />
                    <div className="flex justify-end">
                      <Button
                        bgColor="green-green-darker"
                        className="w-full sm:w-auto"
                        loading={loadingAddBlacklistedAddress}
                        onClick={async () => {
                          setTriedToSubmitNewBlacklistAddress(true);
                          const errorsLength = validateBlacklist().length;
                          if (errorsLength === 0)
                            await onBlacklist(newBlacklistedAddress);
                        }}
                      >
                        <div className="flex items-center">
                          <Svg name={"plus"} />
                          Add
                        </div>
                      </Button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
}
