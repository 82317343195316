import { useState } from "react";

export default function RadioButton({
    id,
    label,
    checked,
    onChange,
    className = "",
    labelClassName = "",
    disabled,
}) {
    const [hovered, setHovered] = useState(false);
    const checkedOrHovered = checked || hovered;

    return (
        <label
            htmlFor={id}
            className={`flex items-center space-x-2 select-none ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"} ${className}`}
            onMouseEnter={!disabled ? () => setHovered(true) : undefined}
            onMouseLeave={!disabled ? () => setHovered(false) : undefined}
        >
            <div className="relative">
                <input
                    id={id}
                    type="radio"
                    checked={checked}
                    onChange={!disabled ? () => onChange(!checked) : () => {}}
                    className={`appearance-none
                ${!disabled ? "cursor-pointer" : ""}
                w-[15px] h-[15px]
                transition duration-300
                flex justify-center items-center
                border rounded-full
                ${checkedOrHovered ? "border-black" : "border-grey2"}`}
                />
                <span className={`absolute top-[3.125px] left-[3.125px] w-[8.75px] h-[8.75px] rounded-full transition duration-300
                ${checkedOrHovered ? "bg-black" : "bg-transparent"}`} />
            </div>
            <span className={`${checkedOrHovered ? "transition duration-300 text-black" : "text-grey2"} ${labelClassName}`}>{label}</span>
        </label>
    )
}