import { useEffect } from "react";
import useERC721 from "../../../contracts/ERC721/useERC721";

export default function useNFTTokenListInit(
    watchTransaction,
    wallet,
    getContract,
    NFTTokenAddresses,
    setNFTTokenList,
    prizeTokenType,
    potTokenID,
) {
    const {
        getSymbol,
        getOwnerOf,
        canCallERC721,
        setCanCallERC721,
    } = useERC721(watchTransaction, wallet, getContract, NFTTokenAddresses, undefined, potTokenID);

    useEffect(() => {
        const updateTokenList = async () => {
            const newTokenList = [];
            for (let i = 0; i < NFTTokenAddresses.length; i++) {
                const address = NFTTokenAddresses[i];
                const newToken = {
                    value: i,
                    address: address
                };
                if (!newToken.label) {
                    try {
                        newToken.label = await getSymbol(address);
                    }
                    catch { }
                }
                try {
                    newToken.owner = (await getOwnerOf(address))?.toLowerCase()
                }
                catch { }
                newTokenList.push(newToken);
            }
            setNFTTokenList(newTokenList);
        }

        if (canCallERC721 && wallet && prizeTokenType === "1") {
            updateTokenList();
            setCanCallERC721(false);
        }
    }, [canCallERC721, wallet, prizeTokenType, potTokenID]);
}