import { useCookies } from "react-cookie";
import { toast } from "react-hot-toast";

export default function usePinnedTournaments(
    isTournamentPinned,
    tournament,
    pinnedTournamentsStorageName,
) {
    const [cookies] = useCookies();
    const isPinned = isTournamentPinned(tournament);
    const contractAddress = tournament.contractAddress;

    const getPinnedTournaments = () => {
        const inLocalStorageUnparsed = localStorage.getItem(pinnedTournamentsStorageName);
        const inLocalStorage = inLocalStorageUnparsed ? JSON.parse(inLocalStorageUnparsed) : undefined;
        const inCookie = cookies[pinnedTournamentsStorageName];
        const result = inLocalStorage || (Array.isArray(inCookie) ? inCookie : undefined) || [];
        return result;
    }

    const pinTournament = () => {
        const pinnedTournaments = getPinnedTournaments();
        pinnedTournaments.push(contractAddress);
        localStorage.setItem(pinnedTournamentsStorageName, JSON.stringify(pinnedTournaments));
        toast.success("Tournament pinned!");
    }

    const unpinTournament = () => {
        const pinnedTournaments = getPinnedTournaments();
        const newArray = pinnedTournaments.filter(address => address !== contractAddress);
        localStorage.setItem(pinnedTournamentsStorageName, JSON.stringify(newArray));
        toast.success("Tournament unpinned!");
    }

    return {
        getPinnedTournaments,
        pinTournament,
        unpinTournament,
        isPinned,
    }
}