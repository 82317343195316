import { useEffect } from "react";
import { useState } from "react";

export default function useBalance(
    canCall, wallet, currentNetwork, getBalance,
) {
    const [balance, setBalance] = useState(0);
    const [balancePollInterval, setBalancePollInterval] = useState();

    useEffect(() => {
        const updateBalance = async () => {
            setBalance(await getBalance());
        }
        if(balancePollInterval) clearInterval(balancePollInterval);
        if (canCall) updateBalance();
        const interval = setInterval(async () => {
            if (wallet) await updateBalance();
        }, 2500);
        setBalancePollInterval(interval);

        return () => clearInterval(interval);
    }, [canCall, wallet, currentNetwork]);

    return balance;
}