const FetchStatus = {
    IDLE: "IDLE",
    FETCHING: "FETCHING",
    FETCHED: "FETCHED",
    FETCH_ERROR: "FETCH_ERROR",
}

const TimeUnit = {
    Seconds: "Seconds",
    Minutes: "Minutes",
    Hours: "Hours",
    Days: "Days",
}

const Position = {
    top: "top",
    right: "right",
    bottom: "bottom",
    left: "left",
}

const BtnType = {
    filled: "filled",
    outlined: "outlined",
    linklike: "linklike",
}

const Size = {
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
}

const TournamentType = {
    active: "active",
    expired: "expired",
    creator: "creator",
    topOwner: "topOwner",
    closed: "closed",
}

export {
    FetchStatus,
    TimeUnit,
    Position,
    BtnType,
    Size,
    TournamentType,
}