export default function Input({
    id,
    label,
    labelColor = "",
    labelSize = "text-14",
    type = "text",
    placeholder,
    value,
    onChange,
    padding = "pr-3 pl-4",
    margin = "mt-0.5",
    borderRadius = "rounded-[6px]",
    fontSize = "text-16",
    outline = "outline-grey3",
    fullWidth,
    className = "",
    disabled,
    startAdornment,
    endAdornment,
    onFocus,
    onBlur,
    error,
    noValidation,
    readOnly,
    innerRef,
    bgColor,
    textColor = "",
    fontWeight = "",
    fontFamily = "",
    height = "h-[48px]",
    maxLength,
    labelClassName = "",
    ...rest
}) {
    return (
        <div className={className}>
            {label &&
                <label className={`${labelSize} ${error ? "text-red" : labelColor} ${labelClassName}`} htmlFor={id}>
                    {label}
                </label>
            }
            <div className={`relative ${fullWidth ? "w-full" : ""} h-full sm:flex`} {...rest}>
                {startAdornment && startAdornment}
                <input
                    id={id}
                    ref={innerRef}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className={`${padding} ${margin}
                        ${fullWidth ? "w-full" : ""} ${height}
                        outline outline-1 ${borderRadius}
                        transition-[outline-color, background-color] duration-100
                        ${fontSize} ${fontWeight} ${fontFamily} placeholder:text-grey3
                        ${disabled ? `cursor-not-allowed bg-grey3 bg-opacity-40 ${outline}`
                            : `${readOnly
                                ? `${bgColor || "bg-white"} ${!textColor ? "" : textColor}`
                                : bgColor || "bg-white"} ${error
                                    ? `text-red outline-red-light hover:outline-red-dark focus:outline-red focus:outline-2`
                                    : `${!textColor ? "text-black" : textColor} ${outline} hover:outline-grey2 focus:outline-black focus:outline-2`}`}`}
                    disabled={disabled}
                    readOnly={readOnly}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    maxLength={maxLength}
                />
                {endAdornment && endAdornment}
            </div>
            {!noValidation &&
                (error
                    ? <p className="text-12 text-red mt-0.5">{error}</p>
                    : <p className="text-12 mt-0.5"><br /></p>)}
        </div>
    )
}