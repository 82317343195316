import { useEffect } from "react";
import { toast } from "react-hot-toast";
import useTournaments from "../../../contracts/tournaments/useTournaments";

export default function useTournamentsInit(
    lastContractAddress,
    wallet,
    currentNetwork,
    prizeTokenType,
    setTopOwnerFee,
    setTokenAddresses,
    setPriorityPrice,
    setNonPriorityPrice,
    setSelectedPriorityPoolOption,
    setLoadingTokenList,
    setTournamentsInitDone,
    setError,
    updateTimestamp,
) {
    const {
        canCallTournaments,
        getTopOwnerFee,
        getTokenList,
        getPriorityPrice,
        getNonPriorityPrice,
        createPot,
    } = useTournaments(wallet, currentNetwork);

    useEffect(() => {
        const init = async () => {
            setLoadingTokenList(true);
            try {
                setTopOwnerFee(await getTopOwnerFee(lastContractAddress));
                const tokenAddresses = await getTokenList(lastContractAddress);
                setTokenAddresses(tokenAddresses);
                const priorityPrice = await getPriorityPrice(lastContractAddress);
                setPriorityPrice(priorityPrice !== undefined ? priorityPrice : 2);
                const nonPriorityPrice = await getNonPriorityPrice(lastContractAddress);
                setNonPriorityPrice(nonPriorityPrice !== undefined ? nonPriorityPrice : 0.01);
                setSelectedPriorityPoolOption(priorityPrice);
            }
            catch {
                setTopOwnerFee(0);
                setTokenAddresses([]);
                setPriorityPrice();
                setNonPriorityPrice();
                setSelectedPriorityPoolOption();
                setLoadingTokenList(false);

                toast.error("Sorry, an error has occured");
                setError(true);
            }
            finally {
                setTournamentsInitDone(true);
            }
        }
        if (canCallTournaments && wallet && prizeTokenType === "0" && lastContractAddress) init();
    }, [canCallTournaments, wallet, prizeTokenType, updateTimestamp]);

    return {
        createPot,
    }
}