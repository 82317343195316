const strokeColors = {
    "black": "stroke-black",
    "grey2": "stroke-grey2",
    "grey3": "stroke-grey3",
}

const strokeColorsHover = {
    "black": "hover:stroke-black",
    "grey2": "hover:stroke-green",
    "grey3": "hover:stroke-grey3-light",
}

const strokeColorsFocus = {
    "black": "focus:stroke-black",
    "grey2": "focus:stroke-green-dark",
    "grey3": "focus:stroke-white",
}

export { strokeColors, strokeColorsHover, strokeColorsFocus };