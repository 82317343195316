import { useEffect, useState } from "react";
import compareArrays from "../../utils/compareArrays";
import ABI from "./NFTPotAbi";

export default function useNFTPots(
  addresses,
  getContract,
  getGasLimit,
  getGasPrice
) {
  const [addressesLocal, setAddressesLocal] = useState([]);
  const getContracts = () => {
    const contracts = {};
    for (var i = 0; i < addressesLocal.length; i++) {
      contracts[addressesLocal[i]] = getContract(addressesLocal[i], ABI);
    }
    return contracts;
  };
  const [contracts, setContracts] = useState({});
  const [canCallNFTPots, setCanCallNFTPots] = useState(false);

  useEffect(() => {
    const filteredAddresses = addresses.filter((address) => address) || [];
    if (
      filteredAddresses.filter((address) => address)?.length > 0 &&
      !compareArrays(filteredAddresses, addressesLocal)
    )
      setAddressesLocal(filteredAddresses);
    if (
      filteredAddresses.length === 0 &&
      !compareArrays(filteredAddresses, addressesLocal)
    )
      setAddressesLocal([]);
  }, [addresses]);

  useEffect(() => {
    setContracts(getContracts());
  }, [addressesLocal]);

  useEffect(() => {
    if (Object.values(contracts).length > 0) setCanCallNFTPots(true);
  }, [contracts]);

  const getLastBidWinner = async (address) =>
    await contracts[address].lastBidWinner();
  const getBidAmount = async (address) =>
    await contracts[address].getBidPrice();
  const getIsClaim = async (address) => await contracts[address].isClaimed();
  const getTimeUntilExpiry = async (address) =>
    (await contracts[address].timeOfExpiry())?.toNumber();
  const getCreatedDate = async (address) =>
    (await contracts[address].createdDate())?.toNumber();
  const getClaimedDate = async (address) =>
    (await contracts[address].claimedDate())?.toNumber();
  const getIsBidNative = async (address) =>
    await contracts[address].isBidNative();
  const getPotToken = async (address) =>
    await contracts[address].potTokenAddress();
  const getBidToken = async (address) =>
    await contracts[address].bidTokenAddress();
  const getPotTokenID = async (address) =>
    (await contracts[address].potTokenID())?.toNumber();
  const getOwnerOfTournament = async (address) =>
    await contracts[address].ownerOfTournament();
  const getBlacklistedAddresses = async (address) =>
    await contracts[address].getBlacklistAddresses();
  const getCreatorClaimAllowTime = async (address) =>
    (await contracts[address].creatorClaimAllowTime())?.toNumber();
  const getWinnerClaimAllowTime = async (address) =>
    (await contracts[address].winnerClaimAllowTime())?.toNumber();
  const getDeposited = async (address) => await contracts[address].deposited();
  const getWhitelistAddresses = async (address) =>
    await contracts[address].getWhitelistAddresses();
  const getWhitelistOnly = async (address) =>
    await contracts[address].whitelistOnly();
  const getBidOption = async (address) =>
    (await contracts[address].bidOption())?.toNumber();
  const getBidVariable1 = async (address) =>
    await contracts[address].bidVariable1();
  const getBidVariable2 = async (address) =>
    await contracts[address].bidVariable2();
  const getTopOwner = async (address) => await contracts[address].topOwner();
  const getOwnerFee = async (address) =>
    (await contracts[address].ownerFee())?.toNumber();
  const getToPreviousBidderFee = async (address) =>
    (await contracts[address].toPreviousBidderFee())?.toNumber();
  const getToTournamentCreator = async (address) =>
    await contracts[address].toTournamentCreator();
  const getToTournamentCreatorFee = async (address) =>
    (await contracts[address].toTournamentCreatorFee())?.toNumber();
  const getToBurnFee = async (address) =>
    (await contracts[address].toBurnFee())?.toNumber();
  const getLengthOfBidDistribution = async (address) =>
    (await contracts[address].lengthOfBidDistribution())?.toNumber();
  //TODO separate getter in contract
  const getBidInfo = async (address) => {
    const result = [];
    const length = await getLengthOfBidDistribution(address);
    for (var i = 0; i < length; i++) {
      const bidInfo = await contracts[address].bidInfo(i);
      result.push({
        toAddress: bidInfo?.toAddress,
        percentage: bidInfo?.percentage?.toNumber(),
      });
    }
    return result;
  };
  const getExpirationTime = async (address) =>
    (await contracts[address].expirationTime())?.toNumber();
  const getExpExpiryOption = async (address) =>
    (await contracts[address].expExpiryOption())?.toNumber();
  const getExpChangeBy = async (address) =>
    (await contracts[address].expChangeBy())?.toNumber();
  const getExpMinimumTime = async (address) =>
    (await contracts[address].expMinimumTime())?.toNumber();
  const getIsPriority = async (address) =>
    await contracts[address].isPriority();

  const bid = async (address, value) => {
    var gasEstimate;
    var estimateError;
    await contracts[address].estimateGas.bid({ value: value }).then(
      (result) => (gasEstimate = result),
      (error) => (estimateError = error)
    );
    if (estimateError) return estimateError;

    return await contracts[address].bid({
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
      value: value,
    });
  };

  const bidERC20 = async (address) => {
    var gasEstimate;
    var estimateError;
    await contracts[address].estimateGas.bidERC20().then(
      (result) => (gasEstimate = result),
      (error) => (estimateError = error)
    );
    if (estimateError) return estimateError;

    return await contracts[address].bidERC20({
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
    });
  };

  const claim = async (address) => {
    var gasEstimate;
    var estimateError;
    await contracts[address].estimateGas.claim().then(
      (result) => (gasEstimate = result),
      (error) => (estimateError = error)
    );
    if (estimateError) return estimateError;

    return await contracts[address].claim({
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
    });
  };

  const depositERC721Token = async (address) => {
    var gasEstimate;
    var estimateError;
    await contracts[address].estimateGas.depositERC721Token().then(
      (result) => (gasEstimate = result),
      (error) => (estimateError = error)
    );
    if (estimateError) return estimateError;

    return await contracts[address].depositERC721Token({
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
    });
  };

  const blacklist = async (contractAddress, addressToBeBlacklisted) => {
    var gasEstimate;
    var estimateError;
    await contracts[contractAddress].estimateGas
      .blacklist(addressToBeBlacklisted)
      .then(
        (result) => (gasEstimate = result),
        (error) => (estimateError = error)
      );
    if (estimateError) return estimateError;

    return await contracts[contractAddress].blacklist(addressToBeBlacklisted, {
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
    });
  };

  const setWhiteListOnly = async (contractAddress) => {
    var gasEstimate;
    var estimateError;
    await contracts[contractAddress].estimateGas.setWhiteListOnly().then(
      (result) => (gasEstimate = result),
      (error) => (estimateError = error)
    );
    if (estimateError) return estimateError;

    return await contracts[contractAddress].setWhiteListOnly({
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
    });
  };

  const whitelist = async (contractAddress, addressToBeWhitelisted) => {
    var gasEstimate;
    var estimateError;
    await contracts[contractAddress].estimateGas
      .whitelist(addressToBeWhitelisted)
      .then(
        (result) => (gasEstimate = result),
        (error) => (estimateError = error)
      );
    if (estimateError) return estimateError;

    return await contracts[contractAddress].whitelist(addressToBeWhitelisted, {
      gasLimit: getGasLimit(gasEstimate),
      gasPrice: await getGasPrice(),
    });
  };

  return {
    canCallNFTPots,
    setCanCallNFTPots,
    getLastBidWinner,
    getBidAmount,
    getIsClaim,
    getTimeUntilExpiry,
    getCreatedDate,
    getClaimedDate,
    getIsBidNative,
    getPotToken,
    getBidToken,
    getPotTokenID,
    getOwnerOfTournament,
    getBlacklistedAddresses,
    getCreatorClaimAllowTime,
    getWinnerClaimAllowTime,
    getDeposited,
    getWhitelistAddresses,
    getWhitelistOnly,
    getBidOption,
    getBidVariable1,
    getBidVariable2,
    getTopOwner,
    getOwnerFee,
    getToPreviousBidderFee,
    getToTournamentCreator,
    getToTournamentCreatorFee,
    getToBurnFee,
    getBidInfo,
    getExpirationTime,
    getExpExpiryOption,
    getExpChangeBy,
    getExpMinimumTime,
    getIsPriority,
    bid,
    bidERC20,
    claim,
    depositERC721Token,
    blacklist,
    setWhiteListOnly,
    whitelist,
  };
}
