import { TournamentType } from "../types";

const isTournamentActive = (tournament) => Date.now() / 1000 < tournament.timeUntilExpiry && tournament.isClaim === false;
const isTournamentExpired = (tournament) => tournament.isClaim === false
    && Date.now() / 1000 > tournament.timeUntilExpiry
    && Date.now() / 1000 < tournament.timeUntilExpiry + tournament.winnerClaimAllowTime;
const isTournamentCreator = (tournament) => tournament.isClaim === false
    && Date.now() / 1000 > tournament.timeUntilExpiry + tournament.winnerClaimAllowTime
    && Date.now() / 1000 < tournament.timeUntilExpiry + tournament.creatorClaimAllowTime;
const isTournamentTopOwner = (tournament) => tournament.isClaim === false
    && Date.now() / 1000 > tournament.timeUntilExpiry + tournament.creatorClaimAllowTime;
const isTournamentClosed = (tournament) => Date.now() / 1000 > tournament.timeUntilExpiry && tournament.isClaim === true;

const updateTournamentType = (tournament) => {
    if (isTournamentActive(tournament)) tournament.tournamentType = TournamentType.active;
    if (isTournamentExpired(tournament)) tournament.tournamentType = TournamentType.expired;
    if (isTournamentCreator(tournament)) tournament.tournamentType = TournamentType.creator;
    if (isTournamentTopOwner(tournament)) tournament.tournamentType = TournamentType.topOwner;
    if (isTournamentClosed(tournament)) tournament.tournamentType = TournamentType.closed;
}

export {
    isTournamentActive,
    isTournamentExpired,
    isTournamentCreator,
    isTournamentTopOwner,
    isTournamentClosed,
    updateTournamentType,
}