import success from "../../../assets/icons/success.svg";
import Button from "../../../components/Button/Button";

export default function SuccessPage() {
    return (
        <div className="flex justify-center">
            <div className="container px-3 py-40 flex flex-col items-center text-center">
                <img src={success} alt="" width={364} height={204} className="mb-8" />
                <p className="text-28 font-bold mb-4">Successful Deposit</p>
                <Button
                    bgColor='green-green-darker'
                    asLink
                    href={"/"}
                >
                    See Tournament
                </Button>
            </div>
        </div>
    )
}