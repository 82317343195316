const textColors = {
    "black": "text-black",
    "grey2": "text-grey2",
    "grey3": "text-grey3",
}

const textColorsHover = {
    "black": "hover:text-black",
    "grey2": "hover:text-green",
    "grey3": "hover:text-grey3-light",
}

const textColorsFocus = {
    "black": "focus:text-black",
    "grey2": "focus:text-green-dark",
    "grey3": "focus:text-white",
}

export { textColors, textColorsHover, textColorsFocus };