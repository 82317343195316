import { useState, useEffect } from "react";
import "./index.css";
import BigNumber from "bignumber.js";
import useWallet from "../../hooks/useWallet";
import toast from "react-hot-toast";
import { BigNumber as BigNumberEthers, ethers } from "ethers";
import { TimeUnit } from "../../types";
import NetworksModal from "../../components/Modals/NetworksModal";
import Pagination from "./components/Pagination";
import Page0 from "./pages/Page0";
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import getTimeUnitInSeconds from "../../utils/getTimeUnitInSeconds";
import SuccessPage from "./pages/SuccessPage";
import useTournamentsInit from "./hooks/useTournamentsInit";
import useNFTTournamentsInit from "./hooks/useNFTTournamentsInit";
import useTokenListInit from "./hooks/useTokenListInit";
import useNFTTokenListInit from "./hooks/useNFTTokenListInit";
import useBalance from "../../hooks/useBalance";
import useMediaQuery from "../../hooks/useMediaQuery";

const Tournament = (props) => {
  const {
    wallet,
    chain,
    currentNetwork,
    canCall,
    connectMetamask,
    switchToNetwork,
    getContract,
    watchTransaction,
    getGasLimit,
    getGasPrice,
    getBalance,
  } = useWallet();

  const bp1024px = useMediaQuery(1024);

  const [prizeTokenType, setPrizeTokenType] = useState("0");

  const [pairAddress, setPairAddress] = useState("");

  const [topOwnerFee, setTopOwnerFee] = useState(0);
  const [creatorFee, setCreatorFee] = useState("");
  const [potFee, setPotFee] = useState("");
  const [toPreviousFee, setToPreviousFee] = useState("");
  const [burn, setBurn] = useState("");

  // expiry time
  const [expVariable1, setExpVariable1] = useState("");
  const [expVariable2, setExpVariable2] = useState("");
  const [expVariable3, setExpVariable3] = useState("");

  const [timeUnit1, setTimeUnit1] = useState(TimeUnit.Seconds);
  const [timeUnit2, setTimeUnit2] = useState(TimeUnit.Seconds);
  const [timeUnit3, setTimeUnit3] = useState(TimeUnit.Seconds);
  const [timeUnit4, setTimeUnit4] = useState(TimeUnit.Seconds);

  // bidAmount
  const [bidVariable1, setBidVariable1] = useState("");
  const [bidVariable2, setBidVariable2] = useState("");

  // Build village
  const [priorityPrice, setPriorityPrice] = useState();
  const [nonPriorityPrice, setNonPriorityPrice] = useState();
  const [selectedBidOption, setSelectedBidOption] = useState("0");
  const [selectedPriorityPoolOption, setSelectedPriorityPoolOption] =
    useState();
  const [selectedExpireTimeOption, setSelectedExpireTimeOption] = useState("0");
  const [selectedPotToken, setSelectedPotToken] = useState(0);
  const [selectedPotTokenAddress, setSelectedPotTokenAddress] = useState("");
  const [selectedBidToken, setSelectedBidToken] = useState(0);
  const [potAmount, setPotAmount] = useState("");
  const [potTokenID, setPotTokenID] = useState("");

  // token list
  const [tokenAddresses, setTokenAddresses] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [NFTTokenAddresses, setNFTTokenAddresses] = useState([]);
  const [NFTTokenList, setNFTTokenList] = useState([]);

  const [distributions, setDistributions] = useState([]);

  const [roundDuration, setRoundDuration] = useState("");
  const [roundReward, setRoundReward] = useState("");

  const [loadingCreatePot, setLoadingCreatePot] = useState(false);
  const [loadingTokenList, setLoadingTokenList] = useState(
    wallet && currentNetwork ? true : false
  );
  const [networksModalOpen, setNetworksModalOpen] = useState(false);

  const [tournamentsInitDone, setTournamentsInitDone] = useState(false);
  const [NFTTournamentsInitDone, setNFTTournamentsInitDone] = useState(false);
  const [error, setError] = useState(false);
  const [updateTimestamp, setUpdateTimestamp] = useState();

  const balance = useBalance(canCall, wallet, currentNetwork, getBalance);

  const isPrizeNFT = prizeTokenType === "1";
  const isPotTokenNative = selectedPotToken === 0 && !isPrizeNFT;
  const isBidTokenNative = selectedBidToken === 0;
  const potTokenDecimals = tokenList?.[selectedPotToken]?.decimals;
  const bidTokenDecimals = tokenList?.[selectedBidToken]?.decimals;
  const minPotAmount = (1 / 10 ** potTokenDecimals).toFixed(potTokenDecimals);
  const minBidAmount = (1 / 10 ** bidTokenDecimals).toFixed(bidTokenDecimals);
  const potAmountParsed =
    potTokenDecimals &&
    parseFloat(potAmount) &&
    potAmount >= minPotAmount &&
    potAmount.length <= 2 + potTokenDecimals
      ? ethers.utils.parseUnits(potAmount, potTokenDecimals)
      : 0;
  const bidAmountParsed =
    bidTokenDecimals &&
    parseFloat(bidVariable1) &&
    bidVariable1 >= minBidAmount &&
    bidVariable1.length <= 2 + bidTokenDecimals
      ? ethers.utils.parseUnits(bidVariable1, bidTokenDecimals)
      : 0;
  const roundRewardParsed =
    potTokenDecimals &&
    parseFloat(roundReward) &&
    roundReward >= minPotAmount &&
    roundReward.length <= 2 + potTokenDecimals
      ? ethers.utils.parseUnits(roundReward, potTokenDecimals)
      : 0;
  const depositValue = ethers.utils.parseUnits(
    (selectedPriorityPoolOption || 2).toString(),
    currentNetwork?.data.nativeCurrency.decimals || 18
  );
  const notEnoughPotToken = potAmountParsed
    ? isPotTokenNative
      ? balance?.lt(BigNumberEthers.from(potAmountParsed))
      : tokenList?.[selectedPotToken]?.balance?.lt(
          BigNumberEthers.from(potAmountParsed)
        )
    : false;
  const notEnoughNativeToken = balance
    ? isPotTokenNative
      ? balance.lt(depositValue.add(potAmountParsed))
      : balance.lt(depositValue)
    : false;
  const notOwnerOfToken =
    isPrizeNFT &&
    potTokenID &&
    NFTTokenList?.[selectedPotToken]?.owner !== wallet
      ? true
      : false;
  const lastContractAddress =
    currentNetwork?.tournamentsAddresses?.[
      currentNetwork?.tournamentsAddresses.length - 1
    ];
  const lastNFTContractAddress =
    currentNetwork?.NFTTournamentsAddresses?.[
      currentNetwork?.NFTTournamentsAddresses.length - 1
    ];

  useEffect(() => {
    if (currentNetwork) {
      setPrizeTokenType("0");
    }
  }, [currentNetwork]);

  useEffect(() => {
    if (currentNetwork) {
      setSelectedPotToken(0);
      setSelectedBidToken(0);
    }
  }, [currentNetwork, isPrizeNFT]);

  useEffect(() => {
    if (currentNetwork)
      setSelectedPotTokenAddress(
        (isPrizeNFT ? NFTTokenList : tokenList)?.[selectedPotToken]?.["address"]
      );
  }, [selectedPotToken, currentNetwork, isPrizeNFT, tokenList, NFTTokenList]);

  useEffect(() => {
    if (isBidTokenNative) setBurn("");
  }, [isBidTokenNative]);

  const { createPot } = useTournamentsInit(
    lastContractAddress,
    wallet,
    currentNetwork,
    prizeTokenType,
    setTopOwnerFee,
    setTokenAddresses,
    setPriorityPrice,
    setNonPriorityPrice,
    setSelectedPriorityPoolOption,
    setLoadingTokenList,
    setTournamentsInitDone,
    setError,
    updateTimestamp
  );

  const { createPot: createNFTPot } = useNFTTournamentsInit(
    lastNFTContractAddress,
    wallet,
    currentNetwork,
    prizeTokenType,
    setTopOwnerFee,
    setTokenAddresses,
    setNFTTokenAddresses,
    setPriorityPrice,
    setNonPriorityPrice,
    setSelectedPriorityPoolOption,
    setLoadingTokenList,
    setNFTTournamentsInitDone,
    setError,
    updateTimestamp
  );

  useTokenListInit(
    watchTransaction,
    wallet,
    getContract,
    tokenAddresses,
    tokenList,
    setTokenList
  );

  useNFTTokenListInit(
    watchTransaction,
    wallet,
    getContract,
    NFTTokenAddresses,
    setNFTTokenList,
    prizeTokenType,
    potTokenID
  );

  useEffect(() => {
    if (error) {
      setTournamentsInitDone(false);
      setNFTTournamentsInitDone(false);
    }
  }, [error]);

  useEffect(() => {
    if (currentNetwork) setError(false);
  }, [currentNetwork]);

  useEffect(() => {
    if (loadingTokenList) {
      if (isPrizeNFT) {
        if (
          NFTTournamentsInitDone &&
          tokenAddresses.length <= tokenList.length - 1 &&
          tokenList.length >= 1 &&
          NFTTokenAddresses.length === NFTTokenList.length
        ) {
          setLoadingTokenList(false);
          setNFTTournamentsInitDone(false);
        }
      } else if (
        tournamentsInitDone &&
        tokenAddresses.length <= tokenList.length - 1 &&
        tokenList.length >= 1
      ) {
        setLoadingTokenList(false);
        setTournamentsInitDone(false);
      }
    }
  }, [
    tokenAddresses,
    tokenList,
    NFTTokenAddresses,
    NFTTokenList,
    isPrizeNFT,
    loadingTokenList,
    tournamentsInitDone,
    NFTTournamentsInitDone,
  ]);

  const nativeToken = {
    value: 0,
    label: currentNetwork?.data.nativeCurrency.symbol || "",
    address: currentNetwork?.data.nativeCurrency.address,
    decimals: currentNetwork?.data.nativeCurrency.decimals,
  };
  useEffect(() => {
    if (tokenAddresses.length === 0 && currentNetwork && wallet)
      setTokenList([nativeToken]);
  }, [tokenAddresses, currentNetwork, wallet]);

  useEffect(() => {
    if (currentNetwork && wallet) setTokenList([nativeToken]);
    else {
      setTokenAddresses([]);
      setTokenList([]);
      setNFTTokenAddresses([]);
      setNFTTokenList([]);
    }
  }, [currentNetwork, wallet]);

  const onCreatePot = async (potAddress, bidAddress) => {
    setLoadingCreatePot(true);
    const priorityPool = selectedPriorityPoolOption === priorityPrice ? 1 : 0,
      expirationTime = [
        selectedExpireTimeOption,
        getTimeUnitInSeconds(timeUnit1, expVariable1),
        getTimeUnitInSeconds(timeUnit2, expVariable2),
        getTimeUnitInSeconds(timeUnit3, expVariable3, 0),
      ],
      bidAmount = [
        selectedBidOption,
        selectedBidOption === "1" ? bidVariable1 : bidAmountParsed,
        bidVariable2 || 0,
      ],
      depositValue = BigNumber(
        selectedPriorityPoolOption * 1000000000000000000
      ),
      toAddress = [],
      toPercent = [],
      fees = [
        Date.now(),
        potAmountParsed,
        creatorFee || "0",
        potFee || "0",
        toPreviousFee || "0",
        burn || "0",
        getTimeUnitInSeconds(timeUnit4, roundDuration, 0),
        roundRewardParsed || "0",
      ];
    distributions.forEach((distAddress) => {
      toAddress.push(distAddress.address);
      toPercent.push(distAddress.value);
    });

    try {
      const result = await createPot(
        lastContractAddress,
        potAddress,
        bidAddress,
        bidAmount,
        toAddress,
        toPercent,
        expirationTime,
        priorityPool,
        fees,
        depositValue
      );
      setPairAddress(result?.events?.TournamentCreated?.returnValues?.[0]);
      setPage(lastPageIndex - 1);
      toast.success("Transaction succeeded.");
    } catch (e) {
      console.log(e);
      toast.error("Transaction failed.");
    } finally {
      setLoadingCreatePot(false);
    }
  };

  const onCreateNFTPot = async () => {
    setLoadingCreatePot(true);
    const priorityPool = selectedPriorityPoolOption === priorityPrice ? 1 : 0,
      expirationTime = [
        selectedExpireTimeOption,
        getTimeUnitInSeconds(timeUnit1, expVariable1),
        getTimeUnitInSeconds(timeUnit2, expVariable2),
        getTimeUnitInSeconds(timeUnit3, expVariable3, 0),
      ],
      bidAmount = [selectedBidOption, bidAmountParsed, bidVariable2 || 0],
      depositValue = BigNumber(
        selectedPriorityPoolOption * 1000000000000000000
      ),
      toAddress = [],
      toPercent = [],
      fees = [
        Date.now(),
        Math.max(0, selectedBidToken - 1),
        Math.max(0, selectedPotToken - 1),
        potTokenID,
        creatorFee || "0",
        toPreviousFee || "0",
        burn || "0",
      ];
    distributions.forEach((distAddress) => {
      toAddress.push(distAddress.address);
      toPercent.push(distAddress.value);
    });

    try {
      const result = await createNFTPot(
        lastNFTContractAddress,
        isBidTokenNative,
        bidAmount,
        toAddress,
        toPercent,
        expirationTime,
        priorityPool,
        fees,
        depositValue
      );
      setPairAddress(result?.events?.TournamentCreated?.returnValues?.[0]);
      setPage(lastPageIndex - 1);
      toast.success("Transaction succeeded.");
    } catch (e) {
      toast.error("Transaction failed.");
    } finally {
      setLoadingCreatePot(false);
    }
  };

  useEffect(() => {
    setBidVariable1("");
    setBidVariable2("");
  }, [selectedBidOption]);

  useEffect(() => {
    if (
      selectedPotToken !== selectedBidToken &&
      selectedBidOption === "1" &&
      !isPrizeNFT
    )
      setSelectedBidOption("0");
  }, [selectedPotToken, selectedBidToken]);

  useEffect(() => {
    setRoundDuration("");
    setRoundReward("");
  }, [selectedPotToken, currentNetwork, prizeTokenType]);

  const lastPageIndex = 4;
  const [page, setPage] = useState(0);
  const [completedPage, setCompletedPage] = useState();

  useEffect(() => {
    if (page > 0) setCompletedPage(page - 1);
    else setCompletedPage();
  }, [page]);

  const Page = () => {
    switch (page) {
      case 0:
        return (
          <Page0
            prizeTokenType={prizeTokenType}
            setPrizeTokenType={setPrizeTokenType}
            potTokenID={potTokenID}
            setPotTokenID={setPotTokenID}
            tokenList={tokenList}
            NFTTokenList={NFTTokenList}
            selectedPotToken={selectedPotToken}
            setSelectedPotToken={setSelectedPotToken}
            potTokenDecimals={potTokenDecimals}
            minPotAmount={minPotAmount}
            potAmount={potAmount}
            setPotAmount={setPotAmount}
            selectedBidToken={selectedBidToken}
            setSelectedBidToken={setSelectedBidToken}
            bidTokenDecimals={bidTokenDecimals}
            selectedBidOption={selectedBidOption}
            setSelectedBidOption={setSelectedBidOption}
            minBidAmount={minBidAmount}
            bidVariable1={bidVariable1}
            setBidVariable1={setBidVariable1}
            bidVariable2={bidVariable2}
            setBidVariable2={setBidVariable2}
            setPage={setPage}
            wallet={wallet}
            connectMetamask={connectMetamask}
            chain={chain}
            setNetworksModalOpen={setNetworksModalOpen}
            notEnoughPotToken={notEnoughPotToken}
            loadingTokenList={loadingTokenList}
            notOwnerOfToken={notOwnerOfToken}
            currentNetwork={currentNetwork}
            error={error}
            setUpdateTimestamp={setUpdateTimestamp}
          />
        );
      case 1:
        return (
          <Page1
            prizeTokenType={prizeTokenType}
            topOwnerFee={topOwnerFee}
            creatorFee={creatorFee}
            setCreatorFee={setCreatorFee}
            potFee={potFee}
            setPotFee={setPotFee}
            toPreviousFee={toPreviousFee}
            setToPreviousFee={setToPreviousFee}
            burn={burn}
            setBurn={setBurn}
            distributions={distributions}
            setDistributions={setDistributions}
            isBidTokenNative={isBidTokenNative}
            setPage={setPage}
            wallet={wallet}
            connectMetamask={connectMetamask}
            chain={chain}
            setNetworksModalOpen={setNetworksModalOpen}
            error={error}
          />
        );
      case 2:
        return (
          <Page2
            selectedExpireTimeOption={selectedExpireTimeOption}
            setSelectedExpireTimeOption={setSelectedExpireTimeOption}
            timeUnit1={timeUnit1}
            setTimeUnit1={setTimeUnit1}
            expVariable1={expVariable1}
            setExpVariable1={setExpVariable1}
            timeUnit2={timeUnit2}
            setTimeUnit2={setTimeUnit2}
            expVariable2={expVariable2}
            setExpVariable2={setExpVariable2}
            timeUnit3={timeUnit3}
            setTimeUnit3={setTimeUnit3}
            expVariable3={expVariable3}
            setExpVariable3={setExpVariable3}
            tokenList={tokenList}
            priorityPrice={priorityPrice}
            nonPriorityPrice={nonPriorityPrice}
            selectedPriorityPoolOption={selectedPriorityPoolOption}
            setSelectedPriorityPoolOption={setSelectedPriorityPoolOption}
            wallet={wallet}
            connectMetamask={connectMetamask}
            chain={chain}
            loadingCreatePot={loadingCreatePot}
            onCreatePot={!isPrizeNFT ? onCreatePot : onCreateNFTPot}
            notEnoughPotToken={notEnoughPotToken}
            notEnoughNativeToken={notEnoughNativeToken}
            selectedPotToken={selectedPotToken}
            selectedBidToken={selectedBidToken}
            currentNetwork={currentNetwork}
            setNetworksModalOpen={setNetworksModalOpen}
            setPage={setPage}
            roundDuration={roundDuration}
            setRoundDuration={setRoundDuration}
            roundReward={roundReward}
            setRoundReward={setRoundReward}
            timeUnit4={timeUnit4}
            setTimeUnit4={setTimeUnit4}
            potTokenDecimals={potTokenDecimals}
            minPotAmount={minPotAmount}
            isPotTokenNative={isPotTokenNative}
            prizeTokenType={prizeTokenType}
            error={error}
          />
        );
      case 3:
        return (
          <Page3
            prizeTokenType={prizeTokenType}
            connectMetamask={connectMetamask}
            wallet={wallet}
            chain={chain}
            pairAddress={pairAddress}
            getContract={getContract}
            getGasLimit={getGasLimit}
            getGasPrice={getGasPrice}
            watchTransaction={watchTransaction}
            isPotTokenNative={isPotTokenNative}
            selectedPotTokenAddress={selectedPotTokenAddress}
            potAmount={potAmount}
            potAmountParsed={potAmountParsed}
            notEnoughPotToken={notEnoughPotToken}
            tokenList={tokenList}
            NFTTokenList={NFTTokenList}
            selectedPotToken={selectedPotToken}
            setNetworksModalOpen={setNetworksModalOpen}
            setPage={setPage}
            lastPageIndex={lastPageIndex}
            potTokenID={potTokenID}
            notOwnerOfToken={notOwnerOfToken}
            error={error}
          />
        );
    }
  };

  return page === lastPageIndex ? (
    <SuccessPage />
  ) : (
    <div
      className={`${
        bp1024px ? "grid grid-cols-12 gap-12" : "flex justify-center"
      } min-h-[100vh]`}
    >
      <div className="hidden lg:block col-span-0 lg:col-span-4 createBg bg-contain bg-no-repeat bg-fixed text-center h-full pt-40 pb-20 px-12">
        <p className="text-32 font-bold mb-8">Build village</p>
        <p className="text-24 font-bold">Achieve financial freedom today!</p>
      </div>
      <div
        className={`pt-32 pb-16 px-5 lg:px-12 ${
          !bp1024px ? "container" : "col-span-8"
        }`}
      >
        <Pagination
          page={page}
          setPage={setPage}
          completedPage={completedPage}
          lastPageIndex={lastPageIndex}
          className="mb-4"
          error={error}
        />
        {Page()}
      </div>
      <NetworksModal
        open={networksModalOpen}
        setOpen={setNetworksModalOpen}
        currentNetwork={currentNetwork}
        switchToNetwork={switchToNetwork}
      />
    </div>
  );
};

export default Tournament;
