import { utils } from "ethers";

const parseBigNumber = (bn, decimalsOrUnitName = "ether") => {
    if (!bn) return 0;
    try {
        return parseFloat(utils.formatUnits(bn, decimalsOrUnitName));
    } catch (e) {
        return 0;
    }
};

export default parseBigNumber;