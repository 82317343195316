const fillColors = {
    green: "fill-green",
    "green-green-darker": "fill-green",
    grey2: "fill-grey2",
    "grey2-grey2-darker": "fill-grey2",
    white: "fill-white",
    red: "fill-red",
    "white-black": "fill-white",
    "black-white": "fill-black",
    "grey3": "fill-grey3",
    "orange": "fill-orange",
}

const fillColorsHover = {
    green: "hover:fill-white",
    "green-green-darker": "hover:fill-green-dark",
    grey2: "hover:fill-white",
    "grey2-grey2-darker": "hover:fill-grey2-dark",
    white: "hover:fill-white",
    red: "hover:fill-red-dark",
    "white-black": "hover:fill-black",
    "black-white": "hover:fill-white",
    "grey3": "hover:fill-grey3-light",
    "orange": "hover:fill-orange-dark",
}

const fillColorsFocus = {
    green: "focus:fill-white",
    "green-green-darker": "focus:fill-green-darker",
    grey2: "focus:fill-white",
    "grey2-grey2-darker": "focus:fill-grey2-darker",
    white: "focus:fill-white",
    red: "focus:fill-red-darker",
    "white-black": "focus:fill-black",
    "black-white": "focus:fill-white",
    "grey3": "focus:fill-white",
    "orange": "focus:fill-orange-darker",
}

export { fillColors, fillColorsHover, fillColorsFocus };