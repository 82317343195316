import { BigNumber } from "ethers";
import { useState } from "react";
import useERC20 from "../contracts/ERC20/useERC20";
import useERC721 from "../contracts/ERC721/useERC721";
import useNFTPots from "../contracts/NFTPot/useNFTPots";
import usePots from "../contracts/pot/usePots";
import useContract from "./useContract";

export default function useTournamentLogic(watchTransaction,
    wallet,
    getContract,
    bidToken,
    potToken,
    isNFT,
    contractAddress,
    getGasLimit,
    getGasPrice,
    isNativeBidToken,
    isNativePotToken,
    refreshTournamentData,
    updateBalanceOfBidTokenInContract,
    updateBalanceOfContract,
    setDeposited,
    setBalanceOfBidToken,
    setBalanceOfPotToken,
    balance,
    potTokenID,
) {
    const [loadingBid, setLoadingBid] = useState(false);
    const [loadingClaim, setLoadingClaim] = useState(false);
    const [loadingDeposit, setLoadingDeposit] = useState(false);

    const {
        getBalanceOf,
        callMethod: approveERC20,
        loading: loadingApproveERC20,
        approved: approvedERC20,
    } = useERC20(watchTransaction, wallet, getContract, [bidToken, isNFT ? "" : potToken], contractAddress);

    const {
        callMethod: approveERC721,
        loading: loadingApproveERC721,
        approved: approvedERC721,
    } = useERC721(watchTransaction, wallet, getContract, [potToken], contractAddress, potTokenID);

    const {
        bid,
        bidERC20,
        claim,
        depositToken,
        depositERC20Token,
    } = usePots([contractAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    const {
        bid: bidNFT,
        bidERC20: bidERC20NFT,
        claim: claimNFT,
        depositERC721Token,
    } = useNFTPots([contractAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    const { state: stateBid, callMethod: onBid } = useContract(
        watchTransaction,
        async (bidAmount) => {
            setLoadingBid(true);
            if (isNativeBidToken) return isNFT
                ? await bidNFT(contractAddress, BigNumber.from(bidAmount))
                : await bid(contractAddress, BigNumber.from(bidAmount));
            else return isNFT
                ? await bidERC20NFT(contractAddress)
                : await bidERC20(contractAddress);
        },
        async () => {
            await refreshTournamentData(contractAddress);
            setTimeout(async () => {
                if (!isNFT) await updateBalanceOfBidTokenInContract();
                await updateBalanceOfBidToken();
                if (!isNFT) await updateBalanceOfContract();
                setLoadingBid(false);
            }, 1500);
        },
        () => {
            setLoadingBid(false);
        }
    );

    const { state: stateClaim, callMethod: onClaim } = useContract(
        watchTransaction,
        async () => {
            setLoadingClaim(true);
            return isNFT ? await claimNFT(contractAddress) : await claim(contractAddress);
        },
        async () => {
            await refreshTournamentData(contractAddress);
            setTimeout(async () => {
                if (!isNFT) await updateBalanceOfBidTokenInContract();
                await updateBalanceOfBidToken();
                if (!isNFT) await updateBalanceOfContract();
                setLoadingClaim(false);
            }, 1500);
        },
        () => {
            setLoadingClaim(false);
        }
    );

    const { state: stateDeposit, callMethod: onDeposit } = useContract(
        watchTransaction,
        async (contractAddress, potAmount) => {
            setLoadingDeposit(true);

            if (isNativePotToken) return await depositToken(contractAddress, potAmount);
            else return await depositERC20Token(contractAddress);
        },
        async () => {
            await refreshTournamentData(contractAddress);
            setTimeout(async () => {
                await updateBalanceOfPotToken();
                setLoadingDeposit(false);
                setDeposited(true);
            }, 1500);
        },
        () => {
            setLoadingDeposit(false);
        }
    );

    const { state: stateDepositNFT, callMethod: onDepositNFT } = useContract(
        watchTransaction,
        async (contractAddress) => {
            setLoadingDeposit(true);
            return await depositERC721Token(contractAddress);
        },
        async () => {
            await refreshTournamentData(contractAddress);
            setLoadingDeposit(false);
            setDeposited(true);
        },
        () => {
            setLoadingDeposit(false);
        }
    );

    const updateBalanceOfBidToken = async () => {
        if (isNativeBidToken) setBalanceOfBidToken(balance);
        else setBalanceOfBidToken(await getBalanceOf(bidToken));
    }

    const updateBalanceOfPotToken = async () => {
        if (isNativePotToken) setBalanceOfPotToken(balance);
        else setBalanceOfPotToken(await getBalanceOf(potToken));
    }

    return {
        onBid,
        onClaim,
        onDepositNFT,
        onDeposit,
        approveERC20,
        approveERC721,
        loadingApproveERC20,
        loadingApproveERC721,
        loadingBid,
        loadingClaim,
        loadingDeposit,
        approvedERC20,
        approvedERC721,
    }
}