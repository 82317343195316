import Button from "../components/Button/Button";

export default function NotFoundPage() {
    return (
        <div className="flex justify-center">
            <div className="container px-3 py-40 flex flex-col items-center text-center min-h-[100vh]">
                <p className="text-28 font-bold mb-4">The page is not found.</p>
                <Button
                    bgColor='green-green-darker'
                    asLink
                    href={"/"}
                >
                    Home
                </Button>
            </div>
        </div>
    )
}