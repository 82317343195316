import { TimeUnit } from "../types";

const minuteInSeconds = 60;
const hourInSeconds = 3600;
const dayInSeconds = hourInSeconds * 24;

const getTimeUnitInSeconds = (timeUnit, expVariable, min = 1) => {
    if (expVariable)
        switch (timeUnit) {
            case TimeUnit.Seconds: return expVariable;
            case TimeUnit.Minutes: return Math.max(Math.round(expVariable * minuteInSeconds), min);
            case TimeUnit.Hours: return Math.max(Math.round(expVariable * hourInSeconds), min);
            case TimeUnit.Days: return Math.max(Math.round(expVariable * dayInSeconds), min);
        }
    else return 0;
}

export default getTimeUnitInSeconds;