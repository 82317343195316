import Countdown from "react-countdown";

export default function CountdownTextLike({
    timeUntilExpiry,
    className = "",
}) {
    return (
        <Countdown
            date={timeUntilExpiry * 1000}
            renderer={({ days, hours, minutes, seconds, completed }) => {
                const numberOfThatAreZeros = days === 0 ? 1 : 0 + hours === 0 ? 1 : 0 + minutes === 0 ? 1 : 0;
                const gridCols = numberOfThatAreZeros === 0
                    ? "grid-cols-4"
                    : numberOfThatAreZeros === 1
                        ? "grid-cols-3"
                        : numberOfThatAreZeros === 2
                            ? "grid-cols-2"
                            : "grid-cols-1";
                return (
                    <div className={className}>
                        {completed
                            ? <p className="text-20 font-medium">Expired!</p>
                            :
                            <div className="flex">
                                <div>
                                    <div className={`grid ${gridCols} gap-0.5 text-20 font-medium text-2xl`}>
                                        {days > 0 && <span>{days?.toString()}</span>}
                                        {hours > 0 && <span>{hours?.toString()}</span>}
                                        {minutes > 0 && <span>{minutes?.toString()}</span>}
                                        <span>{seconds?.toString()}</span>
                                    </div>
                                    <div className={`grid ${gridCols} gap-0.5 text-12`}>
                                        {days > 0 && <span>day{days === 1 ? "" : "s"}</span>}
                                        {hours > 0 && <span>hour{hours === 1 ? "" : "s"}</span>}
                                        {minutes > 0 && <span>minute{minutes === 1 ? "" : "s"}</span>}
                                        <span>second{seconds === 1 ? <span className="invisible">s</span> : "s"}</span>
                                    </div>
                                </div>
                            </div>}
                    </div>
                )
            }}
        />
    )
}