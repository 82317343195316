import { useEffect } from "react";
import { useState } from "react";
import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import { BigNumber, Contract, providers } from "ethers";
import networks, { chainIds } from "../networks";
import ABI from "../contracts/tournaments/tournamentsAbi";

export default function useWallet() {
    const [wallet, setWallet] = useState(null);
    const [chain, setChain] = useState(-1);
    const [loaded, setLoaded] = useState(false);
    const [canCall, setCanCall] = useState(false);

    const currentNetwork = networks.find(network => network.data.chainId === chain);

    const _isMetaMaskInstalled = () => {
        if (typeof window === "undefined") return;
        const { ethereum } = window;
        return Boolean(ethereum && ethereum.isMetaMask);
    };

    const getProvider = () => {
        if (!_isMetaMaskInstalled()) return null;
        return new providers.Web3Provider(window.ethereum, 'any');
    }
    const provider = getProvider();
    const signer = provider?.getSigner();

    const _getChain = async () => {
        if (!provider) return -1;
        return (await provider.getNetwork())?.chainId;
    };

    const _onAccountsChanged = (callback) => {
        if (!_isMetaMaskInstalled()) return;
        window.ethereum.on("accountsChanged", callback);
    };

    const _onChainChanged = (callback) => {
        if (!_isMetaMaskInstalled()) return;
        provider.on("network", callback);
    };

    const _getAddress = async () => {
        if (!provider) return "";
        try {
            const accounts = await provider.listAccounts();
            return accounts.length > 0 ? accounts[0] : "";
        } catch (e) {
            return "";
        }
    };

    const connectMetamask = async () => {
        if (!_isMetaMaskInstalled()) return false;
        try {
            await window.ethereum.request({ method: "eth_requestAccounts" });
            return true;
        } catch (e) {
            return false;
        }
    }

    const switchToNetwork = async (chainId) => {
        if (!_isMetaMaskInstalled()) return false;
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: `0x${parseInt(chainId.toString() || "").toString(16)}` }],
            });
            return true;
        } catch (e) {
            if (e.code === 4902) {
                addNetwork(networks.find(network => network.data.chainId === chainId));
                return true;
            }
            else return false;
        }
    };

    const addNetwork = async (network) => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                    chainId: `0x${parseInt(network.data.chainId.toString() || "").toString(16)}`,
                    chainName: network.data.chainName,
                    nativeCurrency: network.data.nativeCurrency,
                    rpcUrls: network.data.rpcUrls,
                    blockExplorerUrls: network.data.blockExplorerUrls
                }]
            });
        }
        catch (e) { }
    }

    function getContract(address, abi) {
        if (signer) return new Contract(address, abi, signer);
    }

    useEffect(() => {
        const load = async () => {
            try {
                setWallet((await _getAddress())?.toLowerCase());
                setChain(await _getChain());
                setLoaded(true);
            } catch (error) {
                setLoaded(true);
                return error;
            }
        };

        if (window.ethereum) {
            load();
        } else {
            window.addEventListener('ethereum#initialized', load, { once: true, });

            // If the event is not dispatched by the end of the timeout,
            // the user probably doesn't have MetaMask installed.
            setTimeout(load, 3000); // 3 seconds
        }

        _onAccountsChanged((_address) => {
            if (!_address[0]) setWallet(null);
            else setWallet(_address[0].toLowerCase());
        });
        _onChainChanged((_chain) => {
            if (!_chain) return;
            setChain(_chain.chainId);
        });
    }, []);

    //window.ethereum.on is not a function debug
    /*     useEffect(() => {
            console.log("eth", window?.ethereum?.on)
        }, [window.ethereum]); */

    useEffect(() => {
        if (wallet && chainIds.includes(chain)) setCanCall(true);
        else setCanCall(false);
    }, [wallet, chain]);

    const getGasLimit = (gasEstimate, multiplier = 14) => {
        return gasEstimate.mul(BigNumber.from(multiplier)).div(BigNumber.from(10));
    }

    const getGasPrice = async (multiplier = 1.3) => {
        if (provider) {
            const gasPrice = await provider.getGasPrice();
            return Math.round(gasPrice * multiplier);
        }
    }

    const watchTransaction = (txHash, callback) => {
        if (!provider) return;
        provider.once(txHash, (transaction) => {
            callback(transaction, transaction.status === 1);
        });
    };

    const getBalance = async (address = wallet) => await provider.getBalance(address);

    const getLogs = async () => {
        // const latestBlockNumber = await provider.getBlockNumber();
        // const contract = getContract("0xeb94CB41EAB3bBeA3be756ABAe7dFB50dfDF6FB1", ABI);
        // const filter = contract.filters.TournamentCreated(null);
        // const logs = [];
        // for (var block = latestBlockNumber; block >= 0; block -= 3000) {
        //     const events = await contract.queryFilter(filter, Math.max(0, block - 3000), block);
        //     logs.push(...events);
        // }

        /* const event = contract.interface.events["TournamentCreated(address)"];
        console.log("event", event) */
        /* console.log("latestBlockNumber", latestBlockNumber) */
        /* return await provider.getLogs({
            //  filter,
            fromBlock: latestBlockNumber - 1,
            toBlock: latestBlockNumber,
            address: "0xeb94CB41EAB3bBeA3be756ABAe7dFB50dfDF6FB1",
            // topics: filter.topics,
        }) */
    }

    return {
        wallet,
        chain,
        currentNetwork,
        loaded,
        canCall,
        connectMetamask,
        switchToNetwork,
        getContract,
        watchTransaction,
        getGasLimit,
        getGasPrice,
        getBalance,
    }
}