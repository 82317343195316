import { useState, useEffect } from "react";
import "./index.css";
import useWallet from "../../hooks/useWallet";
import Tournament from "./Tournament";
import avalanche from "../../assets/icons/hero/avalanche.svg";
import binance from "../../assets/icons/hero/binance.svg";
import ethereum from "../../assets/icons/hero/ethereum.svg";
import fantom from "../../assets/icons/hero/fantom.svg";

import polygon from "../../assets/icons/hero/polygon.svg";
import Button from "../../components/Button/Button";
import Loading from "../../components/Loading/Loading";
import useTournamentsInit from "./hooks/useTournamentsInit";
import usePotsInit from "./hooks/usePotsInit";
import { useCookies } from "react-cookie";
import useBalance from "../../hooks/useBalance";
import { useRef } from "react";
import { TournamentType } from "../../types";
import { useLocation } from "react-router-dom";
import createNotification from "../../utils/createNotification";
import notification from "../../assets/sounds/notification.mp3";
import playSound from "../../utils/playSound";
import useNow from "../../hooks/useNow";
import {
  isTournamentActive,
  isTournamentExpired,
  isTournamentCreator,
  isTournamentTopOwner,
  isTournamentClosed,
  updateTournamentType,
} from "../../utils/tournamentTypesDeciders";

const Home = ({ refHeader, tournamentTypeToGoTo, setTournamentTypeToGoTo }) => {
  window.history.replaceState({}, document.title);

  const [cookies] = useCookies();

  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [tournamentAddresses, setTournamentAddresses] = useState([]);
  const [tournaments, setTournaments] = useState([]);
  const [updateTournamentsTimestamp, setUpdateTournamentsTimestamp] =
    useState();
  const [wantsToApprove, setWantsToApprove] = useState("");
  const [wantsToApprovePotToken, setWantsToApprovePotToken] = useState("");
  const [wantsToBid, setWantsToBid] = useState("");
  const [wantsToClaim, setWantsToClaim] = useState("");
  const [wantsToDeposit, setWantsToDeposit] = useState("");
  const [canApprove, setCanApprove] = useState("");
  const [canApprovePotToken, setCanApprovePotToken] = useState("");
  const [canBid, setCanBid] = useState("");
  const [canClaim, setCanClaim] = useState("");
  const [canDeposit, setCanDeposit] = useState("");
  const [tournamentsTimeoutSet, setTournamentsTimeoutSet] = useState(false);
  const [skipFetch, setSkipFetch] = useState(false);
  const tournamentsToFetchDefault = 4;
  const [tournamentsToFetch, setTournamentsToFetch] = useState(
    tournamentsToFetchDefault
  );

  const refTournamentsContainer = useRef();
  const refActive = useRef();
  const refExpired = useRef();
  const refCreator = useRef();
  const refTopOwner = useRef();
  const refClosed = useRef();

  const routerState = location?.state;

  const now = useNow();

  useEffect(() => {
    if (routerState?.tournamentType)
      setTournamentTypeToGoTo(routerState?.tournamentType);
  }, [routerState]);

  const getTournamentsLengthByType = (tournamentType) => {
    switch (tournamentType) {
      case TournamentType.active:
        return activeTournaments.length;
      case TournamentType.expired:
        return expiredTournaments.length;
      case TournamentType.creator:
        return creatorTournaments.length;
      case TournamentType.topOwner:
        return topOwnerTournaments.length;
      case TournamentType.closed:
        return closedTournaments.length;
    }
  };

  const getTournamentTitleRef = (tournamentType) => {
    switch (tournamentType) {
      case TournamentType.active:
        return refActive;
      case TournamentType.expired:
        return refExpired;
      case TournamentType.creator:
        return refCreator;
      case TournamentType.topOwner:
        return refTopOwner;
      case TournamentType.closed:
        return refClosed;
    }
  };

  useEffect(() => {
    const ref = getTournamentTitleRef(tournamentTypeToGoTo);
    if (tournamentTypeToGoTo && refHeader?.current) {
      const yOffset = -refHeader.current.offsetHeight - 20;
      if (
        !loading &&
        ref?.current &&
        getTournamentsLengthByType(tournamentTypeToGoTo) > 0
      ) {
        setTournamentTypeToGoTo();
        const y =
          ref.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      } else if (refTournamentsContainer?.current)
        scrollToTournamentsContainer();
    }
  }, [refHeader, tournamentTypeToGoTo, loading]);

  useEffect(() => {
    if (refTournamentsContainer && routerState?.scrollToTournaments)
      scrollToTournamentsContainer();
  }, [refTournamentsContainer, routerState]);

  const scrollToTournamentsContainer = () => {
    const y =
      refTournamentsContainer.current.getBoundingClientRect().top +
      window.pageYOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  const {
    canCall,
    wallet,
    chain,
    currentNetwork,
    loaded,
    connectMetamask,
    switchToNetwork,
    getContract,
    watchTransaction,
    getGasLimit,
    getGasPrice,
    getBalance,
  } = useWallet();

  const balance = useBalance(canCall, wallet, currentNetwork, getBalance);

  const { canCallTournaments, canCallNFTTournaments } = useTournamentsInit(
    wallet,
    currentNetwork,
    setLoading,
    setTournamentAddresses,
    tournaments,
    setTournaments
  );

  const {
    updatePrimaryTournamentData,
    updateSecondaryTournamentData,
    updateBlacklistedAddresses,
    updateWhitelistedAddresses,
  } = usePotsInit(
    tournamentAddresses,
    getContract,
    getGasLimit,
    getGasPrice,
    setTournaments
  );

  useEffect(() => {
    if (!currentNetwork || !wallet) {
      setLoading(false);
      setTournamentAddresses([]);
      setTournaments([]);
      setUpdateTournamentsTimestamp();
      setWantsToApprove("");
      setWantsToApprovePotToken("");
      setWantsToBid("");
      setWantsToClaim("");
      setWantsToDeposit("");
      setCanApprove("");
      setCanApprovePotToken("");
      setCanBid("");
      setCanClaim("");
      setCanDeposit("");
      setTournamentsTimeoutSet(false);
      setSkipFetch(false);
      setTournamentsToFetch(tournamentsToFetchDefault);
    }
  }, [currentNetwork, wallet]);

  useEffect(() => {
    if (
      ((tournamentAddresses.length === 0 && tournaments.length === 0) ||
        tournaments.length > 0) &&
      loading
    ) {
      setLoading(false);
      setUpdateTournamentsTimestamp(Date.now());
    }
  }, [tournaments]);

  const findPotByAddress = (pots, address) =>
    pots.find((tournament) => tournament.contractAddress === address);

  useEffect(() => {
    const updateTournaments = async () => {
      const combinedTournaments = pinnedTournaments
        .concat(activeTournaments)
        .concat(expiredTournaments)
        .concat(creatorTournaments)
        .concat(topOwnerTournaments)
        .concat(closedTournaments);
      const sortedTournaments =
        combinedTournaments.filter((t) => t.needsUpdate).length > 0
          ? combinedTournaments
          : combinedTournaments.slice().map((t, i) => {
              if (i < tournamentsToFetch) t.needsUpdate = true;
              return t;
            });
      const addressesOfPotsToBeUpdated = sortedTournaments
        .filter((t) => t.needsUpdate)
        .map((t) => t.contractAddress);
      const indexOfLastPotToBeUpdated = sortedTournaments.findIndex(
        (t1) =>
          t1.contractAddress ===
          sortedTournaments
            .slice()
            .reverse()
            .find((t2) => t2.needsUpdate)?.contractAddress
      );
      const addressesOfPotsToBeUpdatedNextRound = sortedTournaments
        .filter((t, i) => !t.needsUpdate && i > indexOfLastPotToBeUpdated)
        .filter((t, i) => i < tournamentsToFetch)
        .map((t) => t.contractAddress);
      const newTournaments = await Promise.all(
        sortedTournaments
          .filter((t) => t.needsUpdate)
          .map(async (newTournament) => {
            const contractAddress = newTournament.contractAddress;
            if (newTournament.initDone)
              await updatePrimaryTournamentData(newTournament, contractAddress);
            await updateSecondaryTournamentData(
              newTournament,
              contractAddress,
              wallet
            );
            await updateBlacklistedAddresses(newTournament, contractAddress);
            await updateWhitelistedAddresses(newTournament, contractAddress);
            newTournament.needsUpdate = false;
            if (!newTournament.initDone) newTournament.initDone = true;
            return newTournament;
          })
      );
      setTournaments((prevTournaments) =>
        prevTournaments.map((prevTournament, i) => {
          if (
            addressesOfPotsToBeUpdatedNextRound.find(
              (address) => address === prevTournament.contractAddress
            )
          )
            prevTournament.needsUpdate = true;
          if (
            addressesOfPotsToBeUpdated.find(
              (address) => address === prevTournament.contractAddress
            )
          )
            return findPotByAddress(
              newTournaments,
              prevTournament.contractAddress
            );
          return prevTournament;
        })
      );
      const initDone =
        addressesOfPotsToBeUpdated.find(
          (address) =>
            address ===
            sortedTournaments[sortedTournaments.length - 1].contractAddress
        ) !== undefined || addressesOfPotsToBeUpdated.length === 0;
      if (initDone) setTournamentsTimeoutSet(true);
      else setTournamentsTimeoutSet(false);
      setTimeout(
        () => {
          if (initDone && tournamentsToFetch !== 2) setTournamentsToFetch(2);
          else setUpdateTournamentsTimestamp(Date.now());
          setSkipFetch(false);
        },
        initDone ? 10000 : 0
      );
    };

    if (
      canCallTournaments &&
      canCallNFTTournaments &&
      loading === false &&
      updateTournamentsTimestamp !== undefined &&
      !wantsToBid &&
      !wantsToApprove &&
      !wantsToApprovePotToken &&
      !wantsToClaim &&
      !wantsToDeposit &&
      !skipFetch &&
      tournaments.length > 0
    )
      updateTournaments();
    if (wantsToBid) setCanBid(wantsToBid);
    if (wantsToApprove) setCanApprove(wantsToApprove);
    if (wantsToApprovePotToken) setCanApprovePotToken(wantsToApprovePotToken);
    if (wantsToClaim) setCanClaim(wantsToClaim);
    if (wantsToDeposit) setCanDeposit(wantsToDeposit);
  }, [
    updateTournamentsTimestamp,
    loading,
    wantsToBid,
    wantsToApprove,
    wantsToApprovePotToken,
    wantsToClaim,
    wantsToDeposit,
    skipFetch,
    tournamentsToFetch,
    canCallTournaments,
    canCallNFTTournaments,
  ]);

  useEffect(() => {
    if (!canBid) setWantsToBid("");
  }, [canBid]);

  useEffect(() => {
    if (!canApprove) setWantsToApprove("");
  }, [canApprove]);

  useEffect(() => {
    if (!canApprovePotToken) setWantsToApprovePotToken("");
  }, [canApprovePotToken]);

  useEffect(() => {
    if (!canClaim) setWantsToClaim("");
  }, [canClaim]);

  useEffect(() => {
    if (!canDeposit) setWantsToDeposit("");
  }, [canDeposit]);

  useEffect(() => {
    if (
      tournamentsTimeoutSet &&
      (wantsToApprove ||
        wantsToApprovePotToken ||
        wantsToBid ||
        wantsToClaim ||
        wantsToDeposit)
    )
      setSkipFetch(true);
  }, [
    tournamentsTimeoutSet,
    wantsToApprove,
    wantsToApprovePotToken,
    wantsToBid,
    wantsToClaim,
    wantsToDeposit,
  ]);

  const refreshTournamentData = async (address) => {
    const newTournament = { ...findPotByAddress(tournaments, address) };
    const originalTimeUntilExpiry = JSON.parse(
      JSON.stringify(newTournament.timeUntilExpiry)
    );

    const refreshTournamentDataSub = async () => {
      await updatePrimaryTournamentData(newTournament, address);
      await updateSecondaryTournamentData(newTournament, address, wallet);
      await updateBlacklistedAddresses(newTournament, address);
      await updateWhitelistedAddresses(newTournament, address);
      if (
        newTournament.timeUntilExpiry === originalTimeUntilExpiry &&
        !newTournament.isClaim
      )
        setTimeout(async () => await refreshTournamentDataSub(), 2000);
      else
        setTournaments((prevTournaments) =>
          prevTournaments.map((prevTournament) => {
            if (prevTournament.contractAddress !== address)
              return prevTournament;
            else return newTournament;
          })
        );
    };
    await refreshTournamentDataSub();
  };

  const refreshBlacklistedAddresses = async (address) => {
    const newTournament = { ...findPotByAddress(tournaments, address) };
    const originalAddresses = JSON.parse(
      JSON.stringify(newTournament?.blacklistedAddresses || {})
    );

    const refreshTournamentDataSub = async () => {
      await updateBlacklistedAddresses(newTournament, address);
      if (
        newTournament?.blacklistedAddresses?.length ===
          originalAddresses?.length &&
        !newTournament.isClaim
      )
        setTimeout(async () => await refreshTournamentDataSub(), 2000);
      else
        setTournaments((prevTournaments) =>
          prevTournaments.map((prevTournament) => {
            if (prevTournament.contractAddress !== address)
              return prevTournament;
            else return newTournament;
          })
        );
    };
    await refreshTournamentDataSub();
  };

  const refreshWhitelistedAddresses = async (address) => {
    const newTournament = { ...findPotByAddress(tournaments, address) };
    const originalAddresses = JSON.parse(
      JSON.stringify(newTournament.whitelistedAddresses || {})
    );

    const refreshTournamentDataSub = async () => {
      await updateWhitelistedAddresses(newTournament, address);
      if (
        newTournament?.whitelistedAddresses.length ===
          originalAddresses.length &&
        !newTournament.isClaim
      )
        setTimeout(async () => await refreshTournamentDataSub(), 2000);
      else
        setTournaments((prevTournaments) =>
          prevTournaments.map((prevTournament) => {
            if (prevTournament.contractAddress !== address)
              return prevTournament;
            else return newTournament;
          })
        );
    };
    await refreshTournamentDataSub();
  };

  useEffect(() => {
    if (wallet) {
      const storedNotificationsUnparsed = localStorage.getItem(
        `notifications-${wallet}`
      );
      const storedNotifications = storedNotificationsUnparsed
        ? JSON.parse(storedNotificationsUnparsed)
        : [];
      const indexesOfToBeDeleted = [];
      if (storedNotifications.length > 0) {
        for (var i = 0; i < storedNotifications.length; i++) {
          if (storedNotifications[i].timestamp <= now / 1000) {
            createNotification(storedNotifications[i].text);
            playSound(notification);
            indexesOfToBeDeleted.push(i);
          }
        }
        if (indexesOfToBeDeleted.length > 0) {
          const newNotifications = storedNotifications.filter(
            (n, i) => !indexesOfToBeDeleted.includes(i)
          );
          if (newNotifications.length > 0)
            localStorage.setItem(
              `notifications-${wallet}`,
              JSON.stringify(newNotifications)
            );
          else localStorage.removeItem(`notifications-${wallet}`);
        }
      }
    }
  }, [now, wallet]);

  const sortByTimeUntilExpiryAndPriority = (a, b) => {
    if (a.isPriority && !b.isPriority) return -1;
    else if (b.isPriority && !a.isPriority) return 1;
    else return a.timeUntilExpiry - b.timeUntilExpiry;
  };
  const sortByCreatedDateAndPriority = (a, b) => {
    if (a.isPriority && !b.isPriority) return -1;
    else if (b.isPriority && !a.isPriority) return 1;
    else return b?.createdDate - a?.createdDate;
  };

  useEffect(() => {
    if (cookies) {
      const inCookie = cookies[pinnedTournamentsStorageName];
      if (inCookie) {
        localStorage.setItem(
          pinnedTournamentsStorageName,
          JSON.stringify(inCookie)
        );
      }
    }
  }, [cookies]);

  const pinnedTournamentsStorageName = `pinnedTournaments-${currentNetwork?.data.chainId}-${wallet}`;
  const isTournamentPinned = (tournament) => {
    const inLocalStorageUnparsed = localStorage.getItem(
      pinnedTournamentsStorageName
    );
    const inLocalStorage = inLocalStorageUnparsed
      ? JSON.parse(inLocalStorageUnparsed)
      : undefined;
    const pinnedTournaments = inLocalStorage || [];
    return pinnedTournaments.includes(tournament.contractAddress);
  };

  const sortPinnedTournaments = (a, b) => {
    const aIsActive = a.tournamentType === TournamentType.active;
    const bIsActive = b.tournamentType === TournamentType.active;
    if (a.isPriority && !b.isPriority) return -1;
    if (b.isPriority && !a.isPriority) return 1;
    if (aIsActive && bIsActive) return sortByTimeUntilExpiryAndPriority(a, b);
    if (aIsActive) return -1;
    if (bIsActive) return 1;
    return sortByCreatedDateAndPriority(a, b);
  };

  const getPinnedTournaments = () =>
    tournaments
      .filter((tournament) => isTournamentPinned(tournament))
      .map((tournament) => {
        updateTournamentType(tournament);
        return tournament;
      })
      .sort(sortPinnedTournaments);
  const isTournamentDeposited = (tournament) => tournament.deposited;
  const getActiveTournaments = () =>
    tournaments
      .filter(
        (tournament) =>
          !isTournamentPinned(tournament) &&
          isTournamentActive(tournament) &&
          isTournamentDeposited(tournament)
      )
      .map((tournament) => {
        tournament.tournamentType = TournamentType.active;
        return tournament;
      })
      .sort(sortByTimeUntilExpiryAndPriority);
  const getExpiredTournaments = () =>
    tournaments
      .filter(
        (tournament) =>
          !isTournamentPinned(tournament) &&
          isTournamentExpired(tournament) &&
          isTournamentDeposited(tournament)
      )
      .map((tournament) => {
        tournament.tournamentType = TournamentType.expired;
        return tournament;
      })
      .sort(sortByCreatedDateAndPriority);
  const getCreatorTournaments = () =>
    tournaments
      .filter(
        (tournament) =>
          !isTournamentPinned(tournament) &&
          isTournamentCreator(tournament) &&
          isTournamentDeposited(tournament)
      )
      .map((tournament) => {
        tournament.tournamentType = TournamentType.creator;
        return tournament;
      })
      .sort(sortByCreatedDateAndPriority);
  const getTopOwnerTournaments = () =>
    tournaments
      .filter(
        (tournament) =>
          !isTournamentPinned(tournament) &&
          isTournamentTopOwner(tournament) &&
          isTournamentDeposited(tournament)
      )
      .map((tournament) => {
        tournament.tournamentType = TournamentType.topOwner;
        return tournament;
      })
      .sort(sortByCreatedDateAndPriority);
  const getClosedTournaments = () =>
    tournaments
      .filter(
        (tournament) =>
          !isTournamentPinned(tournament) &&
          (isTournamentClosed(tournament) || !isTournamentDeposited(tournament))
      )
      .map((tournament) => {
        if (isTournamentClosed(tournament))
          tournament.tournamentType = TournamentType.closed;
        else updateTournamentType(tournament);
        return tournament;
      })
      .sort(sortClosedTournaments);
  const sortClosedTournaments = (a, b) => {
    const aIsActive = a.tournamentType === TournamentType.active;
    const bIsActive = b.tournamentType === TournamentType.active;
    const aIsClosed = a.tournamentType === TournamentType.closed;
    const bIsClosed = b.tournamentType === TournamentType.closed;
    if (!aIsClosed && !bIsClosed) {
      if (a.isPriority && !b.isPriority) return -1;
      if (b.isPriority && !a.isPriority) return 1;
      if (aIsActive && bIsActive) return sortByTimeUntilExpiryAndPriority(a, b);
      if (aIsActive) return -1;
      if (bIsActive) return 1;
      return sortByCreatedDateAndPriority(a, b);
    }
    if (!aIsClosed && bIsClosed) return -1;
    if (aIsClosed && !bIsClosed) return 1;
    return sortByCreatedDateAndPriority(a, b);
  };
  const pinnedTournaments = getPinnedTournaments();
  const activeTournaments = getActiveTournaments();
  const expiredTournaments = getExpiredTournaments();
  const creatorTournaments = getCreatorTournaments();
  const topOwnerTournaments = getTopOwnerTournaments();
  const closedTournaments = getClosedTournaments();

  const Tournaments = (tournamentsToDisplay, title, ref) =>
    tournamentsToDisplay.length > 0 && (
      <>
        <p ref={ref} className="text-20 sm:text-40 font-bold mb-4">
          {title}
        </p>
        <div className="grid grid-cols-12 gap-4 mb-5">
          {tournamentsToDisplay.map((tournament) => (
            <Tournament
              key={tournament.contractAddress}
              tournament={tournament}
              refreshTournamentData={refreshTournamentData}
              refreshBlacklistedAddresses={refreshBlacklistedAddresses}
              refreshWhitelistedAddresses={refreshWhitelistedAddresses}
              setWantsToApprove={setWantsToApprove}
              setWantsToApprovePotToken={setWantsToApprovePotToken}
              setWantsToBid={setWantsToBid}
              setWantsToClaim={setWantsToClaim}
              setWantsToDeposit={setWantsToDeposit}
              canApprove={canApprove}
              canApprovePotToken={canApprovePotToken}
              canBid={canBid}
              canClaim={canClaim}
              canDeposit={canDeposit}
              setCanApprove={setCanApprove}
              setCanApprovePotToken={setCanApprovePotToken}
              setCanBid={setCanBid}
              setCanClaim={setCanClaim}
              setCanDeposit={setCanDeposit}
              canCall={canCallTournaments && canCallNFTTournaments}
              wallet={wallet}
              chain={chain}
              currentNetwork={currentNetwork}
              loaded={loaded}
              connectMetamask={connectMetamask}
              switchToNetwork={switchToNetwork}
              getContract={getContract}
              watchTransaction={watchTransaction}
              getGasLimit={getGasLimit}
              getGasPrice={getGasPrice}
              balance={balance}
              getBalance={getBalance}
              isTournamentPinned={isTournamentPinned}
              pinnedTournamentsStorageName={pinnedTournamentsStorageName}
              now={now}
            />
          ))}
        </div>
      </>
    );

  return (
    <>
      <div className="heroBg pt-32 relative top-[60px]">
        <div className="flex justify-center">
          <div className="container pb-40 px-3">
            <div className="w-full lg:w-[75%]">
              <h1 className="text-20 sm:text-40 font-bold mb-6">
                Fight for your financial freedom NOW!
              </h1>
              <p className="sm:text-20 font-light mb-12 w-full lg:w-[80%] text-justify">
                Enter the electrifying world of digital warfare, where every
                raid counts, and every decision shapes your destiny. A new breed
                of battle emerges—Clash of Memes. Don't waste your time; dive
                into the ultimate showdown for crypto supremacy or, even better,
                create your village and become the King! Will you rise as the
                undisputed champion of the decentralized arena or fall to the
                onslaught of your rivals? The battle for dominance begins now.
              </p>
              <Button bgColor="black" asLink href={"/create"} className="w-fit">
                Build village
              </Button>
            </div>
          </div>
        </div>
        <div className="bg-[#2F3545] pt-12 pb-14 flex justify-center">
          <div className="container px-3">
            <p className="text-40 font-bold text-grey2 mb-6 text-center xl:text-left">
              Powered by
            </p>
            <div className="grid grid-cols-12 gap-5 xl:flex justify-between">
              <div className="col-span-12 sm:col-span-6 flex xl:block justify-center">
                <img src={ethereum} alt="" width={160} height={40} />
              </div>
              <div className="col-span-12 sm:col-span-6 flex xl:block justify-center">
                <img src={binance} alt="" width={200} height={40} />
              </div>
              <div className="col-span-12 sm:col-span-6 flex xl:block justify-center">
                <img src={polygon} alt="" width={191} height={40} />
              </div>
              <div className="col-span-12 sm:col-span-6 flex xl:block justify-center">
                <img src={avalanche} alt="" width={199} height={40} />
              </div>
              <div className="col-span-12 flex xl:block justify-center">
                <img src={fantom} alt="" width={155} height={40} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={refTournamentsContainer}
        className="flex justify-center bg-bg-light pt-28 pb-20"
      >
        <div className="container px-3">
          {loading ? (
            <div className="flex items-center justify-center space-x-2 h-[100vh]">
              <p className="text-20 font-bold">Loading tournaments</p>
              <Loading color="black" />
            </div>
          ) : (
            <>
              {Tournaments(pinnedTournaments, "Pinned tournaments")}
              {Tournaments(
                activeTournaments,
                "Villages under attack",
                refActive
              )}
              {Tournaments(expiredTournaments, "Successful raids", refExpired)}
              {Tournaments(
                creatorTournaments,
                "Raiders already left",
                refCreator
              )}
              {Tournaments(
                topOwnerTournaments,
                "Abandoned villages",
                refTopOwner
              )}
              {Tournaments(closedTournaments, "Destroyed villages", refClosed)}
              {pinnedTournaments.length +
                activeTournaments.length +
                expiredTournaments.length +
                creatorTournaments.length +
                topOwnerTournaments.length +
                closedTournaments.length ===
              0 ? (
                <div className="flex items-center justify-center h-[100vh]">
                  <p className="text-20 font-bold text-center">
                    If you do not see the tournaments: Connect with your wallet,
                    make sure that you are on a chain that we support, refresh
                    the page.
                  </p>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Home;
