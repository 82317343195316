import { ethers } from "ethers";

async function fetchData(blockExplorerUrl, module, action, contractAddress, apiKey) {
    try {
        const response = await fetch(`https://api.${blockExplorerUrl}/api?module=${module}&action=${action}&address=${contractAddress}&startblock=0&endblock=99999999&sort=desc&apikey=${apiKey}`);
        const jsonData = await response.json();
        return jsonData?.result;
    }
    catch { }
}

export async function getTransactionsOfContract(blockExplorerUrl, contractAddress, apiKey) {
    return await fetchData(blockExplorerUrl, "account", "txlist", contractAddress, apiKey) || [];
}

export async function getERC20TokenTransfersOfContract(blockExplorerUrl, contractAddress, apiKey) {
    return await fetchData(blockExplorerUrl, "account", "tokentx", contractAddress, apiKey) || [];
}

export async function getInternalTransactionsOfContract(blockExplorerUrl, contractAddress, apiKey) {
    return await fetchData(blockExplorerUrl, "account", "txlistinternal", contractAddress, apiKey) || [];
}

const getClaim = async (blockExplorerUrl, contractAddress, apiKey) => {
    const transactions = await getTransactionsOfContract(blockExplorerUrl, contractAddress, apiKey);
    return Array.isArray(transactions) ? transactions.find(tx => tx.functionName === "claim()") : undefined;
}

export async function getBalanceOfBidTokenInContractFromTx(blockExplorerUrl, contractAddress, apiKey, symbol) {
    const claimHash = (await getClaim(blockExplorerUrl, contractAddress, apiKey))?.hash;
    const ERC20Transfers = await getERC20TokenTransfersOfContract(blockExplorerUrl, contractAddress, apiKey) || [];
    return Array.isArray(ERC20Transfers) ? ERC20Transfers.find(tx => tx.tokenSymbol === symbol && tx.hash === claimHash)?.value : undefined;
}

export async function getBalanceOfContractFromTx(blockExplorerUrl, contractAddress, apiKey) {
    const claimHash = (await getClaim(blockExplorerUrl, contractAddress, apiKey))?.hash;
    const internalTransactions = await getInternalTransactionsOfContract(blockExplorerUrl, contractAddress, apiKey) || [];
    return Array.isArray(internalTransactions) ? internalTransactions.find(tx => tx.hash === claimHash)?.value || ethers.utils.parseUnits("0", 18) : undefined;
}
