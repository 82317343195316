const minuteInSeconds = 60;
const hourInSeconds = 3600;
const dayInSeconds = hourInSeconds * 24;

const getDays = (seconds) => parseInt(Math.floor(seconds / dayInSeconds));
const getDaysString = (seconds) => {
    const inDays = getDays(seconds);
    if (inDays >= 1) return `${inDays} day${inDays === 1 ? "" : "s"}`;
    return "";
}

const getHours = (seconds) => parseInt(Math.floor((seconds - getDays(seconds) * dayInSeconds) / hourInSeconds));
const getHoursString = (seconds) => {
    const inHours = getHours(seconds);
    if (inHours >= 1) return `${inHours} hour${inHours === 1 ? "" : "s"}`;
    return "";
}

const getMinutes = (seconds) => parseInt(Math.floor((seconds - getDays(seconds) * dayInSeconds - getHours(seconds) * hourInSeconds) / minuteInSeconds));
const getMinutesStrings = (seconds) => {
    const inMinutes = getMinutes(seconds);
    if (inMinutes >= 1) return `${inMinutes} minute${inMinutes === 1 ? "" : "s"}`;
    return "";
}

const getSeconds = (seconds) => parseInt(Math.floor(seconds - getDays(seconds) * dayInSeconds - getHours(seconds) * hourInSeconds - getMinutes(seconds) * minuteInSeconds));
const getSecondsStrings = (seconds) => {
    const restOfSeconds = getSeconds(seconds);
    return `${restOfSeconds} second${restOfSeconds === 1 ? "" : "s"}`;
}

export default function getTimeUnitFromSeconds(seconds) {
    const days = getDaysString(seconds);
    const hours = getHoursString(seconds);
    const minutes = getMinutesStrings(seconds);
    const restOfSeconds = getSeconds(seconds);
    const restOfSecondsStrings = getSecondsStrings(seconds);
    return `${days}${days && (hours || minutes || restOfSeconds > 0) ? ", " : ""}
    ${hours}${hours && (minutes || restOfSeconds > 0) ? ", " : ""}
    ${minutes}${minutes && restOfSeconds > 0 ? ", " : ""}
    ${(restOfSeconds > 0 || (!days && !hours && !minutes)) ? restOfSecondsStrings : ""}`;
}