import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import useERC20 from "../contracts/ERC20/useERC20";
import useERC721 from "../contracts/ERC721/useERC721";
import { TournamentType } from "../types";
import moment from 'moment';
import transformToLargeNumberAbbreviation from "../utils/transformToLargeNumberAbbreviation";
import useFetchQueue from "./useFetchQueue";
import { getBalanceOfBidTokenInContractFromTx, getBalanceOfContractFromTx } from "../services/blockExplorers";
import noImageAvailable from "../assets/images/home/noImageAvailable.jpg";

export default function useTournamentData(
    tournament,
    wallet,
    canCall,
    watchTransaction,
    getContract,
    balance,
    getBalance,
    currentNetwork,
) {
    const [symbolBidToken, setSymbolBidToken] = useState();
    const [decimalsBidToken, setDecimalsBidToken] = useState();
    const [balanceOfBidToken, setBalanceOfBidToken] = useState(BigNumber.from(0));
    const [balanceOfPotToken, setBalanceOfPotToken] = useState(BigNumber.from(0));
    const [symbolPotToken, setSymbolPotToken] = useState();
    const [decimalsPotToken, setDecimalsPotToken] = useState();
    const [tokenURI, setTokenURI] = useState("");
    const [ownerOfToken, setOwnerOfToken] = useState("");

    const contractAddress = tournament.contractAddress;
    const initDone = tournament.initDone;
    const isNFT = tournament.isNFT;
    const tournamentType = tournament.tournamentType;
    const isActive = tournamentType === TournamentType.active;
    const creatorCanClaim = tournamentType === TournamentType.creator;
    const expired = tournamentType === TournamentType.expired;
    const topOwner = tournamentType === TournamentType.topOwner;
    const closed = tournamentType === TournamentType.closed;
    const lastBidWinner = tournament?.lastBidWinner ? tournament.lastBidWinner.slice(0, 6) + '...' + tournament.lastBidWinner.slice(-4) : undefined;
    const createdDateFormatted = tournament?.createdDate ? moment.unix(tournament?.createdDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const claimedDateFormatted = tournament?.claimedDate ? moment.unix(tournament?.claimedDate).format("YYYY-MM-DD HH:mm:ss") : undefined;
    const bidAmount = tournament.bidAmount || BigNumber.from(0);
    const bidToken = tournament.bidToken;
    const potToken = tournament.potToken;
    const potAmount = tournament.potAmount || BigNumber.from(0);
    const isNativeBidToken = tournament.isBidNative;
    const isNativePotToken = tournament.isPotNative;
    const ownerOfTournament = tournament?.ownerOfTournament?.toLowerCase();
    const isWalletOwnerOfTournament = ownerOfTournament === wallet;
    const blacklistedAddresses = tournament?.blacklistedAddresses || [];
    const isBlacklisted = blacklistedAddresses.find(address => address.toLowerCase() === wallet.toLowerCase());
    const whitelistedAddresses = tournament?.whitelistedAddresses || [];
    const whitelistOnly = tournament.whitelistOnly;
    const notOnWhitelist = whitelistOnly && !whitelistedAddresses.find(address => address.toLowerCase() === wallet.toLowerCase());
    const timeUntilExpiry = tournament.timeUntilExpiry;
    const isPriority = tournament.isPriority;
    const potTokenID = tournament.potTokenID;

    const [deposited, setDeposited] = useState(tournament.deposited);
    const [balanceOfBidTokenInContract, setBalanceOfBidTokenInContract] = useState(BigNumber.from(0));
    const [balanceOfContract, setBalanceOfContract] = useState(BigNumber.from(0));

    const getBidAmountDivided = () => {
        if (decimalsBidToken && tournament.bidAmount !== undefined) return Number(bidAmount) / (10 ** decimalsBidToken);
        else return "";
    }
    const bidAmountDivided = getBidAmountDivided();
    const getBidAmountFormatted = () => {
        if (decimalsBidToken && tournament.bidAmount !== undefined) return transformToLargeNumberAbbreviation(bidAmountDivided);
        else return "";
    }
    const bidAmountFormatted = getBidAmountFormatted();

    const getPotAmountDivided = () => {
        if (decimalsPotToken && tournament.potAmount !== undefined) return Number(potAmount) / (10 ** decimalsPotToken);
        else return "";
    }
    const potAmountDivided = getPotAmountDivided();
    const getPotAmountFormatted = () => {
        if (decimalsPotToken && tournament.potAmount !== undefined) return transformToLargeNumberAbbreviation(potAmountDivided);
        else return "";
    }
    const potAmountFormatted = getPotAmountFormatted();

    const getBalanceOfBidTokenInContractDivided = () => {
        if (decimalsBidToken) return Number(balanceOfBidTokenInContract) / (10 ** decimalsBidToken);
        else return "";
    }
    const balanceOfBidTokenInContractDivided = getBalanceOfBidTokenInContractDivided();
    const getBalanceOfBidTokenInContractFormatted = () => {
        if (decimalsBidToken) return transformToLargeNumberAbbreviation(balanceOfBidTokenInContractDivided);
        else return "";
    }
    const balanceOfBidTokenInContractFormatted = getBalanceOfBidTokenInContractFormatted();

    const getBalanceOfContractDivided = () => {
        if (decimalsBidToken) return Number(balanceOfContract) / (10 ** decimalsBidToken);
        else return "";
    }
    const balanceOfContractDivided = getBalanceOfContractDivided();
    const getBalanceOfContractFormatted = () => {
        if (decimalsBidToken) return transformToLargeNumberAbbreviation(balanceOfContractDivided);
        else return "";
    }
    const balanceOfContractFormatted = getBalanceOfContractFormatted();

    const notEnoughBidToken = balanceOfBidToken?.lt(bidAmount);
    const notEnoughPotToken = balanceOfPotToken?.lt(potAmount);

    const notOwnerOfToken = isNFT && ownerOfToken !== wallet ? true : false;

    const {
        getSymbol,
        getDecimals,
        getBalanceOf,
        canCallERC20,
        setCanCallERC20,
    } = useERC20(watchTransaction, wallet, getContract, [bidToken, isNFT ? "" : potToken], contractAddress);

    const {
        getSymbol: getSymbolERC721,
        getTokenURI,
        getOwnerOf,
        canCallERC721,
        setCanCallERC721,
    } = useERC721(watchTransaction, wallet, getContract, [potToken], contractAddress, potTokenID);

    const getBlockExplorerUrl = () => {
        const blockExplorerUrl = currentNetwork?.data?.blockExplorerUrls?.[0];
        if (blockExplorerUrl) {
            const blockExplorerUrlWithoutPrefix = blockExplorerUrl.replace("https://", "");
            return blockExplorerUrlWithoutPrefix[blockExplorerUrlWithoutPrefix.length - 1] === "/"
                ? blockExplorerUrlWithoutPrefix.slice(0, -1) : blockExplorerUrlWithoutPrefix;
        }
    }

    const {
        setFetch: setFetchBalanceOfBidTokenInContract,
        data: balanceOfBidTokenInContractFromTx,
    } = useFetchQueue(getBalanceOfBidTokenInContractFromTx, contractAddress, getBlockExplorerUrl(), contractAddress, currentNetwork?.apiKey, symbolBidToken);

    useEffect(() => {
        if (balanceOfBidTokenInContractFromTx) setBalanceOfBidTokenInContract(balanceOfBidTokenInContractFromTx);
    }, [balanceOfBidTokenInContractFromTx]);

    const updateBalanceOfBidTokenInContract = async (_symbolBidToken = symbolBidToken, _decimalsBidToken = decimalsBidToken) => {
        if ((isNativePotToken || (!isNativePotToken && potToken != bidToken)) && !isNativeBidToken) {
            if (closed) setFetchBalanceOfBidTokenInContract(true);
            else setBalanceOfBidTokenInContract(await getBalanceOf(bidToken, contractAddress));
        }
    }

    const {
        setFetch: setFetchBalanceOfContract,
        data: balanceOfContractFromTx,
    } = useFetchQueue(getBalanceOfContractFromTx, contractAddress, getBlockExplorerUrl(), contractAddress, currentNetwork?.apiKey);

    useEffect(() => {
        if (balanceOfContractFromTx) setBalanceOfContract(balanceOfContractFromTx);
    }, [balanceOfContractFromTx]);

    const updateBalanceOfContract = async () => {
        if (!isNativePotToken && isNativeBidToken) {
            if (closed) setFetchBalanceOfContract(true);
            else setBalanceOfContract(await getBalance(contractAddress));
        }
    }

    useEffect(() => {
        const init = async () => {
            const nativeCurrency = currentNetwork.data.nativeCurrency;
            if (isNativeBidToken) {
                setSymbolBidToken(nativeCurrency.symbol);
                setDecimalsBidToken(nativeCurrency.decimals);
            }
            if (isNativePotToken) {
                setSymbolPotToken(nativeCurrency.symbol);
                setDecimalsPotToken(nativeCurrency.decimals);
            }
            if (!isNFT) await updateBalanceOfContract();
        }
        if (canCall && currentNetwork && isNativeBidToken !== undefined && isNativePotToken !== undefined) init();
    }, [canCall, wallet, currentNetwork, isNativeBidToken, isNativePotToken]);

    useEffect(() => {
        const init = async () => {
            if (isNativeBidToken) setBalanceOfBidToken(balance);
            if (isNativePotToken) setBalanceOfPotToken(balance);
        }
        if (isNativeBidToken !== undefined && isNativePotToken !== undefined) init();
    }, [balance, isNativeBidToken, isNativePotToken]);

    useEffect(() => {
        const init = async () => {
            if (!isNativeBidToken) {
                const _symbolBidToken = await getSymbol(bidToken) || symbolBidToken;
                setSymbolBidToken(_symbolBidToken);
                const _decimalsBidToken = await getDecimals(bidToken) || decimalsBidToken;
                setDecimalsBidToken(_decimalsBidToken);
                setBalanceOfBidToken(await getBalanceOf(bidToken));
                if (!isNFT) await updateBalanceOfBidTokenInContract(_symbolBidToken, _decimalsBidToken);
            }
            if (!isNativePotToken && !isNFT) {
                setSymbolPotToken(await getSymbol(potToken) || symbolPotToken);
                setDecimalsPotToken(await getDecimals(potToken) || decimalsPotToken);
                setBalanceOfPotToken(await getBalanceOf(potToken));
            }
        }
        if (bidToken !== undefined && bidToken !== null
            && potToken !== undefined && potToken !== null
            && canCallERC20) {
            init();
            setCanCallERC20(false);
        }
    }, [bidToken, potToken, canCallERC20, wallet]);

    useEffect(() => {
        const init = async () => {
            setSymbolPotToken(await getSymbolERC721(potToken) || symbolPotToken);
            setTokenURI(await getTokenURI(potToken) || noImageAvailable);
            setOwnerOfToken((await getOwnerOf(potToken))?.toLowerCase());
        }
        if (potToken !== undefined && potToken !== null && canCallERC721 && isNFT) {
            init();
            setCanCallERC721(false);
        }
    }, [potToken, canCallERC721, wallet]);

    useEffect(() => {
        setDeposited(tournament.deposited);
    }, [tournament.deposited]);

    return {
        symbolBidToken,
        decimalsBidToken,
        setBalanceOfBidToken,
        setBalanceOfPotToken,
        symbolPotToken,
        decimalsPotToken,
        tokenURI,
        contractAddress,
        initDone,
        isNFT,
        isActive,
        creatorCanClaim,
        expired,
        topOwner,
        lastBidWinner,
        createdDateFormatted,
        claimedDateFormatted,
        bidAmount,
        bidToken,
        potToken,
        potAmount,
        isNativeBidToken,
        isNativePotToken,
        isWalletOwnerOfTournament,
        blacklistedAddresses,
        isBlacklisted,
        whitelistedAddresses,
        whitelistOnly,
        notOnWhitelist,
        timeUntilExpiry,
        isPriority,
        potTokenID,
        deposited,
        bidAmountDivided,
        potAmountDivided,
        bidAmountFormatted,
        potAmountFormatted,
        balanceOfBidTokenInContractDivided,
        balanceOfContractDivided,
        balanceOfBidTokenInContractFormatted,
        balanceOfContractFormatted,
        notEnoughBidToken,
        notEnoughPotToken,
        notOwnerOfToken,
        updateBalanceOfBidTokenInContract,
        updateBalanceOfContract,
        setDeposited,
        potTokenID,
    }
}