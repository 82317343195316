import { BtnType, TimeUnit } from "../types";
import Button from "./Button/Button";
import Input from "./Input";

export default function TimeUnitSwitcher({
    timeUnit,
    setTimeUnit,
    id,
    label,
    placeholder,
    value,
    onChange,
    error,
    disabled,
    className = "",
    labelClassName = "",
    daysInsteadOfSecs,
}) {
    const timeUnits = [
        { timeUnit: TimeUnit.Seconds, short: "Secs" },
        { timeUnit: TimeUnit.Minutes, short: "Mins" },
        { timeUnit: TimeUnit.Hours, short: "Hrs" },
    ];
    if (daysInsteadOfSecs) {
        timeUnits.shift();
        timeUnits.push({ timeUnit: TimeUnit.Days, short: "Days" });
    }

    return (
        <Input
            id={id}
            label={label}
            placeholder={placeholder}
            className={`sm:w-[33%] ${className}`}
            fullWidth
            labelClassName={labelClassName}
            value={value}
            onChange={onChange}
            error={error}
            disabled={disabled}
            endAdornment={
                <div className='flex space-x-1 sm:w-[50px] mt-2 sm:mt-0.5 sm:ml-1 items-center'>
                    {timeUnits.map(obj => {
                        return (
                            <Button
                                key={obj.timeUnit}
                                type={timeUnit === obj.timeUnit ? BtnType.filled : BtnType.outlined}
                                bgColor="black"
                                borderColor="black"
                                className="w-full sm:w-auto h-full"
                                textColor={timeUnit === obj.timeUnit ? "white" : "black-white"}
                                onClick={() => setTimeUnit(obj.timeUnit)}
                                disabled={disabled}
                            >
                                <span className="hidden sm:block">{obj.timeUnit}</span>
                                <span className="sm:hidden">{obj.short}</span>
                            </Button>
                        )
                    })}
                </div>
            }
        />
    )
}