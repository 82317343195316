import { useEffect } from "react";
import useTournaments from "../../../contracts/tournaments/useTournaments";
import useNFTTournaments from "../../../contracts/NFTTournaments/useNFTTournaments";

export default function useTournamentsInit(
    wallet,
    currentNetwork,
    setLoading,
    setTournamentAddresses,
    tournaments,
    setTournaments,
) {
    const {
        canCallTournaments,
        getPots,
    } = useTournaments(wallet, currentNetwork);

    const {
        canCallNFTTournaments,
        getPots: getNFTPots,
    } = useNFTTournaments(wallet, currentNetwork);

    useEffect(() => {
        const init = async () => {
            setLoading(true);

            var tournamentAddresses;
            var isError = false;
            const initSub = async () => {
                try {
                    const pots = (await getPots() || []).map(address => ({ address: address }));
                    const NFTPots = (await getNFTPots() || []).map(address => ({ address: address, isNFT: true }));
                    tournamentAddresses = pots.concat(NFTPots);
                    setTournamentAddresses(tournamentAddresses);
                    if (tournamentAddresses.length === 0 && tournaments.length > 0) {
                        setTournaments([]);
                    }
                    if (tournamentAddresses.length === 0) {
                        setLoading(false);
                    }
                }
                catch {
                    isError = true;
                    setLoading(false);
                }
                if (!tournamentAddresses && !isError) setTimeout(async () => await initSub(), 2000);
            }
            await initSub();
        }

        if (canCallTournaments && canCallNFTTournaments) init();
        else if (tournaments.length > 0) {
            setTournaments([]);
        }
    }, [canCallTournaments, canCallNFTTournaments]);

    return {
        canCallTournaments,
        canCallNFTTournaments,
    }
}