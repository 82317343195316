import { useReducer } from "react";
import { FetchStatus } from "../types";
import toast from "react-hot-toast";
import handleResult from "../contracts/handleResult";

const useContract = (watchTransaction, method, callback, callbackError) => {

    const initialState = {
        status: FetchStatus.IDLE,
        error: null,
        data: null,
    };

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case FetchStatus.IDLE:
                return { ...initialState, status: FetchStatus.IDLE };
            case FetchStatus.FETCHING:
                return { ...initialState, status: FetchStatus.FETCHING };
            case FetchStatus.FETCHED:
                return { ...initialState, status: FetchStatus.FETCHED, data: action.payload };
            case FetchStatus.FETCH_ERROR:
                return { ...initialState, status: FetchStatus.FETCH_ERROR, error: action.payload };
            default:
                return state;
        }
    }, initialState);

    const callMethod = async (...params) => {
        if (!method) return;
        
        const errorMessageDefault = "An error has occured. Check transaction.";

        try {
            const response = await method(...params);
            if (!response || !("hash" in response)) {
                const errorMessage = handleResult(response, "Transaction");
                dispatch({ type: FetchStatus.FETCH_ERROR, payload: errorMessage });
                toast.error(errorMessage);
                if (callbackError) await callbackError();
                return;
            }

            dispatch({ type: FetchStatus.FETCHING });

            watchTransaction(response.hash, async (receipt, success) => {
                if (!success) {
                    toast.error(errorMessageDefault);
                    if (callbackError) await callbackError();
                    return dispatch({ type: FetchStatus.FETCH_ERROR, payload: errorMessageDefault });
                }

                if (callback) await callback(...params, response);

                dispatch({ type: FetchStatus.FETCHED, payload: response.data });

                toast.dismiss();
                toast.success("Transaction succeeded.");
            });

        } catch (error) {
            dispatch({ type: FetchStatus.FETCH_ERROR, payload: error });
            toast.error(errorMessageDefault);
            if (callbackError) await callbackError();
        }
    };

    return { state, callMethod, dispatch };
};

export default useContract;