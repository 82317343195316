import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useContract from "../../hooks/useContract";
import { FetchStatus } from "../../types";
import compareArrays from "../../utils/compareArrays";
import ABI from "./ERC20Abi";

export default function useERC20(watchTransaction, wallet, getContract, addresses, spender) {
    const MAX_APPROVE = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

    const [cookies] = useCookies();

    const [loading, setLoading] = useState(false);
    const [addressesLocal, setAddressesLocal] = useState([]);
    const [approved, setApproved] = useState({});

    const [namesToBeStoredAs, setNamesToBeStoredAs] = useState({});

    const updateNamesToBeStoredAs = () => {
        const newNames = {};
        for (var i = 0; i < addressesLocal.length; i++) {
            newNames[addressesLocal[i]] = `approved-${addressesLocal[i]}-${wallet}-${spender}`;
        }
        setNamesToBeStoredAs(newNames);
    }

    const getContracts = () => {
        const contracts = {};
        for (var i = 0; i < addressesLocal.length; i++) {
            contracts[addressesLocal[i]] = getContract(addressesLocal[i], ABI);
        }
        return contracts;
    }
    const [contracts, setContracts] = useState({});
    const [canCallERC20, setCanCallERC20] = useState(false);

    useEffect(() => {
        const filteredAddresses = addresses.filter(address => address) || [];
        if (filteredAddresses.length > 0 && !compareArrays(filteredAddresses, addressesLocal)) setAddressesLocal(filteredAddresses);
        if (filteredAddresses.length === 0 && !compareArrays(filteredAddresses, addressesLocal)) setAddressesLocal([]);
    }, [addresses]);

    useEffect(() => {
        setContracts(getContracts());
    }, [addressesLocal]);

    useEffect(() => {
        updateNamesToBeStoredAs();
    }, [addressesLocal, wallet, spender]);

    useEffect(() => {
        if (Object.values(contracts)?.length > 0 && wallet) setCanCallERC20(true);
    }, [contracts, wallet]);

    const getSymbol = async (address = addressesLocal[0]) => await contracts[address].symbol();
    const getDecimals = async (address = addressesLocal[0]) => await contracts[address].decimals();
    const getBalanceOf = async (contractAddress = addressesLocal[0], ownerAddress = wallet) => await contracts[contractAddress].balanceOf(ownerAddress);

    const approve = async (address = addressesLocal[0]) => await contracts[address].approve(spender, BigNumber.from(MAX_APPROVE));

    const { state, callMethod } = useContract(
        watchTransaction,
        async (address = addressesLocal[0]) => {
            setLoading(true);
            return await approve(address);
        },
        (address) => {
            localStorage.setItem(namesToBeStoredAs[address], 'true');
            const newApproved = { ...approved };
            newApproved[address] = true;
            setApproved(newApproved);
            setLoading(false);
        },
        () => {
            setLoading(false);
        }
    );

    useEffect(() => {
        if (state.status === FetchStatus.FETCH_ERROR) {
            setLoading(false);
        }
    }, [state.status]);

    useEffect(() => {
        const updateApproved = () => {
            const newApproved = { ...approved };
            for (var i = 0; i < addressesLocal.length; i++) {
                const address = addressesLocal[i];
                if (cookies?.[namesToBeStoredAs[address]] === "true"
                    || localStorage.getItem(namesToBeStoredAs[address]) === "true") newApproved[address] = true;
                else if (newApproved[address]) newApproved[address] = false;
            }
            setApproved(newApproved);
        }

        if (cookies && Object.values(namesToBeStoredAs).length > 0 && wallet) updateApproved();
    }, [cookies, namesToBeStoredAs, wallet]);

    return {
        loading,
        getSymbol,
        getDecimals,
        getBalanceOf,
        approved,
        callMethod,
        canCallERC20,
        setCanCallERC20,
    }
}