import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import Home from './pages/Home';
import Tournament from './pages/Tournament';
import Header from './components/Header/Header';
import { Toaster } from 'react-hot-toast';
import Footer from './components/Footer';
import { useRef } from 'react';
import NotFoundPage from './pages/NotFoundPage';
import { useState } from 'react';
import TournamentPage from './pages/TournamentPage';

function App() {
  const refHeader = useRef();

  const [tournamentTypeToGoTo, setTournamentTypeToGoTo] = useState();

  return (
    <CookiesProvider>
      <Toaster position="top-right" />
      <Header refHeader={refHeader} />
      <Routes>
        <Route path="/" exact element={
          <Home
            refHeader={refHeader}
            tournamentTypeToGoTo={tournamentTypeToGoTo}
            setTournamentTypeToGoTo={setTournamentTypeToGoTo}
          />
        } />
        <Route path="/create" exact element={<Tournament />} />
        <Route path='/tournaments/:contractAddress' element={<TournamentPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <Footer setTournamentTypeToGoTo={setTournamentTypeToGoTo} />
    </CookiesProvider>
  );
}

export default App;
