export default function Hamburger({
  collapsed,
  setCollapsed,
  className = "",
  ...rest
}) {
  return (
    <div
      className={`w-10 h-10 cursor-pointer
                transition duration-300
                hover:bg-green hover:bg-opacity-10
                flex justify-center items-center rounded-full
                ${className}`}
      onClick={() => setCollapsed(!collapsed)}
      {...rest}
    >
      <div className="w-6">
        {[0, 1, 2].map((i) => (
          <div
            key={i.toString()}
            className={"w-full h-1 bg-green my-1 rounded-3"}
          />
        ))}
      </div>
    </div>
  );
}
