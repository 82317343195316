import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../components/Button/Button";
import Loading from "../components/Loading/Loading";
import NotificationModal from "../components/Modals/NotificationModal";
import Svg from "../components/Svg";
import Tooltip from "../components/Tooltip/Tooltip";
import useNFTTournaments from "../contracts/NFTTournaments/useNFTTournaments";
import useTournaments from "../contracts/tournaments/useTournaments";
import useCopyToClipboard from "../hooks/useCopyToClipboard";
import useMediaQuery from "../hooks/useMediaQuery";
import useTournamentData from "../hooks/useTournamentData";
import useTournamentLogic from "../hooks/useTournamentLogic";
import useWallet from "../hooks/useWallet";
import { BtnType, Position } from "../types";
import usePotsInit from "./Home/hooks/usePotsInit";
import useNow from "../hooks/useNow";
import { updateTournamentType } from "../utils/tournamentTypesDeciders";
import SeparatorHorizontal from "../components/SeparatorHorizontal";
import trophyBronze from "../assets/icons/trophyBronze.svg";
import trophyGold from "../assets/icons/trophyGold.svg";
import TournamentCountdown from "./Home/TournamentCountdown";
import useTournamentDetailsData from "../hooks/useTournamentDetailsData";
import useWhitelist from "../hooks/useWhitelist";
import useBlacklist from "../hooks/useBlacklist";
import CountdownTextLike from "../components/CountdownTextLike";
import getTimeUnitFromSeconds from "../utils/getTimeUnitFromSeconds";
import InfoTooltip from "./Tournament/components/InfoTooltip";
import Input from "../components/Input";
import useBalance from "../hooks/useBalance";

export default function TournamentPage() {
  const params = useParams();
  const contractAddress = params?.contractAddress;

  const bp640px = useMediaQuery(640);

  const [contractAddressObj, setContractAddressObj] = useState();

  const [tournament, setTournament] = useState({
    contractAddress: contractAddress,
  });
  const [loading, setLoading] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [hasNotification, setHasNotification] = useState(false);
  const [refreshInterval, setRefreshInterval] = useState();

  const {
    wallet,
    canCall,
    watchTransaction,
    getBalance,
    currentNetwork,
    getContract,
    getGasLimit,
    getGasPrice,
  } = useWallet();

  const { canCallTournaments, getPots } = useTournaments(
    wallet,
    currentNetwork
  );

  const { canCallNFTTournaments, getPots: getNFTPots } = useNFTTournaments(
    wallet,
    currentNetwork
  );

  useEffect(() => {
    const init = async () => {
      var tournamentAddresses;
      var isError = false;
      const initSub = async () => {
        setLoading(true);
        try {
          const pots = ((await getPots()) || []).map((address) => ({
            address: address,
          }));
          const NFTPots = ((await getNFTPots()) || []).map((address) => ({
            address: address,
            isNFT: true,
          }));
          tournamentAddresses = pots.concat(NFTPots);
          setContractAddressObj(
            tournamentAddresses.find((obj) => obj.address === contractAddress)
          );
        } catch {
          isError = true;
          setLoading(false);
        }
        if (!tournamentAddresses && !isError)
          setTimeout(async () => await initSub(), 2000);
      };
      await initSub();
    };

    if (canCallTournaments && canCallNFTTournaments) init();
  }, [canCallTournaments, canCallNFTTournaments]);

  const {
    updatePrimaryTournamentData,
    updateSecondaryTournamentData,
    updateBlacklistedAddresses,
    updateWhitelistedAddresses,
  } = usePotsInit(
    contractAddressObj ? [contractAddressObj] : [],
    getContract,
    getGasLimit,
    getGasPrice,
    (tournaments) => setTournament(tournaments[0])
  );

  useEffect(() => {
    const init = async () => {
      const newTournament = { ...tournament };
      await updateEveryButPrimaryTournamentData(newTournament);
      newTournament.initDone = true;
      setTournament(newTournament);
    };
    if (tournament.initDone === false) init();
  }, [tournament]);

  useEffect(() => {
    if (tournament.initDone) initRefreshInterval();
  }, [tournament]);

  const initRefreshInterval = () => {
    if (refreshInterval) clearInterval(refreshInterval);
    const interval = setInterval(async () => {
      const newTournament = { ...tournament };
      await updatePrimaryTournamentData(newTournament, contractAddress);
      await updateEveryButPrimaryTournamentData(newTournament, contractAddress);
      setTournament(newTournament);
    }, 10000);
    setRefreshInterval(interval);
    return () => clearInterval(interval);
  };

  const updateEveryButPrimaryTournamentData = async (tournament) => {
    await updateSecondaryTournamentData(tournament, contractAddress, wallet);
    await updateBlacklistedAddresses(tournament, contractAddress);
    await updateWhitelistedAddresses(tournament, contractAddress);
    updateTournamentType(tournament);
  };

  const refreshTournamentData = async () => {
    const newTournament = { ...tournament };
    const originalTimeUntilExpiry = JSON.parse(
      JSON.stringify(newTournament.timeUntilExpiry)
    );

    const refreshTournamentDataSub = async () => {
      await updatePrimaryTournamentData(newTournament, contractAddress);
      await updateEveryButPrimaryTournamentData(tournament, contractAddress);
      if (
        newTournament.timeUntilExpiry === originalTimeUntilExpiry &&
        !newTournament.isClaim
      )
        setTimeout(async () => await refreshTournamentDataSub(), 2000);
      else setTournament(newTournament);
    };
    await refreshTournamentDataSub();
  };

  const refreshBlacklistedAddresses = async () => {
    const newTournament = { ...tournament };
    const originalAddresses = JSON.parse(
      JSON.stringify(newTournament?.blacklistedAddresses || {})
    );
    const refreshTournamentDataSub = async () => {
      await updateBlacklistedAddresses(newTournament, contractAddress);
      if (
        newTournament?.blacklistedAddresses?.length ===
          originalAddresses?.length &&
        !newTournament.isClaim
      )
        setTimeout(async () => await refreshTournamentDataSub(), 2000);
      else setTournament(newTournament);
    };
    await refreshTournamentDataSub();
  };

  const refreshWhitelistedAddresses = async () => {
    const newTournament = { ...tournament };
    const originalAddresses = JSON.parse(
      JSON.stringify(newTournament.whitelistedAddresses || {})
    );

    const refreshTournamentDataSub = async () => {
      await updateWhitelistedAddresses(newTournament, contractAddress);
      if (
        newTournament?.whitelistedAddresses.length ===
          originalAddresses.length &&
        !newTournament.isClaim
      )
        setTimeout(async () => await refreshTournamentDataSub(), 2000);
      else setTournament(newTournament);
    };
    await refreshTournamentDataSub();
  };

  const balance = useBalance(canCall, wallet, currentNetwork, getBalance);

  const {
    symbolBidToken,
    decimalsBidToken,
    setBalanceOfBidToken,
    setBalanceOfPotToken,
    symbolPotToken,
    decimalsPotToken,
    tokenURI,
    initDone,
    isNFT,
    isActive,
    creatorCanClaim,
    expired,
    topOwner,
    lastBidWinner,
    createdDateFormatted,
    claimedDateFormatted,
    bidAmount,
    bidToken,
    potToken,
    potAmount,
    isNativeBidToken,
    isNativePotToken,
    isWalletOwnerOfTournament,
    blacklistedAddresses,
    isBlacklisted,
    whitelistedAddresses,
    whitelistOnly,
    notOnWhitelist,
    timeUntilExpiry,
    isPriority,
    potTokenID,
    deposited,
    bidAmountDivided,
    potAmountDivided,
    bidAmountFormatted,
    potAmountFormatted,
    balanceOfBidTokenInContractDivided,
    balanceOfContractDivided,
    balanceOfBidTokenInContractFormatted,
    balanceOfContractFormatted,
    notEnoughBidToken,
    notEnoughPotToken,
    notOwnerOfToken,
    updateBalanceOfBidTokenInContract,
    updateBalanceOfContract,
    setDeposited,
  } = useTournamentData(
    tournament,
    wallet,
    canCall,
    watchTransaction,
    getContract,
    balance,
    getBalance,
    currentNetwork
  );

  const {
    onBid,
    onClaim,
    onDepositNFT,
    onDeposit,
    approveERC20,
    approveERC721,
    loadingApproveERC20,
    loadingApproveERC721,
    loadingBid,
    loadingClaim,
    loadingDeposit,
    approvedERC20,
    approvedERC721,
  } = useTournamentLogic(
    watchTransaction,
    wallet,
    getContract,
    bidToken,
    potToken,
    isNFT,
    contractAddress,
    getGasLimit,
    getGasPrice,
    isNativeBidToken,
    isNativePotToken,
    refreshTournamentData,
    updateBalanceOfBidTokenInContract,
    updateBalanceOfContract,
    setDeposited,
    setBalanceOfBidToken,
    setBalanceOfPotToken,
    balance,
    potTokenID
  );

  const { copyTooltip, setCopyTooltip, copyToClipboard } =
    useCopyToClipboard(contractAddress);

  const now = useNow();

  const {
    canCallPots,
    canCallNFTPots,
    loading: loadingDetails,
    initDone: initDoneDetails,
    bidOption,
    bidVariable1,
    bidVariable2,
    toPotFee,
    expExpiryOption,
    expirationTime,
    expChangeBy,
    expMinimumTime,
    roundDuration,
    roundReward,
    updateData,
    updateNFTData,
    getBidPriceDistributionsSorted,
  } = useTournamentDetailsData(
    contractAddress,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshTournamentData,
    decimalsBidToken,
    decimalsPotToken
  );

  useEffect(() => {
    if (initDoneDetails) setLoading(false);
  }, [initDoneDetails]);

  useEffect(() => {
    if (tournament?.initDone && decimalsBidToken && decimalsPotToken) {
      if (isNFT && canCallNFTPots && !initDoneDetails) updateNFTData();
      else if (canCallPots && !initDoneDetails) updateData();
    }
  }, [
    tournament,
    canCallPots,
    canCallNFTPots,
    initDoneDetails,
    decimalsBidToken,
    decimalsPotToken,
  ]);

  const {
    whitelistErrors,
    newWhitelistedAddress,
    setNewWhitelistedAddress,
    setTriedToSubmitNewWhitelistAddress,
    loadingAddWhitelistedAddress,
    onWhitelist,
    validateWhitelist,
  } = useWhitelist(
    contractAddress,
    isNFT,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshWhitelistedAddresses,
    watchTransaction,
    whitelistedAddresses
  );

  const {
    blacklistErrors,
    newBlacklistedAddress,
    setNewBlacklistedAddress,
    setTriedToSubmitNewBlacklistAddress,
    loadingAddBlacklistedAddress,
    onBlacklist,
    validateBlacklist,
  } = useBlacklist(
    contractAddress,
    isNFT,
    getContract,
    getGasLimit,
    getGasPrice,
    refreshBlacklistedAddresses,
    watchTransaction,
    blacklistedAddresses
  );

  const LastBidWinner = () => {
    return (
      <div className="col-span-6 lg:col-span-2 lg:text-right mb-8">
        <p className="text-grey1 mb-0">Winner</p>
        <p
          className={`text-20 font-medium ${
            lastBidWinner ? "" : "shimmer w-full"
          }`}
        >
          <span className={lastBidWinner !== undefined ? "" : "invisible"}>
            {lastBidWinner || "."}
          </span>
        </p>
      </div>
    );
  };

  const bidTokenAmountInTournamentExists =
    potToken != bidToken && toPotFee > 0 && !isNFT;

  return (
    <div className="flex justify-center bg-bg-light pt-28 pb-20 min-h-[100vh]">
      <div className="container px-3">
        {!tournament?.initDone || loading ? (
          <div className="flex justify-center items-center h-full">
            <Loading color="black" />
          </div>
        ) : !contractAddressObj ? (
          <p className="text-28 text-center font-bold mb-8">
            No contract exists with this address!
          </p>
        ) : (
          <>
            <p className="text-28 font-bold mb-1">Contract</p>
            <div className="flex justify-between">
              <p className="text-18 text-grey2 font-medium break-all">
                {bp640px
                  ? contractAddress
                  : `${contractAddress?.slice(0, 6)}...${contractAddress?.slice(
                      -4
                    )}`}
              </p>
              <div className="flex space-x-2">
                <Tooltip
                  tooltip={copyTooltip}
                  onInvisible={() => setCopyTooltip("Copy to clipboard")}
                  wallet={wallet}
                  position={Position.left}
                  minWidthOverride={160}
                  minHeightOverride={40}
                  minWidthOverrideClassName="w-[160px]"
                  minHeightOverrideClassName="h-[40px]"
                >
                  <Button
                    type={BtnType.linklike}
                    textColor="grey2-grey2-darker"
                    padding="p-1"
                    onClick={copyToClipboard}
                  >
                    <Svg name={"copy"} />
                  </Button>
                </Tooltip>
                {isActive && (
                  <Tooltip
                    tooltip={
                      hasNotification ? "Change reminder" : "Set reminder"
                    }
                    position={Position.left}
                    minWidthOverride={150}
                    minWidthOverrideClassName="w-[150px]"
                  >
                    <Button
                      type={BtnType.linklike}
                      textColor={
                        hasNotification
                          ? isPriority
                            ? "orange"
                            : "green-green-darker"
                          : "grey2-grey2-darker"
                      }
                      padding="p-1"
                      onClick={() => setNotificationModalOpen(true)}
                    >
                      <Svg name={"notification"} />
                    </Button>
                  </Tooltip>
                )}
              </div>
            </div>
            <SeparatorHorizontal />
            <div className="grid grid-cols-6 gap-4">
              <div
                className={`col-span-6 lg:col-span-2 ${
                  isNFT ? "sm:flex space-x-2" : "flex space-x-4"
                }`}
              >
                <div className="flex justify-center">
                  {!isNFT ? (
                    <img
                      src={isPriority ? trophyGold : trophyBronze}
                      alt=""
                      width={70}
                      height={80}
                    />
                  ) : (
                    <div
                      className={`${
                        tokenURI
                          ? "max-w-[200px] max-h-[200px]"
                          : "w-[200px] h-[200px] shimmer"
                      }`}
                    >
                      <img src={tokenURI} alt="" className="w-full h-full" />
                    </div>
                  )}
                </div>
                <div className={`flex flex-col justify-center`}>
                  <p className="text-16 sm:text-18 text-grey1 font-medium sm:mb-3">
                    {isActive
                      ? isNFT
                        ? `You will win one`
                        : "You will win"
                      : isNFT
                      ? `The prize is one`
                      : "Prize"}
                  </p>
                  {!isNFT ? (
                    <div
                      className={`${
                        potAmountFormatted && symbolPotToken !== undefined
                          ? ""
                          : "shimmer w-full"
                      }`}
                    >
                      <div
                        className={`flex flex-wrap items-end ${
                          potAmountFormatted && symbolPotToken !== undefined
                            ? ""
                            : "invisible"
                        }`}
                      >
                        <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">
                          {potAmountFormatted}
                        </span>{" "}
                        <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">
                          {symbolPotToken !== undefined ? symbolPotToken : "."}
                        </span>
                      </div>
                      {(isNativePotToken ||
                        (!isNativePotToken && potToken != bidToken)) &&
                        !isNativeBidToken && (
                          <div
                            className={`flex flex-wrap items-end mt-2 ${
                              balanceOfBidTokenInContractFormatted &&
                              symbolBidToken !== undefined
                                ? ""
                                : "invisible"
                            }`}
                          >
                            <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">
                              {balanceOfBidTokenInContractFormatted}
                            </span>{" "}
                            <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">
                              {symbolBidToken !== undefined
                                ? symbolBidToken
                                : "."}
                            </span>
                          </div>
                        )}
                      {!isNativePotToken && isNativeBidToken && (
                        <div
                          className={`flex flex-wrap items-end mt-2 ${
                            balanceOfContractFormatted &&
                            symbolBidToken !== undefined
                              ? ""
                              : "invisible"
                          }`}
                        >
                          <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">
                            {balanceOfContractFormatted}
                          </span>{" "}
                          <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">
                            {symbolBidToken !== undefined
                              ? symbolBidToken
                              : "."}
                          </span>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`${
                        symbolPotToken !== undefined ? "" : "shimmer w-full"
                      }`}
                    >
                      <div
                        className={`flex flex-wrap items-end ${
                          symbolPotToken !== undefined ? "" : "invisible"
                        }`}
                      >
                        <span className="text-20 sm:text-40 font-bold break-all sm:leading-[33px] mr-2">
                          {symbolPotToken}
                        </span>{" "}
                        <span className="text-18 sm:text-20 font-semibold sm:leading-[23px]">
                          NFT
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {isActive ? (
                <>
                  <TournamentCountdown
                    date={timeUntilExpiry * 1000}
                    onComplete={async () => await refreshTournamentData()}
                    completedText={"Bidding closed!"}
                    isPriority={isPriority}
                    className="col-span-6 lg:col-span-2 lg:text-center"
                    boxesContainerClassName="lg:justify-center"
                  />
                  <div className="col-span-6 lg:col-span-2 lg:text-right">
                    <p className="text-grey1 mb-0">Currently winning</p>
                    <p
                      className={`text-20 font-medium ${
                        lastBidWinner ? "" : "shimmer w-full"
                      }`}
                    >
                      <span
                        className={
                          lastBidWinner !== undefined ? "" : "invisible"
                        }
                      >
                        {lastBidWinner || "."}
                      </span>
                    </p>
                  </div>
                  <div className="col-span-6 flex justify-center">
                    <div className="w-full lg:w-[33%]">
                      <Tooltip
                        tooltip={`Bid: ${bidAmountDivided} ${
                          symbolBidToken || ""
                        }`}
                        position={Position.top}
                        wallet={wallet}
                        hide={
                          !deposited || !bidAmountDivided || !symbolBidToken
                        }
                        containerClassName="w-full"
                        optionalRefresh={deposited}
                      >
                        <Button
                          bgColor={isPriority ? "orange" : "green-green-darker"}
                          borderColor={
                            isPriority ? "orange" : "green-green-darker"
                          }
                          className="w-full mt-2"
                          loading={
                            loadingApproveERC20 ||
                            loadingApproveERC721 ||
                            loadingBid ||
                            loadingDeposit ||
                            !initDone
                          }
                          onClick={
                            !deposited && isWalletOwnerOfTournament
                              ? (
                                  isNFT
                                    ? approvedERC721?.[potToken]
                                    : approvedERC20?.[potToken] ||
                                      isNativePotToken
                                )
                                ? isNFT
                                  ? async () =>
                                      await onDepositNFT(contractAddress)
                                  : async () =>
                                      await onDeposit(
                                        contractAddress,
                                        potAmount
                                      )
                                : isNFT
                                ? async () => await approveERC721(potToken)
                                : async () => await approveERC20(potToken)
                              : !isNativeBidToken && !approvedERC20?.[bidToken]
                              ? async () => await approveERC20(bidToken)
                              : async () => await onBid(bidAmount)
                          }
                          disabled={
                            now >= timeUntilExpiry * 1000 ||
                            ((isNativeBidToken || approvedERC20?.[bidToken]) &&
                              notEnoughBidToken) ||
                            (isBlacklisted &&
                              (isWalletOwnerOfTournament ? deposited : true)) ||
                            (notOnWhitelist &&
                              (isWalletOwnerOfTournament ? deposited : true)) ||
                            (!deposited && !isWalletOwnerOfTournament) ||
                            ((approvedERC20?.[potToken] || isNativePotToken) &&
                              notEnoughPotToken &&
                              isWalletOwnerOfTournament) ||
                            (!deposited &&
                              approvedERC721?.[potToken] &&
                              notOwnerOfToken &&
                              isWalletOwnerOfTournament)
                          }
                        >
                          {!deposited
                            ? isWalletOwnerOfTournament
                              ? (
                                  isNFT
                                    ? !approvedERC721?.[potToken]
                                    : !isNativePotToken &&
                                      !approvedERC20?.[potToken]
                                )
                                ? `Approve ${symbolPotToken || ""}`
                                : isNFT
                                ? notOwnerOfToken
                                  ? `Not owner of token`
                                  : `Deposit ${symbolPotToken || ""}`
                                : notEnoughPotToken
                                ? `Not enough ${symbolPotToken || ""}`
                                : `Deposit ${potAmountFormatted} ${
                                    symbolPotToken || ""
                                  }`
                              : isNFT
                              ? "No NFT was deposited to the pot"
                              : "No token were deposited to the pot"
                            : isBlacklisted
                            ? "You are on blacklist"
                            : notOnWhitelist
                            ? "You are not on the whitelist"
                            : !isNativeBidToken && !approvedERC20?.[bidToken]
                            ? `Approve ${symbolBidToken || ""}`
                            : notEnoughBidToken
                            ? `Not enough ${
                                symbolBidToken || ""
                              } to bid (${bidAmountFormatted})`
                            : `Bid (${bidAmountFormatted} ${
                                symbolBidToken || ""
                              })`}
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </>
              ) : expired || creatorCanClaim || topOwner ? (
                <>
                  {(expired || creatorCanClaim) && (
                    <TournamentCountdown
                      date={
                        (timeUntilExpiry +
                          (expired
                            ? tournament.winnerClaimAllowTime
                            : tournament.creatorClaimAllowTime)) *
                        1000
                      }
                      onComplete={async () => await refreshTournamentData()}
                      completedText={"Time expired!"}
                      label="Claim time:"
                      isPriority={isPriority}
                      className="col-span-6 lg:col-span-2 lg:text-center"
                      boxesContainerClassName="lg:justify-center"
                    />
                  )}
                  {LastBidWinner()}
                  <div className="col-span-6 flex justify-center">
                    <div className="w-full lg:w-[33%]">
                      <Button
                        bgColor={isPriority ? "orange" : "green-green-darker"}
                        borderColor={
                          isPriority ? "orange" : "green-green-darker"
                        }
                        className="w-full"
                        loading={loadingClaim || !initDone}
                        onClick={async () => await onClaim()}
                        disabled={
                          tournament?.lastBidWinner?.toLowerCase() !== wallet
                        }
                      >
                        Claim
                      </Button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-span-6 lg:col-span-2 lg:text-center">
                    <div>
                      <p className="text-grey1 mb-0">Creation</p>
                      <p
                        className={`text-20 font-medium ${
                          createdDateFormatted !== undefined
                            ? ""
                            : "shimmer w-full"
                        }`}
                      >
                        <span
                          className={
                            createdDateFormatted !== undefined
                              ? ""
                              : "invisible"
                          }
                        >
                          {createdDateFormatted || "."}
                        </span>
                      </p>
                    </div>
                    <div>
                      <p className="text-grey1 mb-0">Claim</p>
                      <p
                        className={`text-20 font-medium ${
                          claimedDateFormatted !== undefined
                            ? ""
                            : "shimmer w-full"
                        }`}
                      >
                        <span
                          className={
                            claimedDateFormatted !== undefined
                              ? ""
                              : "invisible"
                          }
                        >
                          {claimedDateFormatted || "."}
                        </span>
                      </p>
                    </div>
                  </div>
                  {LastBidWinner()}
                </>
              )}
              <div className="col-span-6 mt-12 mb-3">
                <p className="col-span-6 text-24 text-grey2 font-bold">
                  Details
                </p>
                <SeparatorHorizontal bgColor="bg-grey3-light" mb="" />
              </div>
              <div className="col-span-6">
                <p className="text-20 text-grey2 font-semibold">
                  {isNFT ? "NFT" : "Treasure token address"}
                </p>
              </div>
              <div className="col-span-6 lg:col-span-3">
                <p className="text-grey1 mb-0">
                  {isNFT ? "NFT" : "Treasure token address"} address
                </p>
                <p className={`text-20 font-medium mb-0 break-all`}>
                  {isNativePotToken
                    ? `${currentNetwork?.data.nativeCurrency.symbol} (native)`
                    : potToken}
                </p>
              </div>

              {isNFT ? (
                <div className="col-span-6">
                  <p className="text-grey1 mb-0">NFT sprite</p>
                  <div className="max-w-[450px]">
                    <img src={tokenURI} alt="" />
                  </div>
                </div>
              ) : (
                <div className="col-span-6 lg:col-span-3 lg:text-right">
                  <p className="text-grey1 mb-0">
                    Treasure token address amount in tournament
                  </p>
                  <p className={`text-20 font-medium mb-0 break-all`}>
                    {deposited ? potAmountDivided : 0}
                  </p>
                </div>
              )}
              <div className="col-span-6 mt-6">
                <p className="text-20 text-grey2 font-semibold">Raid token</p>
              </div>
              <div
                className={`col-span-6 ${
                  bidTokenAmountInTournamentExists
                    ? "lg:col-span-2"
                    : "lg:col-span-3"
                }`}
              >
                <p className="text-grey1 mb-0">Raid token address</p>
                <p className={`text-20 font-medium mb-0 break-all`}>
                  {isNativeBidToken
                    ? `${currentNetwork?.data.nativeCurrency.symbol} (native)`
                    : bidToken}
                </p>
              </div>
              {bidTokenAmountInTournamentExists && (
                <div className="col-span-6 lg:col-span-2 lg:text-center">
                  <p className="text-grey1 mb-0">
                    Raid token amount in tournament
                  </p>
                  <p className={`text-20 font-medium mb-0 break-all`}>
                    {deposited ? (
                      (isNativePotToken && isNativeBidToken) ||
                      (!isNativePotToken &&
                        !isNativeBidToken &&
                        potToken == bidToken) ? (
                        potAmountDivided
                      ) : (isNativePotToken ||
                          (!isNativePotToken && potToken != bidToken)) &&
                        !isNativeBidToken ? (
                        balanceOfBidTokenInContractDivided
                      ) : !isNativePotToken && isNativeBidToken ? (
                        balanceOfContractDivided
                      ) : (
                        <></>
                      )
                    ) : (
                      0
                    )}
                  </p>
                </div>
              )}
              <div
                className={`col-span-6 ${
                  bidTokenAmountInTournamentExists
                    ? "lg:col-span-2"
                    : "lg:col-span-3"
                } lg:text-right`}
              >
                <p className="text-grey1 mb-0">Bid amount</p>
                <p className={`text-20 font-medium mb-0 break-all`}>
                  {bidAmountDivided}
                </p>
              </div>
              <div className="col-span-6">
                <p className="text-grey1 mb-0">Raid fee rule</p>
                <p className={`text-20 font-medium mb-0`}>
                  {bidOption === 0
                    ? `${bidAmountFormatted} ${symbolBidToken}`
                    : bidOption === 1 && !isNFT
                    ? `${bidVariable1}% of Pot amount`
                    : `${bidAmountFormatted} ${symbolBidToken} growing by ${bidVariable2}% after every bid`}
                </p>
              </div>
              <div className="col-span-6">
                <p className="text-grey1 mb-0">Raid fee distribution</p>
                <div className="max-h-40 overflow-y-auto pr-1">
                  {getBidPriceDistributionsSorted().map((distribution) => {
                    return (
                      <p
                        key={distribution.toAddress}
                        className="grid grid-cols-12 text-20 mb-1"
                      >
                        <span className="col-span-3 sm:col-span-1 font-medium">
                          {distribution.percentage}%
                        </span>
                        <span className="col-span-9 sm:col-span-11 break-all">
                          {distribution.toAddress}
                        </span>
                      </p>
                    );
                  })}
                </div>
              </div>
              <div className="col-span-6 mt-6">
                <p className="text-20 text-grey2 font-semibold">
                  Time until expiry
                </p>
              </div>
              <div className="col-span-6 lg:col-span-3">
                <p className="text-grey1 mb-0">Remaining time</p>
                <CountdownTextLike timeUntilExpiry={timeUntilExpiry} />
              </div>
              <div className="col-span-6 lg:col-span-3 lg:text-right">
                <p className="text-grey1 mb-0">Time until expiry rule</p>
                <p className={`text-20 font-light mb-0`}>
                  {expExpiryOption === 0 ||
                  (expExpiryOption === 1 &&
                    expirationTime - expChangeBy <= expMinimumTime) ? (
                    <>
                      Resets to{" "}
                      <span className="text-black font-semibold">
                        {getTimeUnitFromSeconds(expirationTime)}
                      </span>{" "}
                      after each bid
                    </>
                  ) : expExpiryOption === 1 ? (
                    <>
                      Resets to{" "}
                      <span className="text-black font-semibold">
                        {getTimeUnitFromSeconds(expirationTime - expChangeBy)}
                      </span>{" "}
                      but it decreases by{" "}
                      <span className="text-black font-semibold">
                        {getTimeUnitFromSeconds(expChangeBy)}
                      </span>{" "}
                      after every bid, but reset time can never be smaller than{" "}
                      <span className="text-black font-semibold">
                        {getTimeUnitFromSeconds(expMinimumTime)}
                      </span>
                    </>
                  ) : (
                    <>
                      Increases by{" "}
                      <span className="text-black font-semibold">
                        {getTimeUnitFromSeconds(expChangeBy)}
                      </span>{" "}
                      after every bid
                    </>
                  )}
                </p>
              </div>
              {!!(roundDuration && roundReward) && (
                <>
                  <div className="col-span-6 mt-6">
                    <p className="text-20 text-grey2 font-semibold">
                      Round reward
                    </p>
                  </div>
                  <div className="col-span-6 lg:col-span-3">
                    <p className="text-grey1 mb-0">Round duration</p>
                    <p className={`text-20 font-medium mb-0`}>
                      {getTimeUnitFromSeconds(roundDuration)}
                    </p>
                  </div>
                  <div className="col-span-6 lg:col-span-3 lg:text-right">
                    <p className="text-grey1 mb-0">Round reward</p>
                    <p className={`text-20 font-medium mb-0`}>
                      {roundReward} {symbolPotToken}
                    </p>
                  </div>
                </>
              )}
              <div className="col-span-6 mt-6">
                <p className="text-20 text-grey2 font-semibold mb-0">
                  Priority tournament
                </p>
                <p className={`text-20 font-medium mb-0`}>
                  {isPriority ? "Yes" : "No"}
                </p>
              </div>
              {((isWalletOwnerOfTournament && whitelistOnly) ||
                whitelistedAddresses.length > 0) && (
                <>
                  <div className="col-span-6 mt-6">
                    <p className="text-20 text-grey2 font-semibold">
                      Whitelist
                    </p>
                  </div>
                  <div className="col-span-6">
                    <div className="text-grey1 mb-0 flex space-x-2 items-center">
                      <span>Whitelisted addresses</span>
                      <InfoTooltip
                        position={bp640px ? Position.top : Position.left}
                        tooltip={
                          "As the creator, you can choose to make your tournament only available to certain address. You do this by changing the nature of the tournament to whitelist only after you create it but before you deposit the prize. These are permanent, cannot be removed. However, you can blacklist an address if they display an unsportsmanlike conduct this will prevent that address from ever bidding again."
                        }
                      />
                    </div>
                    {whitelistedAddresses.length > 0 ? (
                      <ul className="max-h-40 overflow-y-auto mb-3 pr-1 list-disc list-inside">
                        {whitelistedAddresses.map((address) => {
                          return (
                            <li
                              key={address}
                              className="text-20 font-medium mb-0 break-all"
                            >
                              {address}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <p className="text-20 font-medium mb-3">
                        There are no whitelisted addresses
                      </p>
                    )}
                    {isActive && isWalletOwnerOfTournament && (
                      <>
                        <Input
                          id={`${contractAddress}-details-whitelist-new-address`}
                          label={"Add new address to whitelist"}
                          className="mb-2"
                          fullWidth
                          value={newWhitelistedAddress}
                          onChange={(e) =>
                            setNewWhitelistedAddress(e.target.value)
                          }
                          error={
                            whitelistErrors.find(
                              (e) => e.id === `whitelist-new-address`
                            )?.message
                          }
                        />
                        <div className="flex justify-end">
                          <Button
                            bgColor="green-green-darker"
                            className="w-full sm:w-auto"
                            loading={loadingAddWhitelistedAddress}
                            onClick={async () => {
                              setTriedToSubmitNewWhitelistAddress(true);
                              const errorsLength = validateWhitelist().length;
                              if (errorsLength === 0)
                                await onWhitelist(newWhitelistedAddress);
                            }}
                          >
                            <div className="flex items-center">
                              <Svg name={"plus"} />
                              Add
                            </div>
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
              {(isWalletOwnerOfTournament ||
                blacklistedAddresses.length > 0) && (
                <>
                  <div className="col-span-6 mt-6">
                    <p className="text-20 text-grey2 font-semibold">
                      Blacklist
                    </p>
                  </div>
                  <div className="col-span-6">
                    <div className="text-grey1 mb-0 flex space-x-2 items-center">
                      <span>Blacklisted addresses</span>
                      <InfoTooltip
                        position={bp640px ? Position.top : Position.left}
                        tooltip={
                          "As the creator, you can put addresses on the blacklist of your tournament. These are permanent, cannot be removed. This is to fight against bots to make the game fair to all champions. Note that blacklisted addresses are only restricted from bidding, not from claiming if they would win."
                        }
                      />
                    </div>
                    {blacklistedAddresses.length > 0 ? (
                      <ul className="max-h-40 overflow-auto mb-3 pr-1 list-disc list-inside">
                        {blacklistedAddresses.map((address) => {
                          return (
                            <li
                              key={address}
                              className="text-20 font-medium mb-0 break-all"
                            >
                              {address}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <p className="text-20 font-medium mb-3">
                        There are no blacklisted addresses
                      </p>
                    )}
                    {isActive && isWalletOwnerOfTournament && (
                      <>
                        <Input
                          id={`${contractAddress}-details-blacklist-new-address`}
                          label={"Add new address to blacklist"}
                          className="mb-2"
                          fullWidth
                          value={newBlacklistedAddress}
                          onChange={(e) =>
                            setNewBlacklistedAddress(e.target.value)
                          }
                          error={
                            blacklistErrors.find(
                              (e) => e.id === `blacklist-new-address`
                            )?.message
                          }
                        />
                        <div className="flex justify-end">
                          <Button
                            bgColor="green-green-darker"
                            className="w-full sm:w-auto"
                            loading={loadingAddBlacklistedAddress}
                            onClick={async () => {
                              setTriedToSubmitNewBlacklistAddress(true);
                              const errorsLength = validateBlacklist().length;
                              if (errorsLength === 0)
                                await onBlacklist(newBlacklistedAddress);
                            }}
                          >
                            <div className="flex items-center">
                              <Svg name={"plus"} />
                              Add
                            </div>
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
      <NotificationModal
        notificationModalOpen={notificationModalOpen}
        setNotificationModalOpen={setNotificationModalOpen}
        hasNotification={hasNotification}
        setHasNotification={setHasNotification}
        wallet={wallet}
        tournament={tournament}
        currentNetwork={currentNetwork}
        now={now}
      />
    </div>
  );
}
