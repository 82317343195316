import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import useContract from "../../hooks/useContract";
import { FetchStatus } from "../../types";
import compareArrays from "../../utils/compareArrays";
import ABI from "./ERC721Abi";
import getPersistentCookieOptions from "../../utils/getPersistentCookieOptions";

export default function useERC721(watchTransaction, wallet, getContract, addresses, to, tokenID) {
    const [cookies, setCookie] = useCookies();

    const [loading, setLoading] = useState(false);
    const [addressesLocal, setAddressesLocal] = useState([]);
    const [approved, setApproved] = useState({});

    const [namesToBeStoredAs, setNamesToBeStoredAs] = useState({});

    const updateNamesToBeStoredAs = () => {
        const newNames = {};
        for (var i = 0; i < addressesLocal.length; i++) {
            newNames[addressesLocal[i]] = `approved-${addressesLocal[i]}-${wallet}-${to}-${tokenID}`;
        }
        setNamesToBeStoredAs(newNames);
    }

    const getContracts = () => {
        const contracts = {};
        for (var i = 0; i < addressesLocal.length; i++) {
            contracts[addressesLocal[i]] = getContract(addressesLocal[i], ABI);
        }
        return contracts;
    }
    const [contracts, setContracts] = useState({});
    const [canCallERC721, setCanCallERC721] = useState(false);

    useEffect(() => {
        const filteredAddresses = addresses.filter(address => address) || [];
        if (filteredAddresses.filter(address => address)?.length > 0 && !compareArrays(filteredAddresses, addressesLocal)) setAddressesLocal(filteredAddresses);
        if (filteredAddresses.length === 0 && !compareArrays(filteredAddresses, addressesLocal)) setAddressesLocal([]);
    }, [addresses]);

    useEffect(() => {
        setContracts(getContracts());
    }, [addressesLocal]);

    useEffect(() => {
        updateNamesToBeStoredAs();
    }, [addressesLocal, wallet, to, tokenID]);

    useEffect(() => {
        if (Object.values(contracts)?.length > 0 && wallet) setCanCallERC721(true);
    }, [contracts, wallet, to, tokenID]);

    const getSymbol = async (address = addressesLocal[0]) => await contracts[address].symbol();
    const getTokenURI = async (address = addressesLocal[0]) => {
        const jsonURITest = "https://api.mdvmm.xyz/hvmtl/6168";
        const jsonURI = await contracts[address].tokenURI(tokenID);
        var json;
        try {
            json = await fetch(jsonURI).then((response) => response.json());
        }
        catch { }
        return json?.image || "";
    };
    const getBalanceOf = async (contractAddress = addressesLocal[0], ownerAddress = wallet) => await contracts[contractAddress].balanceOf(ownerAddress);
    const getOwnerOf = async (address = addressesLocal[0]) => await contracts[address].ownerOf(tokenID);

    const approve = async (address = addressesLocal[0]) => await contracts[address].approve(to, tokenID);

    const { state, callMethod } = useContract(
        watchTransaction,
        async (address = addressesLocal[0]) => {
            setLoading(true);
            return await approve(address);
        },
        (address) => {
            localStorage.setItem(namesToBeStoredAs[address], 'true');
            const newApproved = { ...approved };
            newApproved[address] = true;
            setApproved(newApproved);
            setLoading(false);
        },
        () => {
            setLoading(false);
        }
    );

    useEffect(() => {
        if (state.status === FetchStatus.FETCH_ERROR) {
            setLoading(false);
        }
    }, [state.status]);

    useEffect(() => {
        const updateApproved = () => {
            const newApproved = { ...approved };
            for (var i = 0; i < addressesLocal.length; i++) {
                const address = addressesLocal[i];
                if (cookies?.[namesToBeStoredAs[address]] === "true"
                    || localStorage.getItem(namesToBeStoredAs[address]) === "true") newApproved[address] = true;
                else if (newApproved[address]) newApproved[address] = false;
            }
            setApproved(newApproved);
        }

        if (cookies && Object.values(namesToBeStoredAs).length > 0 && wallet) updateApproved();
    }, [cookies, namesToBeStoredAs, wallet]);

    return {
        loading,
        getSymbol,
        getTokenURI,
        getBalanceOf,
        getOwnerOf,
        approved,
        callMethod,
        canCallERC721,
        setCanCallERC721,
    }
}