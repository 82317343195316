const borderColors = {
    green: "border-green",
    grey2: "border-grey2",
    white: "border-white",
    black: "border-black",
    orange: "border-orange",
}

const borderColorsHover = {
    green: "hover:border-green",
    grey2: "hover:border-grey2",
    white: "hover:border-white",
    black: "hover:border-black-dark",
    orange: "hover:border-orange-dark",
}

const borderColorsFocus = {
    green: "focus:border-green-dark",
    grey2: "focus:border-grey2-dark",
    white: "focus:border-[#f0f0f0]",
    black: "focus:border-black-darker",
    orange: "focus:border-orange-darker",
}

export { borderColors, borderColorsHover, borderColorsFocus };