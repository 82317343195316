import Countdown from "react-countdown"

export default function TournamentCountdown({
    date,
    onComplete,
    completedText,
    label = "Time remaining",
    isPriority,
    className= "mb-8",
    boxesContainerClassName = "",
}) {
    const Box = (time, label) =>
        time > 0 &&
        <div className={`${isPriority ? "bg-[#FEEDCF]" : "bg-[#F0F4FF]"} rounded-[8px] w-[50px] h-[60px] sm:w-[68px] sm:h-[80px] pt-2 relative`}>
            <p className="text-20 sm:text-32 text-grey1 font-bold">{time?.toString()}</p>
            <p className="text-[8px] sm:text-10 text-grey1 font-bold">{label}{time === 1 ? "" : "S"}</p>
            <div className="absolute top-[45%] right-[-11px] translate-y-[-45%]">
                <div className="bg-grey3 w-[6px] h-[6px] rounded-[1px] mb-1" />
                <div className="bg-grey3 w-[6px] h-[6px] rounded-[1px]" />
            </div>
        </div>

    return (
        <Countdown
            date={date}
            renderer={({ days, hours, minutes, seconds, completed }) => {
                return (
                    <div className={className}>
                        <p className='text-light-gray mb-3'>{label}</p>
                        {completed
                            ? <p className="font-bold">{completedText}</p>
                            :
                            <div className={`flex items-center space-x-4 text-center ${boxesContainerClassName}`}>
                                {Box(days, "DAY")}
                                {Box(hours, "HOUR")}
                                {Box(minutes, "MINUTE")}
                                <div className={`${isPriority ? "bg-[#FEEDCF]" : "bg-[#F0F4FF]"} rounded-[8px] w-[50px] h-[60px] sm:w-[68px] sm:h-[80px] pt-2`}>
                                    <p className="text-20 sm:text-32 text-grey1 font-bold">{seconds?.toString()}</p>
                                    <p className="text-[8px] sm:text-10 text-grey1 font-bold">SECOND{seconds === 1 ? <span className="invisible">S</span> : "S"}</p>
                                </div>
                            </div>}
                    </div>
                )
            }}
            onComplete={onComplete}
        />
    )
}