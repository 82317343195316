import { useState } from "react";
import { borderColors, borderColorsFocus, borderColorsHover } from "./styles/borderColors";
import { textColors, textColorsHover, textColorsFocus } from "./styles/textColors";
import { fillColors, fillColorsHover, fillColorsFocus } from "./styles/fillColors";
import { strokeColors, strokeColorsHover, strokeColorsFocus } from "./styles/strokeColors";
import { Link as RouterLink, Route } from "react-router-dom";

export default function Link({
    children,
    href,
    className = "",
    textColor = "black",
    fontSize = "text-16",
    fontFamily = "",
    fontWeight = "font-medium",
    padding = "px-0 py-1",
    dontRestrictWidth,
    justifyContent = "justify-center",
    underlined,
    disabled,
    loading,
    asRouterLink,
    to,
    state,
    ...rest
}) {
    const isActive = !loading && !disabled;

    const [hovered, setHovered] = useState(false);
    const [focused, setFocused] = useState(false);

    const Content = () => {
        return (
            <>
                <div className={`flex items-center ${justifyContent} ${loading ? "invisible" : ""}`}>
                    {children}
                </div>
                {/* {loading &&
                    <div className="absolute top-[58%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex">
                        <Loading color={textColor} colorDark={textColorDark} size={Size.sm} />
                    </div>
                } */}
            </>
        )
    }

    const baseClassName = `${textColors[textColor]}
    ${isActive ? `${textColorsHover[textColor]} ${textColorsFocus[textColor]}` : ""} 
    ${fillColors[textColor]}
    ${isActive ? `${fillColorsHover[textColor]} ${fillColorsFocus[textColor]}` : ""} 
    ${strokeColors[textColor]}
    ${isActive ? `${strokeColorsHover[textColor]} ${strokeColorsFocus[textColor]}` : ""} 
    ${fontSize} ${fontFamily} ${fontWeight}
    no-underline
    transition ${underlined ? "duration-100" : "duration-300"}
    flex items-center ${justifyContent} relative ${padding}
    ${isActive ? "cursor-pointer" : ""}
    ${!isActive ? "opacity-50" : ""}
    ${!dontRestrictWidth ? "w-fit" : ""}
    ${className}`;
    const onMouseEnter = isActive ? () => setHovered(true) : undefined;
    const onMouseLeave = isActive ? () => setHovered(false) : undefined;
    const onFocus = isActive ? () => setFocused(true) : undefined;
    const onBlur = isActive ? () => setFocused(false) : undefined;

    const Underline = () => isActive && underlined &&
        <div
            className={`w-full border-b-2 absolute left-0 -bottom-1
        ${`${borderColors[textColor]} ${borderColorsHover[textColor]} ${borderColorsFocus[textColor]}`} 
        pb-1 flex justify-center items-center
        transition duration-300`} />;

    if (asRouterLink)
        return (
            <RouterLink className={baseClassName}
                to={isActive ? to : undefined}
                state={state}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onFocus={onFocus}
                onBlur={onBlur}
                {...rest}
            >
                {Underline()}
                {Content()}
            </RouterLink>
        );

    return (
        <a className={baseClassName}
            href={isActive ? href : undefined}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onFocus={onFocus}
            onBlur={onBlur}
            {...rest}
        >
            {Underline()}
            {Content()}
        </a>
    );
}