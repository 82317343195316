import { useState } from "react";

export default function useCopyToClipboard(textToCopy) {
    const [copyTooltip, setCopyTooltip] = useState("Copy to clipboard");

    const copyToClipboard = () => {
        navigator.clipboard.writeText(textToCopy);
        setCopyTooltip("Copied!");
    }

    return {
        copyTooltip, 
        setCopyTooltip,
        copyToClipboard,
    }
}