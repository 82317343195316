import { Size } from "../../../types";

export default function LoadingPart({
    color,
    size,
    className = "",
}) {
    const getDimensions = () => {
        switch (size) {
            case Size.sm: return "w-[18px] h-[18px]";
            case Size.md: return "w-[23px] h-[23px]";
            case Size.lg: return "w-[23px] h-[23px] sm:w-[33px] sm:h-[33px]";
        }
    }
    const dimensions = getDimensions();

    const getBorderWidth = () => {
        switch (size) {
            case Size.sm: return "border-[2px]";
            case Size.md: return "border-[2px]";
            case Size.lg: return "border-[2px] sm:border-[4px]";
        }
    }
    const borderWidth = getBorderWidth();

    return (
        <div
            className={`
            ${dimensions} ${borderWidth}
            absolute rounded-[50%]
            ${color} border-r-transparent border-b-transparent border-l-transparent
            ${className}`}
        />
    )
}