import { useEffect, useRef, useState } from "react";
import { Position } from "../../types";
import bgColors from "./styles/bgColors";
import { borderBottomColors, borderLeftColors, borderRightColors, borderTopColors } from "./styles/borderColors";
export default function Tooltip({
    tooltip,
    bgColor = "black",
    textColor = "text-white",
    textAlign = "",
    onInvisible,
    position,
    wallet,
    minWidthOverride,
    minHeightOverride,
    minWidthOverrideClassName = "",
    minHeightOverrideClassName = "",
    fontSize = "text-sm",
    padding = "p-3",
    borderRadius = "rounded-sm",
    shadow = "drop-shadow-[0_4px_34px_rgba(212,217,255,0.8)]",
    stayOpen,
    children,
    onMouseOver,
    onMouseLeave,
    onTouchStart,
    onTouchEnd,
    hide,
    optionalRefresh,
    containerClassName = "",
    ...rest
}) {
    const horizontalShift = 0;
    const verticalScrollbarWidth = 12;
    const verticalShift = 0;

    const refContainer = useRef(null);
    const refTooltip = useRef(null);

    const [visible, setVisible] = useState(false);
    const [tooltipPositionClassNames, setTooltipPositionsClassNames] = useState("");
    const [triangleClassNames, setTriangleClassNames] = useState("");

    const toTop = () => {
        setTooltipPositionsClassNames(`left-[50%] translate-x-[-50%] top-0 translate-y-[calc(-100%_-_10px)]`);
        setTriangleClassNames(`left-[50%] translate-x-[-50%] bottom-[-10px] 
        border-x-[10px] border-t-[10px] border-x-transparent ${borderTopColors[bgColor]}`);
    }

    const toBottom = () => {
        setTooltipPositionsClassNames(`left-[50%] translate-x-[-50%] bottom-0 translate-y-[calc(100%_+_10px)]`);
        setTriangleClassNames(`left-[50%] translate-x-[-50%] top-[-10px] 
        border-x-[10px] border-b-[10px] border-x-transparent ${borderBottomColors[bgColor]}`);
    }

    const toRight = () => {
        setTooltipPositionsClassNames(`top-[50%] translate-y-[-50%] right-0 translate-x-[calc(100%_+_10px)]`);
        setTriangleClassNames(`top-[50%] translate-y-[-50%] left-[-10px] 
        border-y-[10px] border-r-[10px] border-y-transparent ${borderRightColors[bgColor]}`);
    }

    const toLeft = () => {
        setTooltipPositionsClassNames(`top-[50%] translate-y-[-50%] left-0 translate-x-[calc(-100%_-_10px)]`);
        setTriangleClassNames(`top-[50%] translate-y-[-50%] right-[-10px] 
        border-y-[10px] border-l-[10px] border-y-transparent ${borderLeftColors[bgColor]}`);
    }

    useEffect(() => {
        const setPositions = () => {
            const container = refContainer?.current?.getBoundingClientRect();
            const tooltipWidth = minWidthOverride !== undefined ? minWidthOverride : refTooltip?.current?.offsetWidth;
            const tooltipHeight = minHeightOverride !== undefined ? minHeightOverride : refTooltip?.current?.offsetHeight;

            if (position) {
                switch (position) {
                    case Position.top: toTop(); break;
                    case Position.bottom: toBottom(); break;
                    case Position.right: toRight(); break;
                    default: toLeft(); break;
                }
            }
            else {
                if (container.right + tooltipWidth + verticalScrollbarWidth + verticalShift < window.innerWidth) toRight();
                else if (container.top - tooltipHeight >= 0) toTop();
                else if (container.bottom + tooltipHeight < window.innerHeight) toBottom();
                else toLeft();
            }
        }

        if (refContainer?.current && refTooltip?.current) setPositions();
    }, [refContainer, refTooltip, tooltip, wallet, optionalRefresh]);

    return (
        <div className={"relative flex items-center"}>
            {!hide &&
                <div
                    ref={refTooltip}
                    className={
                        `absolute ${tooltipPositionClassNames} ${padding} ${borderRadius}
                    ${minWidthOverrideClassName} ${minHeightOverrideClassName}
                    flex items-center justify-center text-center
                    ${bgColors[bgColor]}
                    ${fontSize} ${textColor} ${textAlign}  
                    transition-[opacity] duration-500  
                    ${(visible || stayOpen) ? "opacity-100 z-20" : "opacity-0 -z-10"}`}
                    {...rest}
                >
                    <div className={`absolute w-0 h-0 ${triangleClassNames}`} />
                    {tooltip}
                </div>
            }
            <div
                ref={refContainer}
                className={containerClassName}
                onMouseOver={() => {
                    setVisible(true);
                    if (onMouseOver) onMouseOver();
                }}
                onMouseLeave={() => {
                    setVisible(false);
                    if (onInvisible) setTimeout(onInvisible, 500);
                    if (onMouseLeave) onMouseLeave();
                }}
                onTouchStart={() => {
                    setVisible(true);
                    if (onTouchStart) onTouchStart();
                }}
                onTouchEnd={() => {
                    setVisible(false);
                    if (onInvisible) setTimeout(onInvisible, 500);
                    if (onTouchEnd) onTouchEnd();
                }}
            >
                {children}
            </div>
        </div >
    )
}