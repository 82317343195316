import useWallet from "../../hooks/useWallet";
import { useState } from "react";
import NetworksModal from "../Modals/NetworksModal";
import { chainIds } from "../../networks";
import Button from "../Button/Button";
import logo from "../../assets/icons/logo.svg";
import Link from "../Link/Link";
import { useLocation } from "react-router";
import Svg from "../Svg";
import useMediaQuery from "../../hooks/useMediaQuery";
import Hamburger from "./components/Hamburger";
import { useEffect } from "react";
import { BtnType } from "../../types";

export default function Header({ refHeader }) {
  const { wallet, chain, currentNetwork, connectMetamask, switchToNetwork } =
    useWallet();

  const location = useLocation();
  const onHome = location.pathname === "/";
  const onCreate = location.pathname === "/create";

  const bp1280px = useMediaQuery(1280);

  const [networksModalOpen, setNetworksModalOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    if (bp1280px && !collapsed) setCollapsed(true);
  }, [bp1280px]);

  const Logo = () => (
    <a href={"/"} className="no-underline">
      <div className="flex space-x-3">
        <img src={logo} alt="" width={32} height={32} />
        <p className="text-22 font-medium text-black mb-0">Clash of Memes</p>
      </div>
    </a>
  );

  const Links = () => (
    <nav className="flex flex-col xl:flex-row items-center xl:space-x-10 space-y-2 xl:space-y-0">
      <Link
        className=""
        textColor={onHome ? "black" : "grey2"}
        underlined={onHome}
        asRouterLink
        to={{ pathname: "/" }}
        state={{ scrollToTournaments: true }}
      >
        <div className="flex space-x-1 items-center">
          <span>Raids</span>
          <Svg name={"swords"} />
        </div>
      </Link>
      <Link
        href={"/create"}
        className=""
        textColor={onCreate ? "black" : "grey2"}
        underlined={onCreate}
      >
        <div className="flex space-x-1 items-center">
          <span>Build</span>
          <Svg name={"plusSquare"} />
        </div>
      </Link>
      <Link
        href={"https://docs.tokencombat.io"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        textColor="grey2"
      >
        <div className="flex space-x-1 items-center">
          <span>Docs</span>
          <Svg name={"doc"} />
        </div>
      </Link>
      <Link
        target={"_blank"}
        rel={"noopener noreferrer"}
        textColor="grey2"
        disabled
      >
        <div className="flex space-x-1 items-center">
          <span>Partners</span>
          <Svg name={"handshake"} />
        </div>
      </Link>
      {!bp1280px || <div>|</div>}
      <Link
        href={"https://twitter.com/TokenCombat"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        textColor="grey2"
      >
        {bp1280px || <div className="mr-1"> Twitter</div>}
        <div className={bp1280px ? "w-5" : "w-4"}>
          <Svg name={"twitter"} />
        </div>{" "}
      </Link>
      <Link
        href={"https://t.me/tokencombat"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        textColor="grey2"
      >
        {bp1280px || <div className="mr-1"> Telegram</div>}
        <div className={bp1280px ? "w-5" : "w-4"}>
          <Svg name={"telegram"} />
        </div>
      </Link>
      <Link
        href={"https://discord.gg/y2bBtyPZv6"}
        target={"_blank"}
        rel={"noopener noreferrer"}
        textColor="grey2"
      >
        {bp1280px || <div className="mr-1"> Discord</div>}
        <div className={bp1280px ? "w-6" : "w-5"}>
          <Svg name={"discord"} />
        </div>
      </Link>
    </nav>
  );

  const Networks = () => (
    <div className="xl:flex xl:space-x-5 space-y-3 xl:space-y-0 items-center text-center">
      {!wallet ? (
        <Button
          type={BtnType.outlined}
          bgColor="black"
          borderColor="black"
          textColor="black-white"
          className="ml-3"
          onClick={connectMetamask}
        >
          <div className="flex items-center">
            <Svg name={"wallet"} className="mr-2" />
            Connect wallet
          </div>
        </Button>
      ) : (
        <>
          <div className="my-1.5 font-semibold text-lg">
            {wallet?.slice(0, 6)}...{wallet?.slice(-4)}
          </div>
          {chainIds.includes(chain) ? (
            <Button
              type={BtnType.outlined}
              bgColor="black"
              borderColor="black"
              textColor="black-white"
              onClick={() => setNetworksModalOpen(true)}
            >
              <div className={`flex space-x-1 items-center`}>
                <div className="bg-white rounded-full p-1">
                  {currentNetwork?.icon}
                </div>
                <span className="font-semibold">
                  {currentNetwork?.displayName}
                </span>
                <Svg name={"caretDown"} />
              </div>
            </Button>
          ) : (
            <Button
              type={BtnType.outlined}
              bgColor="black"
              borderColor="black"
              textColor="black-white"
              onClick={() => setNetworksModalOpen(true)}
            >
              Switch chain
            </Button>
          )}
        </>
      )}
    </div>
  );

  return (
    <header
      ref={refHeader}
      className={`fixed top-0 left-0 right-0 z-50 gradient-sky shadow-md`}
    >
      <div
        className={`container mx-auto px-2 py-2.5 flex justify-between items-center`}
      >
        {bp1280px ? (
          <>
            <div className="flex space-x-16 items-center">
              {Logo()}
              {Links()}
            </div>
            {Networks()}
          </>
        ) : (
          <>
            {Logo()}
            <Hamburger collapsed={collapsed} setCollapsed={setCollapsed} />
          </>
        )}
      </div>
      <div
        className={`flex flex-col items-center space-y-2 h-0 truncate transition-[height] duration-[350ms] ease-in-out
        ${
          !collapsed
            ? wallet
              ? "h-[380px] border-b-2 border-grey3"
              : "h-[340px] border-b-2 border-grey3"
            : ""
        }
        `}
      >
        {Links()}
        {Networks()}
      </div>
      <NetworksModal
        open={networksModalOpen}
        setOpen={setNetworksModalOpen}
        currentNetwork={currentNetwork}
        switchToNetwork={switchToNetwork}
      />
    </header>
  );
}
