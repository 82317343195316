import Svg from "../../../components/Svg";
import Tooltip from "../../../components/Tooltip/Tooltip";
import useMediaQuery from "../../../hooks/useMediaQuery";

export default function InfoTooltip({ tooltip, size, position, optionalRefresh, bpSm }) {
    const bp640px = useMediaQuery(640);
    const bp1024px = useMediaQuery(1024);
    return (
        <Tooltip
            minWidthOverride={bp1024px ? 450 : (bpSm ? bpSm : bp640px) ? 300 : 200}
            minWidthOverrideClassName={bp1024px ? "w-[450px]" : (bpSm ? bpSm : bp640px) ? "w-[300px]" : "w-[200px]"}
            fontSize="text-14"
            position={position}
            tooltip={tooltip}
            optionalRefresh={optionalRefresh}>
            <Svg name={`info${size || ""}`} className="hover:fill-black transition duration-300" />
        </Tooltip>
    )
}