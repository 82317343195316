import { useEffect } from "react";
import usePots from "../../../contracts/pot/usePots";

export default function usePotsInit(
    prizeTokenType,
    pairAddress,
    getContract,
    getGasLimit,
    getGasPrice,
    setWhitelisted,
) {
    const {
        depositToken,
        depositERC20Token,
        setWhiteListOnly,
        getWhitelistOnly,
        canCallPots,
    } = usePots([pairAddress],
        getContract,
        getGasLimit,
        getGasPrice);

    useEffect(() => {
        const init = async () => {
            setWhitelisted(await getWhitelistOnly(pairAddress));
        }
        if (canCallPots && prizeTokenType === "0" && pairAddress) init();
    }, [canCallPots, prizeTokenType, pairAddress]);

    return {
        depositToken,
        depositERC20Token,
        setWhiteListOnly,
    }
}