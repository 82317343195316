const borderTopColors = {
    white: "border-t-white",
    black: "border-t-black",
}

const borderRightColors = {
    white: "border-r-white",
    black: "border-r-black",
}

const borderBottomColors = {
    white: "border-b-white",
    black: "border-b-black",
}

const borderLeftColors = {
    white: "border-l-white",
    black: "border-l-black",
}

export { borderTopColors, borderRightColors, borderBottomColors, borderLeftColors };