import { useEffect } from "react";
import usePots from "../../../contracts/pot/usePots";
import useNFTPots from "../../../contracts/NFTPot/useNFTPots";

export default function usePotsInit(
  tournamentAddresses,
  getContract,
  getGasLimit,
  getGasPrice,
  setTournaments
) {
  const {
    canCallPots,
    setCanCallPots,
    getLastBidWinner,
    getBidAmount,
    getIsClaim,
    getTimeUntilExpiry,
    getCreatedDate,
    getClaimedDate,
    getPotToken,
    getBidToken,
    getPotAmount,
    getOwnerOfTournament,
    getBlacklistedAddresses,
    getCreatorClaimAllowTime,
    getWinnerClaimAllowTime,
    getIsPotNative,
    getIsBidNative,
    getDeposited,
    getWhitelistAddresses,
    getWhitelistOnly,
    getIsPriority,
  } = usePots(
    tournamentAddresses.filter((obj) => !obj.isNFT).map((obj) => obj.address),
    getContract,
    getGasLimit,
    getGasPrice
  );

  const {
    canCallNFTPots,
    setCanCallNFTPots,
    getLastBidWinner: getLastBidWinnerNFT,
    getBidAmount: getBidAmountNFT,
    getIsClaim: getIsClaimNFT,
    getTimeUntilExpiry: getTimeUntilExpiryNFT,
    getCreatedDate: getCreatedDateNFT,
    getClaimedDate: getClaimedDateNFT,
    getPotToken: getPotTokenNFT,
    getBidToken: getBidTokenNFT,
    getPotTokenID,
    getOwnerOfTournament: getOwnerOfTournamentNFT,
    getBlacklistedAddresses: getBlacklistedAddressesNFT,
    getCreatorClaimAllowTime: getCreatorClaimAllowTimeNFT,
    getWinnerClaimAllowTime: getWinnerClaimAllowTimeNFT,
    getIsBidNative: getIsBidNativeNFT,
    getDeposited: getDepositedNFT,
    getWhitelistAddresses: getWhitelistAddressesNFT,
    getWhitelistOnly: getWhitelistOnlyNFT,
    getIsPriority: getIsPriorityNFT,
  } = useNFTPots(
    tournamentAddresses.filter((obj) => obj.isNFT).map((obj) => obj.address),
    getContract,
    getGasLimit,
    getGasPrice
  );

  useEffect(() => {
    if (
      (canCallPots && canCallNFTPots) ||
      (canCallPots &&
        tournamentAddresses.filter((obj) => obj.isNFT).length === 0) ||
      (canCallNFTPots &&
        tournamentAddresses.filter((obj) => !obj.isNFT).length === 0)
    ) {
      initTournaments();
      if (canCallPots) setCanCallPots(false);
      if (canCallNFTPots) setCanCallNFTPots(false);
    }
  }, [canCallPots, canCallNFTPots, tournamentAddresses]);

  const initTournaments = async () => {
    const tournaments = [];
    for (var i = 0; i < tournamentAddresses.length; i++) {
      const address = tournamentAddresses[i].address;
      const tournament = {
        isNFT: tournamentAddresses[i].isNFT,
        needsUpdate: false,
        initDone: false,
        contractAddress: address,
      };
      await updatePrimaryTournamentData(tournament, address);
      tournaments.push(tournament);
    }
    setTournaments(tournaments);
  };

  const updatePrimaryTournamentData = async (tournament, address) => {
    const isNFT = tournament.isNFT;
    try {
      const isClaim = isNFT
        ? await getIsClaimNFT(address)
        : await getIsClaim(address);

      const timeUntilExpiry = isNFT
        ? await getTimeUntilExpiryNFT(address)
        : await getTimeUntilExpiry(address);

      const creatorClaimAllowTime = isNFT
        ? await getCreatorClaimAllowTimeNFT(address)
        : await getCreatorClaimAllowTime(address);
      const winnerClaimAllowTime = isNFT
        ? await getWinnerClaimAllowTimeNFT(address)
        : await getWinnerClaimAllowTime(address);

      const deposited = isNFT
        ? await getDepositedNFT(address)
        : await getDeposited(address);
      const isPriority = isNFT
        ? await getIsPriorityNFT(address)
        : await getIsPriority(address);
      const createdDate = isNFT
        ? await getCreatedDateNFT(address)
        : await getCreatedDate(address);

      tournament.isClaim = isClaim;
      tournament.timeUntilExpiry = timeUntilExpiry;
      tournament.creatorClaimAllowTime = creatorClaimAllowTime;
      tournament.winnerClaimAllowTime = winnerClaimAllowTime;
      tournament.deposited = deposited;
      tournament.isPriority = isPriority;
      tournament.createdDate = createdDate;
    } catch {
    } finally {
      if (tournament.isClaim === undefined) tournament.isClaim = null;
      if (tournament.timeUntilExpiry === undefined)
        tournament.timeUntilExpiry = null;
      if (tournament.creatorClaimAllowTime === undefined)
        tournament.creatorClaimAllowTime = 5702400;
      if (tournament.winnerClaimAllowTime === undefined)
        tournament.winnerClaimAllowTime = 2851200;
      if (tournament.deposited === undefined) tournament.deposited = null;
      if (tournament.isPriority === undefined) tournament.isPriority = null;
      if (tournament.createdDate === undefined) tournament.createdDate = null;
    }
  };

  const updateSecondaryTournamentData = async (tournament, address, wallet) => {
    const isNFT = tournament.isNFT;
    try {
      const isPotNative = !isNFT ? await getIsPotNative(address) : undefined;
      const isBidNative = isNFT
        ? await getIsBidNativeNFT(address)
        : await getIsBidNative(address);
      const potToken = isNFT
        ? await getPotTokenNFT(address)
        : !isPotNative && (await getPotToken(address));
      const bidToken =
        !isBidNative &&
        (isNFT ? await getBidTokenNFT(address) : await getBidToken(address));
      const lastBidWinner = isNFT
        ? await getLastBidWinnerNFT(address)
        : await getLastBidWinner(address);
      const bidAmount = isNFT
        ? await getBidAmountNFT(address)
        : await getBidAmount(address, wallet);
      const claimedDate = isNFT
        ? await getClaimedDateNFT(address)
        : await getClaimedDate(address);
      const potAmount = !isNFT ? await getPotAmount(address) : undefined;
      const potTokenID = isNFT ? await getPotTokenID(address) : undefined;
      const ownerOfTournament = isNFT
        ? await getOwnerOfTournamentNFT(address)
        : await getOwnerOfTournament(address);
      const whitelistOnly = isNFT
        ? await getWhitelistOnlyNFT(address)
        : await getWhitelistOnly(address);

      if (!isNFT) {
        tournament.isPotNative = isPotNative;
        tournament.potAmount = potAmount;
      } else {
        tournament.isPotNative = false;
        tournament.potTokenID = potTokenID;
      }
      tournament.isBidNative = isBidNative;
      tournament.potToken = potToken;
      tournament.bidToken = bidToken;
      tournament.lastBidWinner = lastBidWinner;
      tournament.bidAmount = bidAmount;
      tournament.claimedDate = claimedDate;
      tournament.ownerOfTournament = ownerOfTournament;
      tournament.whitelistOnly = whitelistOnly;
    } catch {
    } finally {
      if (!isNFT && tournament.isPotNative === undefined)
        tournament.isPotNative = null;
      if (!isNFT && tournament.potAmount === undefined)
        tournament.potAmount = null;
      if (isNFT && tournament.potTokenID === undefined)
        tournament.potTokenID = null;
      if (tournament.isBidNative === undefined) tournament.isBidNative = null;
      if (tournament.potToken === undefined) tournament.potToken = null;
      if (tournament.bidToken === undefined) tournament.bidToken = null;
      if (tournament.lastBidWinner === undefined)
        tournament.lastBidWinner = null;
      if (tournament.bidAmount === undefined) tournament.bidAmount = null;
      if (tournament.claimedDate === undefined) tournament.claimedDate = null;
      if (tournament.ownerOfTournament === undefined)
        tournament.ownerOfTournament = null;
      if (tournament.whitelistOnly === undefined)
        tournament.whitelistOnly = null;
    }
  };

  const updateBlacklistedAddresses = async (tournament, address) => {
    const isNFT = tournament.isNFT;
    try {
      const blacklistedAddresses = isNFT
        ? await getBlacklistedAddressesNFT(address)
        : await getBlacklistedAddresses(address);
      tournament.blacklistedAddresses = blacklistedAddresses;
    } catch {
    } finally {
      if (tournament.blacklistedAddresses === undefined)
        tournament.blacklistedAddresses = null;
    }
  };

  const updateWhitelistedAddresses = async (tournament, address) => {
    const isNFT = tournament.isNFT;
    try {
      const whitelistedAddresses = isNFT
        ? await getWhitelistAddressesNFT(address)
        : await getWhitelistAddresses(address);
      tournament.whitelistedAddresses = whitelistedAddresses;
    } catch {
    } finally {
      if (tournament.whitelistedAddresses === undefined)
        tournament.whitelistedAddresses = null;
    }
  };

  return {
    updatePrimaryTournamentData,
    updateSecondaryTournamentData,
    updateBlacklistedAddresses,
    updateWhitelistedAddresses,
  };
}
