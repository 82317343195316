import Button from "../../../components/Button/Button";
import Svg from "../../../components/Svg";
import { BtnType } from "../../../types";

export default function BackBtn({
    onClick
}) {
    return (
        <Button
            type={BtnType.outlined}
            textColor="black-white"
            bgColor="black"
            borderColor="black"
            onClick={onClick}
        >
            <div className="flex space-x-3 items-center">
                <Svg name={"arrowRight"} className="rotate-180" />
                <span>Back</span>
            </div>
        </Button>
    )
}