import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-hot-toast";
import { BtnType, TimeUnit } from "../../types";
import getTimeUnitFromSeconds from "../../utils/getTimeUnitFromSeconds";
import getTimeUnitInSeconds from "../../utils/getTimeUnitInSeconds";
import onlyNumbersOrEmpty from "../../utils/onlyNumbersOrEmpty";
import Button from "../Button/Button";
import RadioButton from "../RadioButton";
import Svg from "../Svg";
import TimeUnitSwitcher from "../TimeUnitSwitcher";
import Modal from "./Modal";

export default function NotificationModal({
    notificationModalOpen,
    setNotificationModalOpen,
    hasNotification,
    setHasNotification,
    wallet,
    tournament,
    currentNetwork,
    now,
}) {
    const contractAddress = tournament.contractAddress;
    const timeUntilExpiry = tournament.timeUntilExpiry;
    const isPriority = tournament.isPriority;

    const [notificationAtType, setNotificationAtType] = useState("0");
    const [notificationType, setNotificationType] = useState("0");
    const [notificationCustom, setNotificationCustom] = useState("");
    const [notificationCustomTimeUnit, setNotificationCustomTimeUnit] = useState(TimeUnit.Minutes);

    const storeNotificationDataInStorage = () => {
        const getNotificationTimeInSecs = () => {
            switch (notificationAtType) {
                case "0": return 0;
                case "1": return getTimeUnitInSeconds(TimeUnit.Minutes, 10, 0);
                case "2": return getTimeUnitInSeconds(TimeUnit.Minutes, 30, 0);
                case "3": return getTimeUnitInSeconds(TimeUnit.Hours, 1, 0);
                case "4": return getTimeUnitInSeconds(TimeUnit.Days, 1, 0);
                case "5": return getTimeUnitInSeconds(notificationCustomTimeUnit, notificationCustom, 0);
            }
        }

        const storedNotifications = getStoredNotifications();
        const notificationTimeInSecs = getNotificationTimeInSecs();
        const notificationOfPot = getNotificationOfPot();
        if (notificationOfPot) {
            localStorage.setItem(`notifications-${wallet}`, JSON.stringify(storedNotifications.map(n => {
                if (n.contractAddress === contractAddress) {
                    n.timestamp = timeUntilExpiry - notificationTimeInSecs;
                    n.text = `Pot ${contractAddress?.slice(0, 6)}...${contractAddress?.slice(-4)} on ${currentNetwork.data.chainName} will expire in ${getTimeUnitFromSeconds(notificationTimeInSecs)}`;
                    n.notificationAtType = notificationAtType;
                    n.notificationType = notificationType;
                    n.notificationCustom = notificationCustom;
                    n.notificationCustomTimeUnit = notificationCustomTimeUnit;
                }
                return n;
            })));
        }
        else {
            storedNotifications.push({
                contractAddress: contractAddress,
                timestamp: timeUntilExpiry - notificationTimeInSecs,
                text: `Pot ${contractAddress?.slice(0, 6)}...${contractAddress?.slice(-4)} on ${currentNetwork.data.chainName} will expire in ${getTimeUnitFromSeconds(notificationTimeInSecs)}`,
                notificationAtType: notificationAtType,
                notificationType: notificationType,
                notificationCustom: notificationCustom,
                notificationCustomTimeUnit: notificationCustomTimeUnit,
            });
            localStorage.setItem(`notifications-${wallet}`, JSON.stringify(storedNotifications));
        }

        setNotificationModalOpen(false);
        setHasNotification(true);
        toast.success(`Notification ${notificationOfPot ? "modified" : "set"}`);
    }

    const deleteNotification = () => {
        const storedNotifications = getStoredNotifications();
        const newNotifications = storedNotifications.filter(n => n.contractAddress !== contractAddress);
        if (newNotifications.length > 0) localStorage.setItem(`notifications-${wallet}`, JSON.stringify(newNotifications));
        else localStorage.removeItem(`notifications-${wallet}`);

        setNotificationModalOpen(false);
        setHasNotification(false);
        toast.success("Notification deleted");
    }

    const getStoredNotifications = () => {
        const storedNotificationsUnparsed = localStorage.getItem(`notifications-${wallet}`);
        return storedNotificationsUnparsed ? JSON.parse(storedNotificationsUnparsed) : [];
    }

    const getNotificationOfPot = () => {
        const storedNotifications = getStoredNotifications();
        return storedNotifications.find(n => n.contractAddress === contractAddress);
    }

    const updateNotificationData = () => {
        const notificationOfPot = getNotificationOfPot();
        if (notificationOfPot) {
            setNotificationAtType(notificationOfPot.notificationAtType);
            setNotificationType(notificationOfPot.notificationType);
            setNotificationCustom(notificationOfPot.notificationCustom);
            setNotificationCustomTimeUnit(notificationOfPot.notificationCustomTimeUnit);
            setHasNotification(true);
        }
        else {
            setHasNotification(false);
            setNotificationAtType("0");
            setNotificationType("0");
            setNotificationCustom("");
            setNotificationCustomTimeUnit(TimeUnit.Minutes);
        }
    }

    useEffect(() => {
        if (notificationModalOpen) updateNotificationData();
    }, [notificationModalOpen]);

    useEffect(() => {
        const notificationOfPot = getNotificationOfPot();
        if (!notificationOfPot && hasNotification) setHasNotification(false);
    }, [now]);

    return (
        <Modal
            open={notificationModalOpen}
            setOpen={setNotificationModalOpen}
            onHide={() => {
                setNotificationAtType("0");
                setNotificationType("0");
                setNotificationCustom("");
                setNotificationCustomTimeUnit(TimeUnit.Minutes);
            }}
            title={
                <div className='flex justify-between w-full'>
                    {hasNotification
                        ?
                        <>
                            <span>Change reminder</span>
                            <Button
                                type={BtnType.linklike}
                                textColor="red"
                                padding=""
                                onClick={deleteNotification}
                            >
                                <Svg name={"trashbin"} />
                            </Button>
                        </>
                        : "Set reminder"}
                </div>
            }
        >
            <RadioButton
                id={`${contractAddress}-reminder-at-expiry`}
                label={`At expiry`}
                checked={notificationAtType === "0"}
                onChange={() => setNotificationAtType("0")}
                className="mt-2 mb-1"
            />
            <RadioButton
                id={`${contractAddress}-reminder-10-minutes-before-expiry`}
                label={`10 minutes before expiry`}
                checked={notificationAtType === "1"}
                onChange={() => setNotificationAtType("1")}
                className="mb-1"
            />
            <RadioButton
                id={`${contractAddress}-reminder-30-minutes-before-expiry`}
                label={`30 minutes before expiry`}
                checked={notificationAtType === "2"}
                onChange={() => setNotificationAtType("2")}
                className="mb-1"
            />
            <RadioButton
                id={`${contractAddress}-reminder-1-hour-before-expiry`}
                label={`1 hour before expiry`}
                checked={notificationAtType === "3"}
                onChange={() => setNotificationAtType("3")}
                className="mb-1"
            />
            <RadioButton
                id={`${contractAddress}-reminder-1-day-before-expiry`}
                label={`1 day before expiry`}
                checked={notificationAtType === "4"}
                onChange={() => setNotificationAtType("4")}
                className="mb-1"
            />
            <RadioButton
                id={`${contractAddress}-reminder-custom-time-before-expiry`}
                label={`Custom time before expiry`}
                checked={notificationAtType === "5"}
                onChange={() => setNotificationAtType("5")}
                className="mb-1"
            />
            <TimeUnitSwitcher
                timeUnit={notificationCustomTimeUnit}
                setTimeUnit={setNotificationCustomTimeUnit}
                id={`${contractAddress}-reminder-custom-time-before`}
                label={""}
                placeholder=""
                value={notificationCustom}
                onChange={(e) => {
                    const value = e.target.value;
                    if (onlyNumbersOrEmpty(value)) setNotificationCustom(value);
                }}
                disabled={notificationAtType !== "5"}
                daysInsteadOfSecs
            />
            <label className='text-16 font-medium mb-1' htmlFor='reminder-type-browser'>Reminder type</label>
            <RadioButton
                id={`${contractAddress}-reminder-type-browser`}
                label={`Browser sound notification`}
                checked={notificationType === "0"}
                onChange={() => setNotificationType("0")}
            />
            <RadioButton
                id={`${contractAddress}-reminder-type-email`}
                label={`Email`}
                checked={notificationType === "1"}
                onChange={() => setNotificationType("1")}
                disabled
            />
            <RadioButton
                id={`${contractAddress}-reminder-type-telegram`}
                label={`Telegram`}
                className="mb-3"
                checked={notificationType === "2"}
                onChange={() => setNotificationType("2")}
                disabled
            />
            <Button
                bgColor={isPriority ? "orange" : 'green-green-darker'}
                borderColor={isPriority ? "orange" : 'green-green-darker'}
                className="w-full"
                onClick={storeNotificationDataInStorage}
            >
                {hasNotification ? "Change" : "Confirm"}
            </Button>
        </Modal>
    )
}