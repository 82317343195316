const fillColors = {
    "black": "fill-black",
    "grey2": "fill-grey2",
    "grey3": "fill-grey3",
}

const fillColorsHover = {
    "black": "hover:fill-black",
    "grey2": "hover:fill-green",
    "grey3": "hover:fill-grey3-light",
}

const fillColorsFocus = {
    "black": "focus:fill-black",
    "grey2": "focus:fill-green-dark",
    "grey3": "focus:fill-white",
}

export { fillColors, fillColorsHover, fillColorsFocus };