const borderColors = {
    "black": "border-black",
    "grey2": "border-grey2",
}

const borderColorsHover = {
    "black": "hover:border-black",
    "grey2": "hover:border-green",
}

const borderColorsFocus = {
    "black": "focus:border-black",
    "grey2": "focus:border-green-dark",
}

export { borderColors, borderColorsHover, borderColorsFocus };