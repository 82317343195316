import ABI from "./NFTTournamentsAbi";
import { useState, useEffect } from "react";
import Web3 from "web3";
import parseBigNumber from "../../utils/parseBigNumber";

export default function useNFTTournaments(wallet, currentNetwork) {
    const [contracts, setContracts] = useState();
    const [canCallNFTTournaments, setCanCallNFTTournaments] = useState(false);

    const loadWeb3 = async () => {
        if (window.ethereum) {
            window.web3 = new Web3(window.ethereum);
            window.ethereum.enable();
        }
    }

    const loadContract = async (address) => {
        return await new window.web3.eth.Contract(ABI, address);
    }

    useEffect(() => {
        const init = async () => {
            setCanCallNFTTournaments(false);
            await loadWeb3();

            const contracts = {};
            for (var i = 0; i < currentNetwork.NFTTournamentsAddresses.length; i++) {
                const address = currentNetwork.NFTTournamentsAddresses[i];
                contracts[address] = await loadContract(address);
            }
            setContracts(contracts);
        }
        if (currentNetwork) init();
    }, [currentNetwork]);

    const isCurrentNetwork = () => {
        var result = true;
        const addresses = Object.keys(contracts);
        for (var i = 0; i < addresses.length; i++) {
            if (addresses[i] !== currentNetwork.NFTTournamentsAddresses[i]) result = false;
        }
        return result;
    }

    useEffect(() => {
        if (contracts && !!(currentNetwork && isCurrentNetwork() && wallet && !canCallNFTTournaments)) setCanCallNFTTournaments(true);
    }, [contracts, currentNetwork, wallet, canCallNFTTournaments]);

    useEffect(() => {
        if (!wallet) setCanCallNFTTournaments(false);
    }, [wallet]);

    const getPots = async () => {
        const pots = [];
        const values = Object.values(contracts);
        for (var i = 0; i < values.length; i++) {
            try { pots.push(...await values[i].methods.getPots().call()); }
            catch { }
        }
        return pots;
    };
    const getTopOwnerFee = async (address) => await contracts[address].methods.ownerFee().call();
    const getERC20TokenList = async (address) => await contracts[address].methods.getERC20TokenList().call();
    const getERC721TokenList = async (address) => await contracts[address].methods.getERC721TokenList().call();
    const getPriorityPrice = async (address) => parseBigNumber(await contracts[address].methods.priorityPrice().call());
    const getNonPriorityPrice = async (address) => parseBigNumber(await contracts[address].methods.nonPriorityPrice().call());

    const createPot = async (
        contractAddress,
        isBidTokenNative,
        bidAmount,
        toAddress,
        toPercent,
        expirationTime,
        priorityPool,
        fees,
        depositValue) => {
        return await contracts[contractAddress].methods.createPot(
            isBidTokenNative,
            bidAmount,
            toAddress,
            toPercent,
            expirationTime,
            priorityPool,
            fees).send({
                from: wallet,
                value: depositValue
            });
    }

    return {
        canCallNFTTournaments,
        getPots,
        getTopOwnerFee,
        getERC20TokenList,
        getERC721TokenList,
        getPriorityPrice,
        getNonPriorityPrice,
        createPot,
    }
}