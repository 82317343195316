import { bgColors, bgColorsHover, bgColorsFocus } from "./styles/bgColors";
import { borderColors, borderColorsHover, borderColorsFocus } from "./styles/borderColors";
import { textColors, textColorsHover, textColorsFocus } from "./styles/textColors";
import { fillColors, fillColorsHover, fillColorsFocus } from "./styles/fillColors";
import { strokeColors, strokeColorsHover, strokeColorsFocus } from "./styles/strokeColors";
import { BtnType, Size } from "../../types";
import Loading from "../Loading/Loading";

export default function Button({
    children,
    onClick,
    padding = "px-[20px] py-2",
    className = "",
    fontSize = "text-18",
    fontFamily = "",
    fontWeight = "font-medium",
    borderRadius = "rounded-[6px]",
    textColor = "white",
    bgColor = "green",
    borderWidth = "border-[1.2px]",
    borderColor = "green",
    type = BtnType.filled,
    customShadow,
    justifyContent = "justify-center",
    asLink,
    href,
    disabled,
    loading,
    ...rest
}) {
    const isActive = !loading && !disabled;
    const isFilledOrOutlined = type === BtnType.filled || type === BtnType.outlined;

    if (asLink)
        return (
            <a className={`
            ${isFilledOrOutlined ? `${borderWidth} ${borderColors[borderColor]} ${isActive ? `${borderColorsHover[borderColor]} ${borderColorsFocus[borderColor]}` : ""}` : ""}
            ${type === BtnType.filled
                    ? `${bgColors[bgColor]} ${isActive ? `${bgColorsHover[bgColor]} ${bgColorsFocus[bgColor]}` : ""} ${isActive ? customShadow : ""}`
                    : `${type === BtnType.outlined
                        ? `${isActive ? `${bgColorsHover[bgColor]} ${bgColorsFocus[bgColor]}` : ""}`
                        : ""}`}
            ${fontSize} ${fontFamily} ${fontWeight}
            ${textColors[textColor]}
            ${isActive ? `${textColorsHover[textColor]} ${textColorsFocus[textColor]}` : ""} 
            ${fillColors[textColor]}
            ${isActive ? `${fillColorsHover[textColor]} ${fillColorsFocus[textColor]}` : ""} 
            ${strokeColors[textColor]}
            ${isActive ? `${strokeColorsHover[textColor]} ${strokeColorsFocus[textColor]}` : ""} 
            ${borderRadius} 
            ${isActive ? "cursor-pointer" : "cursor-auto"}
            transition duration-300
            flex items-center ${justifyContent} relative
            ${padding} 
            ${!isActive ? "opacity-50" : ""}
            ${className}`}
                href={isActive ? href : undefined}
                {...rest}
            >
                <div className={`flex items-center ${justifyContent} ${loading ? "invisible" : ""}`}>
                    {children}
                </div>
                {loading &&
                    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex">
                        <Loading color={textColor} size={Size.sm} />
                    </div>
                }
            </a>
        )
    return (
        <button className={`
        ${isFilledOrOutlined ? `${borderWidth} ${borderColors[borderColor]} ${isActive ? `${borderColorsHover[borderColor]} ${borderColorsFocus[borderColor]}` : ""}` : ""}
            ${type === BtnType.filled
                ? `${bgColors[bgColor]} ${isActive ? `${bgColorsHover[bgColor]} ${bgColorsFocus[bgColor]} ` : ""} ${isActive ? customShadow : ""}`
                : `${type === BtnType.outlined
                    ? `${isActive ? `${bgColorsHover[bgColor]} ${bgColorsFocus[bgColor]}` : ""}`
                    : ""}`}
            ${fontSize} ${fontFamily} ${fontWeight}
            ${textColors[textColor]} 
            ${isActive ? `${textColorsHover[textColor]} ${textColorsFocus[textColor]}` : ""} 
            ${fillColors[textColor]}
            ${isActive ? `${fillColorsHover[textColor]} ${fillColorsFocus[textColor]}` : ""} 
            ${strokeColors[textColor]}
            ${isActive ? `${strokeColorsHover[textColor]} ${strokeColorsFocus[textColor]}` : ""} 
            ${borderRadius} 
            ${isActive ? "cursor-pointer" : "cursor-auto"}
            transition duration-300
            flex items-center ${justifyContent} relative
            ${padding} 
            ${!isActive ? "opacity-50" : ""}
            ${className}`}
            onClick={isActive ? onClick : undefined}
            disabled={disabled || loading}
            {...rest}
        >
            <div className={`flex items-center ${justifyContent} ${loading ? "invisible" : ""}`}>
                {children}
            </div>
            {loading &&
                <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex">
                    <Loading color={textColor} size={Size.sm} />
                </div>
            }
        </button>
    )
}